import React from 'react';
import PropTypes from 'prop-types';

import { stdView } from '../viewControl';
import EmbeddedForm from '../EmbeddedForm/EmbeddedForm';
import SelectList from '../SelectList/SelectList';

const FilterView = ({ adminMode, scheduleModel, viewControl }) => {
  const { LOCATION, STAFF, PATIENT } = scheduleModel;

  const loadFilterData = ({
    fieldState,
    setFieldState,
    viewControl: {
      setError,
      setPending,
      view: { date }
    }
  }) => {
    const patient = fieldState(PATIENT);
    if (!(patient.loaded || patient.loading)) {
      const setPatient = setFieldState(PATIENT);
      setPatient({
        value: patient.value,
        loading: true,
        head: 'Loading users...'
      });
      setPending(true);
      scheduleModel
        .fetchUsersWithAppointments(date)
        .then(result => {
          setPending(false);
          const users = [
            { key: 'ALL', value: 'All Users' },
            ...result.map(({ key, val: { name } }) => ({
              key,
              value: name
            }))
          ];
          setPatient({
            list: users,
            value: patient.value ? patient.value : users[0].key,
            loaded: true
          });
        })
        .catch(error => {
          setPending(false);
          setError(error);
          setPatient({ error: true, head: 'Data load error.' });
        });
    }
    scheduleModel.fetchAppointmentData(
      {
        fieldState,
        setFieldState,
        viewControl
      },
      true
    );
  };

  const filterForm = {
    onSubmit: ({ fieldState }) => {
      const filterFields = fieldState.all();
      const filter = {};
      for (const field in filterFields) {
        filter[field] = filterFields[field].value;
      }
      scheduleModel.setFilter(filter);
      return new Promise(resolve => resolve(true));
    },
    title: 'Filter Options',
    fields: [
      {
        id: LOCATION,
        component: SelectList,
        label: 'Location',
        initForUpdate: ({ entity }) => ({
          value: entity.val[LOCATION]
        })
      },
      {
        id: STAFF,
        omitField: !adminMode,
        component: SelectList,
        label: 'Staff',
        initForUpdate: ({ entity }) => ({
          value: entity.val[STAFF]
        })
      },
      {
        id: PATIENT,
        component: SelectList,
        label: 'User',
        initForUpdate: ({ entity }) => ({
          value: entity.val[PATIENT]
        })
      }
    ],
    effects: [
      {
        run: loadFilterData
      }
    ]
  };

  // console.log('render');
  return (
    <EmbeddedForm
      id={stdView.DETAIL}
      viewControl={viewControl}
      form={filterForm}
    />
  );
};

FilterView.propTypes = {
  viewControl: PropTypes.object,
  adminMode: PropTypes.bool,
  userMode: PropTypes.bool,
  scheduleModel: PropTypes.object.isRequired
};

export default FilterView;
