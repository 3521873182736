import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { withStyles } from '@material-ui/core/styles';
import LandingPage from './LandingPage';

let StyledLandingPage;

const StaticCMSLandingPage = props => (
  <StaticQuery
    query={graphql`
      query {
        styles: allDatoCmsSiteStyle {
          edges {
            node {
              styleKey
              style
            }
          }
        }
      }
    `}
    render={data => {
      if (!StyledLandingPage) {
        const rawStyles = {};
        data.styles.edges.forEach(({ node: { styleKey, style } }) => {
          rawStyles[styleKey] = style;
        });
        StyledLandingPage = withStyles(rawStyles)(LandingPage);
      }
      return <StyledLandingPage />;
    }}
  />
);

export default StaticCMSLandingPage;
