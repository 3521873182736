const style = {
  pullCenter: {
    display: 'inline-block',
    float: 'none'
  },
  linksVertical: {
    '& li': {
      display: 'block !important',
      marginLeft: '-5px',
      marginRight: '-5px',
      '& a': {
        padding: '5px !important'
      }
    }
  },
  copyRight: {
    padding: '15px 0px'
  },
  socialButtons: {
    '& li': {
      display: 'inline-block'
    }
  }
};

export default style;
