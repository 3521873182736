import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { util } from 'bkptshared';

import CustomDropdown from '../../mui-pro/components/CustomDropdown/CustomDropdown';
import Button from '../../mui-pro/components/CustomButtons/Button';

import { viewMode, viewModes } from '../viewControl';
import ModalDialog from '../ModalDialog/ModalDialog';
import { FirebaseContext, AuthContext } from '../../firebase';

import CalendarView from './CalendarView';
import StaffScheduleView from './StaffScheduleView';
import UserScheduleView from './UserScheduleView';
import AvailabilityView from './AvailabilityView';
import AppointmentView from './AppointmentView';
import FilterView from './FilterView';
import moment from '../../../node_modules/moment/moment';

const cache = {};
const clearCache = () => Object.keys(cache).forEach(key => delete cache[key]);

const STAFF = 'staffId';
const LOCATION = 'locationId';
const PATIENT = 'patientId';

const ScheduleModal = ({
  open,
  onClose,
  mode,
  adminMode,
  userMode,
  staffId,
  patientId
}) => {
  const firebase = useContext(FirebaseContext);
  const authControl = useContext(AuthContext);

  const staffScheduleViewId = 'staff-schedule';
  const userScheduleViewId = 'user-schedule';
  const calendarViewId = 'calender-view';
  const availabilityViewId = 'availability-form';
  const appointmentViewId = 'appointment-form';
  const filterViewId = 'filter-form';

  const [dataFilter, setDataFilter] = useState({
    key: filterViewId,
    val:
      adminMode && staffId
        ? { staffId }
        : adminMode && patientId
        ? { patientId }
        : {}
  });

  const initialView = userMode
    ? {
        id: mode === viewMode.MASTER ? userScheduleViewId : appointmentViewId,
        mode,
        title: 'My Appointments'
      }
    : {
        id: calendarViewId,
        mode,
        title: adminMode ? 'Manage Schedules' : 'My Schedule'
      };

  /////////////////////////////////////////////////
  // Manage the Model

  useEffect(() => {
    clearCache();
    return () => clearCache();
  }, []);

  const apptCacheKey = date => util.parseDate(moment(date).date(1));

  const appointmentsCache = date =>
    userMode
      ? cache.appointments
      : cache.appointments && cache.appointments[date];

  const appointmentsForDate = date => appointmentsCache(apptCacheKey(date));

  const setAppointmentsCache = (appointments, date) => {
    if (userMode) {
      cache.appointments = appointments;
    } else {
      if (!cache.appointments) {
        cache.appointments = {};
      }
      cache.appointments[date] = appointments;
    }
  };

  const addAppointmentToCache = appointment => {
    const cacheKey = apptCacheKey(appointment.val.DateTime);
    const appointments = appointmentsCache(cacheKey);
    if (appointments) {
      appointments.push(appointment);
      setAppointmentsCache(appointments, cacheKey);
    }
  };

  const updateAppointmentInCache = (entity, update) => {
    const cacheKey = apptCacheKey(entity.val.DateTime);
    const appointments = appointmentsCache(cacheKey).map(appt =>
      appt.key === entity.key
        ? { key: appt.key, val: { ...appt.val, ...update } }
        : appt
    );
    setAppointmentsCache(appointments, cacheKey);
  };

  const removeAppointmentFromCache = entity => {
    const cacheKey = apptCacheKey(entity.val.DateTime);
    const appointments = appointmentsCache(cacheKey).filter(
      appt => appt.key !== entity.key
    );
    setAppointmentsCache(appointments, cacheKey);
  };

  const filterAppointments = (appointments, filter = dataFilter) => {
    return appointments // check for null
      ? // Is a filter defined?
        (LOCATION in filter.val && filter.val[LOCATION] !== 'ALL') ||
        (STAFF in filter.val && filter.val[STAFF] !== 'ALL') ||
        (PATIENT in filter.val && filter.val[PATIENT] !== 'ALL') ||
        'date' in filter.val
        ? appointments.filter(
            // If yes- then return the filtered array
            ({ val }) =>
              (!(LOCATION in filter.val) ||
                filter.val[LOCATION] === 'ALL' ||
                filter.val[LOCATION] === val.LocationID) &&
              (!(STAFF in filter.val) ||
                filter.val[STAFF] === 'ALL' ||
                filter.val[STAFF] === val.StaffID) &&
              (!(PATIENT in filter.val) ||
                filter.val[PATIENT] === 'ALL' ||
                filter.val[PATIENT] === val.PatientID) &&
              (!filter.val.date ||
                filter.val.date === util.parseDate(val.DateTime))
          )
        : appointments // no filter- just return the input array
      : []; // input array is null - return an empty array instead
  };

  const filterAvailability = (availability, filter = dataFilter) =>
    availability // check for null
      ? // is a filter defined?
        (LOCATION in filter.val && filter.val[LOCATION] !== 'ALL') ||
        (STAFF in filter.val && filter.val[STAFF] !== 'ALL') ||
        filter.val.date
        ? availability.filter(
            // if yes, then return the filtered array
            ({ val }) =>
              (!(LOCATION in filter.val) ||
                filter.val[LOCATION] === 'ALL' ||
                filter.val[LOCATION] === val.LocationID) &&
              (!(STAFF in filter.val) ||
                filter.val[STAFF] === 'ALL' ||
                filter.val[STAFF] === val.StaffID) &&
              (!filter.val.date ||
                (filter.val.date >= val.DateStart &&
                  filter.val.date <= val.DateEnd &&
                  (!val.Recurring || val.Days[moment(filter.val.date).day()])))
          )
        : availability // no filter- just return the input array
      : []; // input array is null - return an empty array instead

  const actions = viewControl => [
    userMode ? (
      <Button
        variant="contained"
        color="primary"
        size="sm"
        onClick={() =>
          viewControl.presentView({
            id: scheduleModel.appointmentViewId,
            mode: viewMode.CREATE
          })
        }
        disabled={viewControl.pending}
        key="create"
      >
        New Appointment
      </Button>
    ) : (
      <CustomDropdown
        buttonText="Create New"
        buttonProps={{
          color: 'primary',
          disabled: viewControl.pending,
          size: 'sm'
        }}
        dropdownList={['Appointment', 'Availability']}
        onClick={item => {
          if (item === 'Availability') {
            viewControl.presentView({
              id: scheduleModel.availabilityViewId,
              mode: viewMode.CREATE
            });
          } else if (item === 'Appointment') {
            viewControl.presentView({
              id: scheduleModel.appointmentViewId,
              mode: viewMode.CREATE
            });
          }
        }}
        s
        dropup
        key="new"
      />
    ),
    <Button
      variant="contained"
      color="primary"
      size="sm"
      onClick={viewControl.rewindAll}
      disabled={viewControl.pending}
      key="close"
    >
      Close
    </Button>
  ];

  const scheduleModel = {
    setFilter: filterVal => {
      const newFilter = {
        ...dataFilter,
        val: { ...dataFilter.val, ...filterVal }
      };
      setDataFilter(newFilter);
    },

    fetchAppointments: async (dateFrom, dateTo) => {
      let appointments = appointmentsCache(dateFrom);
      if (!appointments) {
        if (userMode) {
          appointments = await firebase.myAppointments(dateFrom);
        } else {
          appointments = await firebase.loadAppointments(
            dateFrom,
            dateTo,
            adminMode
          );
        }
        setAppointmentsCache(appointments, dateFrom);
      }
      return filterAppointments(appointments);
    },

    fetchStaffAppointments: async (staffId, date) =>
      filterAppointments(filterAppointments(appointmentsForDate(date)), {
        val: { staffId, date }
      }),

    fetchAvailability: async () => {
      let availability;
      if (cache.availability) {
        availability = cache.availability;
      } else {
        availability = await firebase.loadAvailability(adminMode);
        cache.availability = availability;
      }
      return filterAvailability(availability);
    },

    fetchStaffAvailability: async (staffId, date) =>
      filterAvailability(
        filterAvailability(cache.availability, {
          val: { staffId, date }
        })
      ),

    fetchLists: async () => {
      let lists;
      if (cache.lists) {
        lists = cache.lists;
      } else {
        lists = await firebase.appointmentData();
        cache.lists = lists;
      }
      return lists;
    },

    fetchLocations: async filter => {
      let locations;
      if (cache.locations) {
        locations = cache.locations;
      } else {
        const result = await firebase.appointmentData(false, true);
        locations = result.locations;
        cache.locations = locations;
      }
      return filter
        ? locations.filter(({ key }) => filter.find(fkey => fkey === key))
        : locations;
    },

    fetchUsers: async users => {
      if (!cache.userMap) {
        cache.userMap = {
          [authControl.user.uid]: authControl.profile
        };
      }
      const { userMap } = cache;
      const fetch = {};
      users &&
        users.forEach(uid => {
          if (!(uid in userMap)) {
            fetch[uid] = true;
          }
        });
      const fetchUsers = Object.keys(fetch);
      let fetched;
      if (!users || fetchUsers.length > 0) {
        fetched = await firebase.loadDisplayData(
          users ? fetchUsers : undefined
        );
        if (fetched.users) {
          fetched.users.forEach(entity => {
            userMap[entity.key] = entity;
          });
        }
      }
      return users ? users.map(uid => userMap[uid]) : fetched.users;
    },
    appointmentTimes: (locationId, staffId, date, exclude) =>
      firebase.appointmentTimes(locationId, staffId, date, exclude),
    fetchAppointmentData: (
      { fieldState, setFieldState, viewControl: { setError, setPending } },
      allowSelectAll = false
    ) => {
      const location = fieldState(LOCATION);
      if (!(location.loaded || location.loading)) {
        const staffField = fieldState(STAFF);
        const setLocation = setFieldState(LOCATION);
        const setStaff = setFieldState(STAFF);
        setLocation({
          value: location.value,
          loading: true,
          head: 'Loading locations...'
        });
        setStaff({
          value: staffField.value,
          loading: true,
          head: 'Loading staff...'
        });
        setPending(true);
        scheduleModel
          .fetchLists()
          .then(result => {
            setPending(false);
            const locations = [
              ...(allowSelectAll
                ? [{ key: 'ALL', value: 'All Locations' }]
                : []),
              ...result.locations.map(({ key, val: { LocationName } }) => ({
                key,
                value: LocationName
              }))
            ];
            if (locations.length > 0) {
              setLocation({
                value: location.value ? location.value : locations[0].key,
                list: locations,
                loaded: true
              });
            } else {
              setLocation({ head: 'No locations found.' });
            }
            let staff = [
              ...(allowSelectAll ? [{ key: 'ALL', value: 'All Staff' }] : []),
              ...result.staff.map(({ key, val: { Name, therapist } }) => ({
                key,
                value: Name,
                therapist
              }))
            ];
            if (userMode) {
              staff = staff.filter(
                staffRec =>
                  staffRec.therapist ||
                  (staffField.value && staffField.value === staffRec.key)
              );
            }
            if (staff && staff.length > 0) {
              setStaff({
                value: staffField.value ? staffField.value : staff[0].key,
                list: staff,
                loaded: true
              });
            } else {
              setStaff({ head: 'No staff found.' });
            }
          })
          .catch(error => {
            setPending(false);
            setError(error);
            setLocation({ error: true, head: 'Data load error.' });
            setStaff({ error: true, head: 'Data load error.' });
          });
      }
    },
    createAvailability: availability =>
      firebase.createAvailability(availability).then(({ key }) => {
        cache.availability.push({ key, val: availability });
      }),
    updateAvailability: (key, availability) =>
      firebase.updateAvailability(key, availability).then(() => {
        cache.availability = cache.availability.map(avail =>
          avail.key === key ? { key, val: availability } : avail
        );
      }),
    deleteAvailability: key =>
      firebase.deleteAvailability(key).then(() => {
        cache.availability = cache.availability.filter(
          avail => avail.key !== key
        );
      }),
    makeAppointment: appointment =>
      firebase.makeAppointment(appointment).then(({ key }) => {
        addAppointmentToCache({ key, val: appointment });
      }),
    updateAppointment: (key, appointment) =>
      firebase.updateAppointment(key, appointment).then(() => {
        updateAppointmentInCache({ key, val: appointment }, appointment);
      }),
    confirmAppointment: entity =>
      firebase.confirmAppointment(entity.key).then(() => {
        updateAppointmentInCache(entity, { Confirmed: true });
      }),
    cancelAppointment: entity =>
      firebase.cancelAppointment(entity.key).then(() => {
        if (userMode) {
          removeAppointmentFromCache(entity);
        } else {
          updateAppointmentInCache(entity, { Canceled: true });
        }
      }),
    deleteAppointment: entity =>
      firebase.deleteAppointment(entity.key).then(() => {
        removeAppointmentFromCache(entity);
      }),
    filter: {
      id: filterViewId,
      entity: dataFilter,
      mode: viewMode.UPDATE
    },
    fetchUsersWithAppointments: async date => {
      const appointments = appointmentsForDate(date);
      const users = {};
      appointments.forEach(entity => {
        users[entity.val.PatientID] = true;
      });
      const userList = await scheduleModel.fetchUsers(Object.keys(users));
      return userList.sort((a, b) =>
        a.val.name < b.val.name ? -1 : a.val.name > b.val.name ? 1 : 0
      );
    },
    // eslint-disable-next-line react/display-name
    appointmentTable: (entity, loc, staff, patient) => (
      <table>
        <tbody>
          <tr>
            <td>
              <span style={{ fontWeight: 600 }}>Staff:&nbsp;</span>
            </td>
            <td>{staff.name}</td>
          </tr>
          <tr>
            <td>
              <span style={{ fontWeight: 600 }}>Location:&nbsp;</span>
            </td>
            <td>{loc.LocationName}</td>
          </tr>
          <tr>
            <td>
              <span style={{ fontWeight: 600 }}>Patient:&nbsp;</span>
            </td>
            <td>{patient.name}</td>
          </tr>
          <tr>
            <td>
              <span style={{ fontWeight: 600 }}>Date &amp; Time:&nbsp;</span>
            </td>
            <td>{`${moment(entity.val.DateTime).format(
              'MMMM D, YYYY'
            )} ${util.hourToString12(
              util.parseHour(entity.val.DateTime)
            )}`}</td>
          </tr>
          <tr>
            <td>
              <span style={{ fontWeight: 600 }}>Service:&nbsp;</span>
            </td>
            <td>{entity.val.Notes}</td>
          </tr>
          <tr>
            <td>
              <span style={{ fontWeight: 600 }}>Status:&nbsp;</span>
            </td>
            <td>
              {entity.val.Canceled
                ? 'Canceled'
                : entity.val.Confirmed
                ? 'Confirmed'
                : 'Unconfirmed'}
            </td>
          </tr>
        </tbody>
      </table>
    ),
    actions,
    filterViewId,
    staffScheduleViewId,
    userScheduleViewId,
    calendarViewId,
    availabilityViewId,
    appointmentViewId,
    STAFF,
    LOCATION,
    PATIENT
  };

  // console.log('render');
  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title={initialView.title}
      initialView={initialView}
      wide
    >
      {!userMode && (
        <CalendarView
          id={calendarViewId}
          adminMode={adminMode}
          userMode={userMode}
          scheduleModel={scheduleModel}
        />
      )}
      {!userMode && (
        <StaffScheduleView
          id={staffScheduleViewId}
          adminMode={adminMode}
          userMode={userMode}
          scheduleModel={scheduleModel}
        />
      )}
      {!userMode && (
        <AvailabilityView
          id={availabilityViewId}
          adminMode={adminMode}
          userMode={userMode}
          scheduleModel={scheduleModel}
        />
      )}
      {!userMode && (
        <FilterView
          id={filterViewId}
          adminMode={adminMode}
          userMode={userMode}
          scheduleModel={scheduleModel}
        />
      )}
      {userMode && (
        <UserScheduleView
          id={userScheduleViewId}
          adminMode={adminMode}
          userMode={userMode}
          scheduleModel={scheduleModel}
        />
      )}
      <AppointmentView
        id={appointmentViewId}
        adminMode={adminMode}
        userMode={userMode}
        scheduleModel={scheduleModel}
      />
    </ModalDialog>
  );
};

ScheduleModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(viewModes),
  adminMode: PropTypes.bool,
  userMode: PropTypes.bool,
  staffId: PropTypes.string,
  patientId: PropTypes.string
};

export default ScheduleModal;
