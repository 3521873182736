import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Check from '@material-ui/icons/Check';

import styles from './checkboxStyle';

const checkbox = ({ classes, onChange, checked, value, label, disabled }) => (
  <div
    className={
      classes.checkboxAndRadio + ' ' + classes.checkboxAndRadioHorizontal
    }
    style={{ marginTop: 4 }}
  >
    <FormControlLabel
      control={
        <Checkbox
          tabIndex={-1}
          onClick={onChange}
          checkedIcon={<Check className={classes.checkedIcon} />}
          icon={<Check className={classes.uncheckedIcon} />}
          checked={checked}
          disabled={disabled}
          value={value}
          classes={{
            checked: classes.checked,
            root: classes.checkRoot
          }}
        />
      }
      classes={{ label: classes.label }}
      label={label}
    />
  </div>
);

checkbox.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default withStyles(styles)(checkbox);
