import {
  cardTitle,
  whiteColor,
  dangerColor
} from '../../mui-pro/assets/jss/material-kit-pro-react.jsx';

import modalStyle from '../../mui-pro/assets/jss/material-kit-pro-react/modalStyle';

const modalDialogStyles = theme => {
  // const {
  //   modalRoot,
  //   modal,
  //   modalHeader,
  //   modalTitle,
  //   modalCloseButton,
  //   modalClose,
  //   modalBody,
  //   modalFooter,
  //   modalFooterCenter,
  //   instructionNoticeModal,
  //   imageNoticeModal,
  //   modalLarge,
  //   modalSmall,
  //   modalSmallBody,
  //   modalSmallFooterFirstButton,
  //   modalSmallFooterSecondButton,
  //   modalLogin,
  //   modalLoginCard,
  //   modalSignup,
  //   modalSignupCard
  // } = modalStyle(theme);
  return {
    // modalRoot,
    // modal,
    // modalHeader,
    // modalTitle,
    // modalCloseButton,
    // modalClose,
    // modalBody,
    // modalFooter,
    // modalFooterCenter,
    // instructionNoticeModal,
    // imageNoticeModal,
    // modalLarge,
    // modalSmall,
    // modalSmallBody,
    // modalSmallFooterFirstButton,
    // modalSmallFooterSecondButton,
    // modalLogin,
    // modalLoginCard,
    // modalSignup,
    // modalSignupCard,
    ...modalStyle(theme),
    modalRegister: {
      ...modalStyle(theme).modalLogin,
      maxWidth: 450
    },
    wide: {},
    wider: {},
    cardAdjust: {
      height: '100%'
    },
    modalBodyAdjust: {
      paddingRight: 'unset',
      paddingLeft: 'unset',
      overflowY: 'scroll'
    },
    variableHeight: { height: 'unset' },
    cardBodyAdjust: { paddingLeft: 24, paddingRight: 24 },
    modalCustom: {
      ...modalStyle(theme).modal,
      maxWidth: 'unset',
      margin: 0,
      marginTop: 0,
      height: '100vh',
      borderRadius: 0
    },
    cardLoginHeader: {
      marginTop: 0,
      padding: '20px 0',
      width: '100%',
      marginBottom: '15px'
    },
    '@media (min-width: 596px)': {
      modalCustom: {
        margin: 48,
        marginTop: '130px !important',
        height: 'unset',
        borderRadius: 6
      },
      cardAdjust: { height: 'unset' },
      cardLoginHeader: { marginTop: -40 },
      modalBodyAdjust: {
        paddingRight: 24,
        paddingLeft: 24,
        height: '55vh'
      },
      variableHeight: { height: 'unset' },
      wide: { maxWidth: 500 },
      wider: {
        maxWidth: 600
      }
    },
    textCenter: {
      textAlign: 'center'
    },
    cardTitleWhite: {
      ...cardTitle,
      color: whiteColor + '  !important'
    },
    cardLoginBody: {
      paddingTop: '0',
      paddingBottom: '0'
      //height: '55vh'
    },
    footerBorder: {
      borderTop: '1px solid #E0E0E0'
    },
    justifyContentCenter: {
      WebkitBoxPack: 'center !important',
      MsFlexPack: 'center !important',
      justifyContent: 'center !important'
    },
    wrapper: {
      margin: theme.spacing(1),
      height: 40,
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-end',
      alignContent: 'center'
    },
    progressWrapper: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      position: 'relative'
    },
    buttonProgress: {
      color: '#9c27b0',
      position: 'absolute',
      top: '35%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12
    },
    error: {
      color: dangerColor[0],
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      paddingRight: 10,
      textAlign: 'left'
    }
  };
};

export default modalDialogStyles;
