import {
  description,
  grayColor,
  infoColor,
  primaryColor,
  dangerColor,
  warningColor
} from '../../mui-pro/assets/jss/material-kit-pro-react.jsx';

export const toolbarColor = 'rgba(255,255,255,1)';
const titleBarHeight = 40;
const groupHeaderHeight = 30;
const colHeaderHeight = 30;
const rowHeight = 30;

const selectable = {
  cursor: 'pointer',
  pointerEvents: 'auto',
  '& *': { pointerEvents: 'none', cursor: 'pointer' }
};

const flexTableStyle = {
  selectable,
  flextable: {
    backgroundColor: grayColor[1],
    width: '100%',
    borderRadius: 3,
    overflow: 'hidden',
    marginBottom: 20
  },
  tablebody: {
    width: '100%',
    padding: '10px 2px 10px 2px',
    display: 'flex',
    flexDirection: 'column'
  },
  titlebar: {
    backgroundColor: infoColor[0],
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: toolbarColor,
    minHeight: titleBarHeight,
    boxSizing: 'border-box'
  },
  controlGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  title: {
    ...description,
    display: 'inline',
    padding: 0,
    margin: 0,
    color: 'inherit',
    textAlign: 'center'
  },
  tablegroup: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  groupheadTitle: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: 12,
    lineHeight: '14px',
    color: 'rgba(255,255,255,0.87)',
    fontWeight: 600,
    minHeight: groupHeaderHeight,
    boxSizing: 'border-box'
  },
  tablerow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: rowHeight,
    boxSizing: 'border-box',
    padding: 2
  },
  tablerowSelectable: {
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.87)'
    }
  },
  tablecellWrapper: {
    padding: '0px 2px 0px 2px',
    height: '100%',
    boxSizing: 'border-box'
  },
  columnHead: {
    width: '100%',
    backgroundColor: primaryColor[1],
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: 12,
    lineHeight: '14px',
    fontWeight: 600,
    color: 'rgba(255,255,255,0.87)',
    minHeight: colHeaderHeight,
    boxSizing: 'boder-box',
    borderRadius: 3
  },
  tablecell: {
    width: '100%',
    backgroundColor: infoColor[0],
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: 12,
    lineHeight: '14px',
    fontWeight: 400,
    color: 'rgba(255,255,255,0.87)',
    minHeight: rowHeight,
    boxSizing: 'border-box',
    borderRadius: 3
  },
  alertcell: {
    backgroundColor: dangerColor[0]
  },
  warncell: {
    backgroundColor: warningColor[0]
  },
  actionicon: {
    color: 'rgba(255,255,255,0.87)'
  },
  actioniconSelectable: {
    ...selectable,
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.87)',
      color: infoColor[0],
      borderRadius: 5
    }
  }
};

export default flexTableStyle;
