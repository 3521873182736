import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import GroupHead from './GroupHead';
import TableRow from './TableRow';
import style from './flextableStyle';

const TableGroup = ({ classes, tableControl, group }) => {
  const { resolve } = tableControl;

  // console.log('render');
  return (
    <div className={classes.tablegroup}>
      {group.noGroupHeader || (
        <GroupHead tableControl={tableControl} group={group} />
      )}
      {group.data &&
        resolve(group.data).map(row => (
          <TableRow
            tableControl={tableControl}
            group={group}
            row={row}
            key={resolve(row.id)}
          />
        ))}
    </div>
  );
};

TableGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  tableControl: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired
};

export default withStyles(style)(TableGroup);
