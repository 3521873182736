import {
  cardTitle,
  cardSubtitle
} from '../../mui-pro/assets/jss/material-kit-pro-react.jsx';

const staffCardStyle = {
  profile: {
    maxWidth: 350,
    margin: '50px 10px 0px 10px'
  },
  featuredProfile: {
    maxWidth: '80%'
  },
  profileName: {
    ...cardTitle,
    marginBottom: 0,
    marginTop: 0
  },
  profileTitle: {
    ...cardSubtitle,
    marginTop: 0,
    marginBottom: 10
  }
};

export default staffCardStyle;
