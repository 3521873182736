import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Link, Location } from '@reach/router';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import Menu from '@material-ui/icons/Menu';
import Close from '@material-ui/icons/Close';

import HeaderLinks from './HeaderLinks';
import headerStyle from './headerStyle';

// The website Header component
// This header component was adapted from the Material Kit React Pro
// landing page which you can see here:
// https://demos.creative-tim.com/material-kit-pro-react/#/landing-page
// But it was modified considerably for BKPT

const Header = ({ classes, title, fixed, absolute, changeOnScroll }) => {
  // Flag to track if menu bar is open on mobile devices
  // (it is always open on a full-width device)
  const [mobileOpen, setMobileOpen] = useState(false);
  // Option for size-changing header (we don't use this)
  const [fullHeader, setFullHeader] = useState(true);

  // This scroll handler is used to change the header size
  // when scrolling (we aren't using this)
  // Here we register a scroll handler to manage this
  useEffect(() => {
    // register the handler on component mount
    window.addEventListener('scroll', scrollHandler);
    return () => {
      // remove the handler on dismount
      window.removeEventListener('scroll', scrollHandler);
    };
  });

  const scrollHandler = () => {
    if (window.pageYOffset > changeOnScroll.triggerPos) {
      if (fullHeader) {
        setFullHeader(false);
      }
    } else {
      if (!fullHeader) {
        setFullHeader(true);
      }
    }
  };

  // click handler to open the menu bar on a mobile device
  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  const settings = fullHeader ? changeOnScroll.full : changeOnScroll.compact;

  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[settings.color]]: true,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed,
    [classes.expanded]: false
  });

  const toolbarStyles = {
    maxWidth: 'unset'
  };

  // const logo = settings.logo ? (
  //   <img
  //     src={settings.logo}
  //     className={classes.logo}
  //     style={{
  //       height: settings.height,
  //       width: 'auto'
  //     }}
  //     alt="TODO"
  //   />
  // ) : null;

  const logo = settings.logo ? (
    <div className={classes.logo}>
      <Img fixed={settings.logo.fixed} />
    </div>
  ) : null;

  const links = (
    <Location>
      {({ location, navigate }) => (
        <HeaderLinks
          dropdownHoverColor="info"
          routing={{ location, navigate }}
          drawer={{ mobileOpen, setMobileOpen }}
        />
      )}
    </Location>
  );

  return (
    <AppBar className={appBarClasses} id="header">
      <Toolbar className={classes.container} style={toolbarStyles}>
        <Link to="/" className={classes.titleLink}>
          {logo}
          <p className={classes.titleText}>{title}</p>
        </Link>
        <Hidden smDown implementation="css" className={classes.hidden}>
          <div className={classes.collapse} style={{ flexDirection: 'column' }}>
            {links}
          </div>
        </Hidden>
        <Hidden mdUp>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={'right'}
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper
          }}
          onClose={handleDrawerToggle}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            className={classes.closeButtonDrawer}
          >
            <Close />
          </IconButton>
          <div className={classes.appResponsive}>{links}</div>
        </Drawer>
      </Hidden>
    </AppBar>
  );
};

Header.defaultProp = {
  color: 'white'
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  changeOnScroll: PropTypes.shape({
    triggerPos: PropTypes.number.isRequired,
    full: PropTypes.shape({
      height: PropTypes.number,
      color: PropTypes.oneOf([
        'primary',
        'info',
        'success',
        'warning',
        'danger',
        'transparent',
        'white',
        'rose',
        'dark'
      ]).isRequired,
      logo: PropTypes.object
    }).isRequired,
    compact: PropTypes.shape({
      height: PropTypes.number,
      color: PropTypes.oneOf([
        'primary',
        'info',
        'success',
        'warning',
        'danger',
        'transparent',
        'white',
        'rose',
        'dark'
      ]).isRequired,
      logo: PropTypes.object
    }).isRequired
  })
};

export default withStyles(headerStyle)(Header);
