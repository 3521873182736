import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';

import { viewMode, stdView } from '../viewControl';
import EmbeddedForm from '../EmbeddedForm/EmbeddedForm';
import Checkbox from '../Checkbox/Checkbox';
import SelectList from '../SelectList/SelectList';
import { AuthContext } from '../../firebase';
import { util } from 'bkptshared';

// Field IDs - define them as constants to avoid typos
const AVAIL_STATUS = 'availStatus';
const DATE_END = 'dateEnd';
const DATE_START = 'dateStart';
const HAS_END = 'hasEnd';
const DAYS = 'days';
const RECURRING = 'recurring';
const TIME_START = 'timeStart';
const TIME_END = 'timeEnd';
const TYPE = 'type';

const timeList = new Array(24).fill('').map((_, i) => ({
  key: i,
  value: util.hourToString12(i)
}));

const AvailabilityView = ({ adminMode, scheduleModel, viewControl }) => {
  const authControl = useContext(AuthContext);
  const { STAFF, LOCATION } = scheduleModel;

  /////////////////////////////////////////////////
  // Detail Form View

  const handleSubmit = ({ fieldState, entity, view }) => {
    const hasEnd = fieldState(HAS_END).value;
    const DateStart = util.parseDate(fieldState(DATE_START).value);
    const DateEnd = util.parseDate(
      hasEnd ? fieldState(DATE_END).value : util.FOREVER
    );
    const Recurring = Boolean(
      DateStart !== DateEnd && fieldState(RECURRING).value
    );
    const Days = util.dayList.map(({ key }) =>
      Boolean(
        Recurring && fieldState(DAYS).value.findIndex(d => d === key) !== -1
      )
    );
    const availability = {
      StaffID: fieldState(STAFF).value,
      LocationID: fieldState(LOCATION).value,
      Unavailable: fieldState(AVAIL_STATUS).value === util.statusList[1].key,
      DateStart,
      DateEnd,
      Recurring,
      Days,
      TimeStart: fieldState(TIME_START).value,
      TimeEnd: fieldState(TIME_END).value,
      Type: fieldState(TYPE).value
    };
    if (view.mode === viewMode.CREATE) {
      return scheduleModel
        .createAvailability(availability)
        .then(resp => ({ close: true }));
    } else {
      return scheduleModel
        .updateAvailability(entity.key, availability)
        .then(resp => ({ close: true }));
    }
  };

  const detailForm = {
    onSubmit: handleSubmit,
    title: ({ view }) =>
      view.mode === viewMode.UPDATE
        ? 'Modify Availability'
        : 'Schedule Availability',
    fields: [
      {
        id: LOCATION,
        component: SelectList,
        label: 'Location',
        required: true,
        validate: [{ required: 'Please select a location.' }],
        initForUpdate: ({ entity }) => ({ value: entity.val.LocationID })
      },
      {
        id: STAFF,
        disabled: !adminMode,
        component: SelectList,
        label: 'Staff',
        required: true,
        validate: [{ required: 'Please select a staff member.' }],
        initForCreate: { value: adminMode ? '' : authControl.user.uid },
        initForUpdate: ({ entity }) => ({ value: entity.val.StaffID })
      },
      {
        id: AVAIL_STATUS,
        component: SelectList,
        label: 'Availability',
        required: true,
        validate: [{ required: 'Please select availability.' }],
        initForCreate: { value: util.statusList[0].key, list: util.statusList },
        initForUpdate: ({ entity }) => ({
          value: util.statusList[entity.val.Unavailable ? 1 : 0].key,
          list: util.statusList
        })
      },
      {
        id: TYPE,
        component: SelectList,
        label: 'Type',
        required: true,
        validate: [{ required: 'Please select availability type.' }],
        initForCreate: { value: util.availList[0].key, list: util.availList },
        initForUpdate: ({ entity }) => ({
          value: entity.val.Type,
          list: entity.val.Unavailable ? util.notAvailList : util.availList
        })
      },
      {
        id: DATE_START,
        component: SelectList,
        label: 'Availability Date',
        calendar: true,
        required: true,
        validate: [{ validDate: 'Please  a start date.' }],
        initForUpdate: ({ entity }) => ({
          value: moment(util.parseDate(entity.val.DateStart))
        })
      },
      {
        id: HAS_END,
        component: Checkbox,
        label: 'Specify End Date',
        initForUpdate: ({ entity }) => ({
          value: !util.isForever(entity.val.DateEnd)
        })
      },
      {
        id: DATE_END,
        omitField: ({ fieldState }) => !fieldState(HAS_END).value,
        component: SelectList,
        label: 'Availability End Date',
        calendar: true,
        required: ({ fieldState }) => !fieldState(HAS_END).value,
        validate: [{ validDate: 'Please select an end date.' }],
        initForUpdate: ({ entity }) => ({
          value: moment(util.parseDate(entity.val.DateEnd))
        })
      },
      {
        id: TIME_START,
        component: SelectList,
        label: 'Start Time',
        required: true,
        validate: [{ required: 'Please select a time.' }],
        initForCreate: { value: 8, list: timeList },
        initForUpdate: ({ entity }) => ({
          value: entity.val.TimeStart,
          list: timeList
        })
      },
      {
        id: TIME_END,
        component: SelectList,
        label: 'End Time',
        required: true,
        validate: [{ required: 'Please select a time.' }],
        initForCreate: { value: 16, list: timeList },
        initForUpdate: ({ entity }) => ({
          value: entity.val.TimeEnd,
          list: timeList
        })
      },
      {
        id: RECURRING,
        omitField: ({ fieldState }) =>
          fieldState(HAS_END).value &&
          util.parseDate(fieldState(DATE_START).value) ===
            util.parseDate(fieldState(DATE_END).value),
        component: Checkbox,
        label: 'Repeat Weekly',
        initForCreate: { value: false },
        initForUpdate: ({ entity }) => ({ value: entity.val.Recurring })
      },
      {
        id: DAYS,
        omitField: ({ fieldState }) =>
          (fieldState(HAS_END).value &&
            util.parseDate(fieldState(DATE_START).value) ===
              util.parseDate(fieldState(DATE_END).value)) ||
          !fieldState(RECURRING).value,
        component: SelectList,
        label: 'Select Days',
        multiple: true,
        required: true,
        validate: [{ required: 'Please select occurrance days.' }],
        initForCreate: { list: util.dayList, value: [] },
        initForUpdate: ({ entity }) => ({
          value: entity.val.Days.map((day, i) =>
            day ? util.dayList[i].key : null
          ).filter(day => day),
          list: util.dayList
        })
      }
    ],
    effects: [
      {
        run: scheduleModel.fetchAppointmentData
      },
      {
        run: ({ fieldState, setFieldState }) => {
          const avail = fieldState(AVAIL_STATUS);
          const type = fieldState(TYPE);
          const isAvail = avail.value === util.statusList[0].key;
          const list = isAvail ? util.availList : util.notAvailList;
          if (
            type.list !== list ||
            type.list.findIndex(item => item.key === type.value) === -1
          ) {
            let index = list.findIndex(item => item.key === type.value);
            if (index === -1) {
              index = 0;
            }
            setFieldState(TYPE)({ list: list, value: list[index].key });
          }
        },
        fieldDeps: [AVAIL_STATUS]
      }
    ]
  };

  // console.log('render');
  return (
    <EmbeddedForm
      id={stdView.DETAIL}
      viewControl={viewControl}
      form={detailForm}
    />
  );
};

AvailabilityView.propTypes = {
  viewControl: PropTypes.object,
  adminMode: PropTypes.bool,
  userMode: PropTypes.bool,
  scheduleModel: PropTypes.object.isRequired
};

export default AvailabilityView;
