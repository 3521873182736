import {
  cardTitle,
  cardSubtitle
} from '../../mui-pro/assets/jss/material-kit-pro-react.jsx';

const htmlCardStyle = {
  card: {
    maxWidth: 300,
    margin: '50px 10px 0px 10px'
  },
  title: {
    '&, & a': {
      ...cardTitle,
      color: 'white'
    }
  },
  cardTitle,
  cardSubtitle
};

export default htmlCardStyle;
