import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { util } from 'bkptshared';

import Home from '@material-ui/icons/Home';
import Cancel from '@material-ui/icons/Cancel';
import Close from '@material-ui/icons/Close';

import FlexTable from '../FlexTable/FlexTable';
import { viewMode } from '../viewControl';

const UserScheduleView = ({ viewControl, scheduleModel }) => {
  const { pending, setPending, setError } = viewControl;
  const [today] = useState(util.parseDate(moment()));
  const [appointments, setAppointments] = useState(null);
  const [staff, setStaff] = useState(null);
  const [locations, setLocations] = useState({});

  useEffect(() => {
    setPending(true);
    scheduleModel
      .fetchAppointments(today)
      .then(results => {
        setPending(false);
        setAppointments(results);
      })
      .catch(error => {
        setPending(false);
        setError(error);
      });
  }, []);

  useEffect(() => {
    if (appointments) {
      let users = {};
      appointments.forEach(({ val: { StaffID } }) => {
        users[StaffID] = true;
      });
      const fetchList = Object.keys(users);
      if (fetchList.length > 0) {
        setPending(true);
        scheduleModel
          .fetchUsers(fetchList)
          .then(userList => {
            setPending(false);
            users = {};
            userList.forEach(entity => (users[entity.key] = entity));
            setStaff(users);
          })
          .catch(error => {
            setPending(false);
            setError(error);
          });
      }
    }
  }, [appointments]);

  const getLocations = list => {
    const locs = {};
    if (list) {
      list.forEach(({ val: { LocationID } }) => (locs[LocationID] = true));
    }
    return Object.keys(locs);
  };

  useEffect(() => {
    if (appointments) {
      setPending(true);
      const locs = {};
      getLocations(appointments).map(loc => (locs[loc] = true));
      scheduleModel
        .fetchLocations(Object.keys(locs))
        .then(results => {
          const newLocs = {};
          results.forEach(entity => (newLocs[entity.key] = entity));
          setLocations(newLocs);
          setPending(false);
        })
        .catch(error => {
          setPending(false);
          setError(error);
        });
    }
  }, [appointments]);

  const cancelAppointment = row => {
    const { entity } = row;
    setPending(true);
    Promise.all([
      scheduleModel.fetchLocations([entity.val.LocationID]),
      scheduleModel.fetchUsers([entity.val.StaffID, entity.val.PatientID])
    ])
      .then(results => {
        setPending(false);
        const loc = results[0][0].val;
        const staff = results[1][0].val;
        const patient = results[1][1].val;
        viewControl.actionDialog({
          title: 'Cancel Appointment',
          body: (
            <span>
              <h5>Do you want to cancel this appointment?</h5>
              {scheduleModel.appointmentTable(row.entity, loc, staff, patient)}
            </span>
          ),
          actions: [
            {
              title: 'Go Back',
              color: 'success',
              onActivate: viewControl => viewControl.rewind()
            },
            {
              title: 'Cancel',
              color: 'danger',
              icon: Close,
              onActivate: viewControl => {
                viewControl.setPending(true);
                scheduleModel
                  .cancelAppointment(entity)
                  .then(() => {
                    viewControl.setPending(false);
                    viewControl.rewind();
                  })
                  .catch(error => {
                    viewControl.setPending(false);
                    viewControl.setError(error);
                    viewControl.rewind();
                  });
              }
            }
          ]
        });
      })
      .catch(error => {
        setPending(false);
        setError(error);
      });
  };

  const appointmentCols = [
    {
      id: 'status',
      span: 2,
      head: 'Status',
      alert: 'Unconfirmed',
      warn: 'Canceled'
    },
    { id: 'dateTime', span: 2, head: 'Date/Time' },
    { id: 'staff', span: 3, head: 'Therapist' },
    { id: 'actions', span: 1, head: 'Cancel', actions: true }
  ];
  const appointmentGroups = appointments
    ? getLocations(appointments).map(locId => ({
        id: `appointments-${locId}`,
        title: locations[locId]
          ? `Appointments in ${locations[locId].val.LocationName}`
          : 'Appointments',
        cols: appointmentCols,
        data: appointments
          .filter(({ val }) => val.LocationID === locId)
          .sort((a, b) =>
            a.val.DateTime < b.val.DateTime
              ? -1
              : a.val.DateTime > b.val.DateTime
              ? 1
              : 0
          )
          .map(entity => ({
            id: entity.key,
            entity,
            cells: {
              status: entity.val.Canceled
                ? 'Canceled'
                : entity.val.Confirmed
                ? 'Confirmed'
                : 'Unconfirmed',
              dateTime: (
                <span>
                  {moment(util.parseDate(entity.val.DateTime)).format(
                    'ddd MMM D'
                  )}
                  <br />
                  {util.hourToString12(util.parseHour(entity.val.DateTime))}
                </span>
              ),
              staff: (
                <>
                  {entity.val.AtHome && (
                    <Home
                      size="small"
                      style={{
                        color: 'rgba(255,255,255,0.87)',
                        paddingRight: 3
                      }}
                    />
                  )}
                  {staff && staff[entity.val.StaffID]
                    ? staff[entity.val.StaffID].val.name
                    : '...'}
                </>
              ),
              actions: [
                {
                  icon: Cancel,
                  title: 'Cancel Appointment',
                  omit: entity.val.Canceled,
                  active: true,
                  onClick: row => cancelAppointment(row)
                }
              ]
            },
            onClick: () =>
              viewControl.presentView({
                id: scheduleModel.appointmentViewId,
                mode: viewMode.READ,
                entity,
                disallowEdit: entity.val.Confirmed || entity.val.Canceled
              })
          }))
      }))
    : [
        {
          id: 'appointments',
          title: 'Loading Appointments...',
          cols: appointmentCols,
          data: []
        }
      ];

  const tableModel = {
    title: 'Upcoming Appointments',
    data: [...appointmentGroups]
  };

  useEffect(() => viewControl.setActions(scheduleModel.actions(viewControl)), [
    pending
  ]);

  // console.log('render');
  return <FlexTable viewControl={viewControl} tableModel={tableModel} />;
};

UserScheduleView.propTypes = {
  viewControl: PropTypes.object,
  adminMode: PropTypes.bool,
  scheduleModel: PropTypes.object.isRequired
};

export default UserScheduleView;
