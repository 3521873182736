import React from 'react';
import PropTypes from 'prop-types';

import HtmlCard from '../HtmlCard/HtmlCard';

const ProductCard = ({ products, color, injectStyles, injectClasses }) => {
  return products.map(({ productId, image, name }) => (
    <HtmlCard
      injectClasses={injectClasses}
      title={name}
      image={image}
      color={color}
      injectStyles={injectStyles}
      key={productId}
    />
  ));
};

// eslint-disable-next-line react/display-name
ProductCard.createFromCms = cmsContext => {
  const {
    key,
    styles,
    localStyles,
    component: { color },
    data: { products }
  } = cmsContext;
  const props = {
    products,
    color,
    injectClasses: styles,
    injectStyles: localStyles
  };
  return <ProductCard products={products} {...props} key={key} />;
};

ProductCard.propTypes = {
  products: PropTypes.array.isRequired,
  color: PropTypes.string,
  injectClasses: PropTypes.array,
  injectStyles: PropTypes.object
};

export default ProductCard;
