import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import TitleBar from './TitleBar';
import TableGroup from './TableGroup';
import style from './flextableStyle';

const FlexTable = ({ classes, viewControl, tableModel }) => {
  const resolve = (v, ...rest) =>
    v && typeof v === 'function' ? v(tableControl, ...rest) : v;
  const tableControl = {
    viewControl,
    tableModel,
    resolve
  };

  // console.log('render');
  return (
    <div className={classes.flextable}>
      <TitleBar tableControl={tableControl} />
      <div className={classes.tablebody}>
        {resolve(tableModel.data).map(group => (
          <TableGroup
            tableControl={tableControl}
            group={group}
            key={resolve(group.id)}
          />
        ))}
      </div>
    </div>
  );
};

FlexTable.propTypes = {
  classes: PropTypes.object.isRequired,
  viewControl: PropTypes.object,
  tableModel: PropTypes.object.isRequired
};

export default withStyles(style)(FlexTable);
