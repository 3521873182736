import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ImageBlock = ({ image, injectClasses, injectStyles }) => {
  const classes = injectClasses ? { className: classNames(injectClasses) } : {};
  const styles = injectStyles ? { style: injectStyles } : {};
  return <img {...classes} src={image.url} alt={image.alt} {...styles} />;
};

// eslint-disable-next-line react/display-name
ImageBlock.createFromCms = cmsContext => {
  const {
    sectionKey,
    key,
    styles,
    localStyles,
    component: { image }
  } = cmsContext;
  const props = {
    image: image && image.value && image.value[0] && image.value[0].image,
    injectClasses: styles,
    injectStyles: localStyles
  };
  // eslint-disable-next-line react/prop-types
  if (!props.image) {
    // eslint-disable-next-line no-console
    console.error(`Null image in ${sectionKey}`);
  }
  return <ImageBlock {...props} key={key} />;
};

ImageBlock.propTypes = {
  image: PropTypes.object.isRequired,
  injectClasses: PropTypes.array,
  injectStyles: PropTypes.object
};

export default ImageBlock;
