import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Datetime from 'react-datetime';

import DateTimePicker from '../DateTimePicker/DateTimePicker';

import styles from '../../mui-pro/assets/jss/material-kit-pro-react/customSelectStyle.jsx';

const selectList = ({
  classes,
  name,
  id,
  title,
  data,
  onChange,
  getListItem,
  calendar,
  disabled,
  multiple
}) => {
  const today = Datetime.moment();
  var validDate = current => {
    return current.isAfter(today);
  };

  return calendar ? (
    <DateTimePicker
      name={name}
      id={id}
      labelText={title}
      isValidDate={validDate}
      error={data.error}
      disabled={disabled}
      inputProps={{
        value: data.value,
        onChange
      }}
      formControlProps={{
        fullWidth: true,
        className: classes.selectFormControl
      }}
    />
  ) : (
    <FormControl
      fullWidth
      className={classes.selectFormControl}
      disabled={disabled}
    >
      <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
        {title}
      </InputLabel>
      <Select
        MenuProps={{ className: classes.selectMenu }}
        classes={{ select: classes.select }}
        value={data.value ? data.value : multiple ? [] : ''}
        onChange={onChange}
        inputProps={{ name, id }}
        multiple={multiple}
      >
        <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
          {data.head ? data.head : title}
        </MenuItem>
        {data.list
          ? data.list.map(item => {
              const { key, value } = getListItem ? getListItem(item) : item;
              return (
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  value={key}
                  key={key}
                >
                  {value}
                </MenuItem>
              );
            })
          : null}
      </Select>
    </FormControl>
  );
};

selectList.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  getListItem: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  calendar: PropTypes.bool,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool
};

export default withStyles(styles)(selectList);
