import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import Button from '../../mui-pro/components/CustomButtons/Button';
import CustomInput from '../../mui-pro/components/CustomInput/CustomInput';

import { viewMode } from '../viewControl';
import SelectList from '../SelectList/SelectList';
import Checkbox from '../Checkbox/Checkbox';

import styles from './embeddedFormStyles';

const EmbeddedForm = ({ classes, viewControl, form }) => {
  const { view, pending, setPending, setError } = viewControl;
  const { mode, entity, disallowEdit } = view;
  const [readOnly, setReadOnly] = useState(mode === viewMode.READ);
  const formState = {};
  const fieldState = id => formState[id][0];
  fieldState.all = () => {
    const states = {};
    for (const id in formState) {
      states[id] = formState[id][0];
    }
    return states;
  };
  const setFieldState = id => formState[id][1];
  setFieldState.all = () => {
    const setters = {};
    for (const id in formState) {
      setters[id] = formState[id][1];
    }
    return setters;
  };
  const formControl = {
    viewControl,
    handleSubmit,
    fieldState,
    setFieldState,
    view,
    entity
  };
  const resolve = (v, ...rest) =>
    v && typeof v === 'function' ? v(formControl, ...rest) : v;

  form.fields.forEach(field => {
    if (mode === viewMode.CREATE) {
      const { id, initForCreate } = field;
      formState[id] = useState(resolve(initForCreate) || {});
    } else if (mode === viewMode.UPDATE || mode === viewMode.READ) {
      const { id, initForUpdate } = field;
      formState[id] = useState(resolve(initForUpdate) || {});
    } else {
      // eslint-disable-next-line no-console
      console.error('Invalid form mode', mode);
    }
  });

  if (form.effects) {
    form.effects.forEach(effect => {
      if (!effect.disabled) {
        useEffect(() => effect.run(formControl), [
          ...(effect.fieldDeps
            ? effect.fieldDeps.map(id => formState[id][0].value)
            : []),
          ...(effect.valueDeps ? effect.valueDeps : [])
        ]);
      }
    });
  }

  const fieldEventValue = (field, event) => {
    if (event === null || event === undefined) {
      return event;
    }
    if (event.target) {
      return event.target.value;
    }
    if (event.isValid) {
      return event;
    }
    if (event === false || event === true) {
      return event;
    }
    // eslint-disable-next-line no-console
    console.error('Invalid field data');
    return event;
  };

  viewControl.handleEvents({
    //todo check for unsaved changes
    canClose: () => true,
    willClose: () => {}
  });

  function handleSubmit(formData = null, validate = true) {
    if (!validate || validateForm()) {
      setPending(true);
      formControl.formData = formData;
      form
        .onSubmit(formControl)
        .then(result => {
          setPending(false);
          setError(null);
          if (result.confirm) {
            viewControl.confirmDialog(result.confirm);
          } else if (result.replaceView) {
            viewControl.replaceView(result.replaceView);
          } else {
            if (!result.keepOpen) {
              viewControl.rewind();
            }
          }
        })
        .catch(error => {
          setPending(false);
          setError(error.message);
        });
    }
  }

  const findField = id => form.fields.find(field => id === field.id);

  const validate = {
    required: (fieldValue, msg) => {
      const value = fieldValue ? String(fieldValue).trim() : '';
      const error = value.length === 0;
      const alert = error ? msg : undefined;
      return { value, error, alert };
    },
    date: (moment, msg) => {
      const value =
        moment && moment.isValid && moment.isValid() ? moment : null;
      const error = value === null;
      const alert = error ? msg : undefined;
      return { value, error, alert };
    },
    email: (email, msg) => {
      const value = email ? email.trim() : '';
      const error = !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value);
      const alert = error ? msg : undefined;
      return { value, error, alert };
    },
    minLength: (fieldValue, min, msg) => {
      const value = fieldValue ? String(fieldValue).trim() : '';
      const error = value.length < min;
      const alert = error ? msg : undefined;
      return { value, error, alert };
    },
    compareEqual: (fieldValue, field, msg) => {
      const value = fieldValue ? String(fieldValue).trim() : '';
      const val2 =
        field && fieldState(field.id).value
          ? String(fieldState(field.id).value).trim()
          : '';
      const error = value !== val2;
      const alert = error ? msg : undefined;
      return { value, error, alert };
    }
  };

  const validateField = (field, value) => {
    let validation = {};
    if (field.validator) {
      validation = field.validator(field, value);
    } else {
      if (field.validate) {
        field.validate.every(test => {
          if (test.required) {
            validation = validate.required(value, test.required);
          } else if (test.validDate) {
            validation = validate.date(value, test.validDate);
          } else if (test.email) {
            validation = validate.email(value, test.email);
          } else if (test.minLength) {
            const [min, msg] = test.minLength;
            validation = validate.minLength(value, min, msg);
          } else if (test.compareEqual) {
            const [id, msg] = test.compareEqual;
            const field = findField(id);
            validation = validate.compareEqual(value, field, msg);
          } else {
            // eslint-disable-next-line no-console
            console.error('Invalid validation rule', test);
          }
          return !validation.error;
        });
      }
    }
    return validation;
  };

  const onFieldChange = (event, field) => {
    const id = field.id;
    const curState = fieldState(id);
    let value = fieldEventValue(field, event);
    if (field.component === Checkbox) {
      value = !curState.value;
    }
    const validation = validateField(field, value);
    setFieldState(id)({ ...curState, ...validation, value });
  };

  const validateForm = () => {
    if (form.customValidate) {
      return form.customValidate();
    } else {
      const hasErrors = form.fields.reduce((hasError, field) => {
        if (resolve(field.omitField)) {
          return hasError;
        }
        const id = field.id;
        const curState = fieldState(id);
        const value = curState.value;
        const validation = validateField(field, value);
        if (validation.error) {
          setFieldState(id)({ ...curState, ...validation });
        }
        return validation.error || hasError;
      }, false);
      if (hasErrors) {
        setError('Please correct errors noted above.');
      }
      return !hasErrors;
    }
  };
  const actions = [
    <Button
      variant="contained"
      color="primary"
      size="sm"
      onClick={viewControl.rewind}
      disabled={pending}
      key="1"
    >
      Cancel
    </Button>
  ];
  if (!readOnly || !disallowEdit) {
    actions.push(
      <Button
        variant="contained"
        color="primary"
        size="sm"
        onClick={() => (readOnly ? setReadOnly(false) : handleSubmit())}
        disabled={pending}
        key="2"
      >
        {readOnly ? 'Edit' : form.saveText ? form.saveText : 'Save'}
      </Button>
    );
  }
  useEffect(() => viewControl.setActions(actions), [
    pending,
    readOnly,
    form.saveText,
    ...Object.values(fieldState.all())
  ]);

  //TODO disable all fields when pending

  const renderField = field => {
    const {
      id,
      type = 'text',
      label = '',
      getListItem,
      calendar,
      multiline,
      multiple,
      fieldProps = {},
      inputProps = {},
      formControlProps = {}
    } = field;
    const disabled = resolve(field.disabled);
    const required = resolve(field.required);
    const state = fieldState(id);
    return (
      <div
        key={id}
        className={
          field.component === Checkbox
            ? classes.checkboxContainer
            : classes.fieldContainer
        }
      >
        {field.component === Checkbox ? (
          <Checkbox
            key={id}
            checked={!!state.value}
            value={id}
            label={label}
            disabled={readOnly || disabled}
            onChange={event => onFieldChange(event, field)}
            {...fieldProps}
          />
        ) : field.component === SelectList ? (
          <SelectList
            key={id}
            name={id}
            data={state}
            title={label}
            disabled={readOnly || disabled}
            multiple={multiple}
            {...{
              id,
              getListItem,
              calendar,
              error: state.error
            }}
            onChange={event => onFieldChange(event, field)}
            inputProps={{
              required,
              ...inputProps
            }}
            formControlProps={{
              fullWidth: true,
              error: state.error,
              ...formControlProps
            }}
            {...fieldProps}
          />
        ) : field.component === CustomInput ? (
          <CustomInput
            id={id}
            labelText={label}
            error={state.error}
            inputProps={{
              type,
              required,
              multiline,
              disabled: readOnly || disabled,
              value: state.value ? state.value : '',
              onChange: event => onFieldChange(event, field),
              ...inputProps
            }}
            formControlProps={{
              fullWidth: true,
              error: state.error,
              ...formControlProps
            }}
            {...fieldProps}
          />
        ) : (
          <field.component key={id} id={id} {...fieldProps} />
        )}
        {state.alert && (
          <span className={classes.fieldAlert}>{state.alert}</span>
        )}
      </div>
    );
  };

  const title = resolve(form.title);

  // console.log('render');
  return (
    <form>
      {title && (
        <h5 className={`${classes.formHeader} ${classes.textCenter}`}>
          {title}
        </h5>
      )}
      {form.header}
      {form.fields.map((field, index) =>
        !resolve(field.omitField) ? renderField(field) : null
      )}
      {resolve(form.footer)}
    </form>
  );
};

EmbeddedForm.propTypes = {
  classes: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  viewControl: PropTypes.object
};

export default withStyles(styles)(EmbeddedForm);
