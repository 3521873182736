import { description } from '../../mui-pro/assets/jss/material-kit-pro-react.jsx';

const usersModalStyles = theme => ({
  loginOption: {
    ...description,
    margin: '5px 0 0 0'
  },
  textCenter: {
    textAlign: 'center'
  },
  socialLine: {
    textAlign: 'center'
  },
  socialLineButton: {
    //'&, &:hover': { color: whiteColor },
    marginLeft: 8,
    marginRight: 8
  }
});

export default usersModalStyles;
