import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import flexListStyles from './flexListStyle';

const FlexList = ({
  classes,
  children,
  direction,
  wrap,
  justify,
  align,
  injectClasses,
  injectStyles
}) => {
  const vertical = direction === 'Vertical';
  const flexStyles = {
    flexDirection: vertical ? 'column' : 'row',
    flexWrap: wrap ? 'wrap' : 'nowrap',
    justifyContent: justify,
    alignItems: align,
    ...(vertical ? { height: '100%' } : { width: '100%' }),
    ...injectStyles
  };
  const flexClasses = classNames([classes.flexList, ...injectClasses]);
  return (
    <div style={flexStyles} className={flexClasses}>
      {children}
    </div>
  );
};

// eslint-disable-next-line react/display-name
FlexList.createFromCms = cmsContext => {
  const {
    key,
    children,
    styles,
    localStyles,
    component: { direction, wrap, justify, align }
  } = cmsContext;
  const props = {
    children,
    direction,
    wrap,
    justify,
    align,
    injectClasses: styles,
    injectStyles: localStyles
  };
  const FlexListCms = withStyles(flexListStyles)(FlexList);
  return <FlexListCms {...props} key={key} />;
};

FlexList.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.array,
  direction: PropTypes.string.isRequired,
  wrap: PropTypes.bool,
  justify: PropTypes.string,
  align: PropTypes.string,
  injectClasses: PropTypes.array,
  injectStyles: PropTypes.object
};

export default withStyles(flexListStyles)(FlexList);
