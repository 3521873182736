import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { util } from 'bkptshared';

import Home from '@material-ui/icons/Home';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Cancel from '@material-ui/icons/Cancel';
import Delete from '@material-ui/icons/Delete';
import DateRange from '@material-ui/icons/DateRange';
import Close from '@material-ui/icons/Close';

import FlexTable from '../FlexTable/FlexTable';
import { viewMode } from '../viewControl';

const StaffScheduleView = ({ viewControl, scheduleModel }) => {
  const { view, pending, setPending, setError } = viewControl;
  const { key, day } = view;
  const [staffId] = useState(key);
  const [staff, setStaff] = useState(null);
  const [availability, setAvailability] = useState(null);
  const [appointments, setAppointments] = useState(null);
  const [locations, setLocations] = useState({});
  const [patients, setPatients] = useState({});

  useEffect(() => {
    if (staffId) {
      setPending(true);
      Promise.all([
        scheduleModel.fetchUsers([staffId]),
        scheduleModel.fetchStaffAvailability(staffId, util.parseDate(day)),
        scheduleModel.fetchStaffAppointments(staffId, util.parseDate(day))
      ])
        .then(results => {
          setPending(false);
          setStaff(results[0][0].val);
          setAvailability(results[1]);
          setAppointments(results[2]);
        })
        .catch(error => {
          setPending(false);
          setError(error);
        });
    }
  }, [staffId]);

  useEffect(() => {
    if (appointments) {
      setPending(true);
      const userList = [];
      appointments.forEach(({ val: { PatientID } }) =>
        userList.push(PatientID)
      );
      scheduleModel
        .fetchUsers(userList)
        .then(results => {
          setPending(false);
          const newUsers = {};
          results.forEach(entity => (newUsers[entity.key] = entity));
          setPatients(newUsers);
        })
        .catch(error => {
          setPending(false);
          setError(error);
        });
    }
  }, [appointments]);

  const getLocations = list => {
    const locs = {};
    if (list) {
      list.forEach(({ val: { LocationID } }) => (locs[LocationID] = true));
    }
    return Object.keys(locs);
  };

  useEffect(() => {
    if (appointments || availability) {
      setPending(true);
      const locs = {};
      getLocations(appointments).map(loc => (locs[loc] = true));
      getLocations(availability).map(loc => (locs[loc] = true));
      scheduleModel
        .fetchLocations(Object.keys(locs))
        .then(results => {
          const newLocs = {};
          results.forEach(entity => (newLocs[entity.key] = entity));
          setLocations(newLocs);
          setPending(false);
        })
        .catch(error => {
          setPending(false);
          setError(error);
        });
    }
  }, [availability, appointments]);

  const deleteAvailability = ({ entity, cells }) => {
    setPending(true);
    Promise.all([
      scheduleModel.fetchLocations([entity.val.LocationID]),
      scheduleModel.fetchUsers([entity.val.StaffID])
    ])
      .then(results => {
        setPending(false);
        const loc = results[0][0].val;
        const user = results[1][0].val;
        viewControl.actionDialog({
          title: 'Verify Deletion',
          body: (
            <span>
              <h5>Are you sure you want to delete this availability?</h5>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <span style={{ fontWeight: 600 }}>Staff:&nbsp;</span>
                    </td>
                    <td>{user.name}</td>
                  </tr>
                  <tr>
                    <td>
                      <span style={{ fontWeight: 600 }}>Location:&nbsp;</span>
                    </td>
                    <td>{loc.LocationName}</td>
                  </tr>
                  <tr>
                    <td>
                      <span style={{ fontWeight: 600 }}>Status:&nbsp;</span>
                    </td>
                    <td>{cells.status}</td>
                  </tr>
                  <tr>
                    <td>
                      <span style={{ fontWeight: 600 }}>Date:&nbsp;</span>
                    </td>
                    <td>
                      {util.dateRangeString(
                        entity.val.DateStart,
                        entity.val.DateEnd,
                        'MMM D, YYYY'
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span style={{ fontWeight: 600 }}>Time:&nbsp;</span>
                    </td>
                    <td>{cells.time}</td>
                  </tr>
                </tbody>
              </table>
            </span>
          ),
          actions: [
            {
              title: 'Go Back',
              color: 'success',
              onActivate: viewControl => viewControl.rewind()
            },
            {
              title: 'Delete',
              color: 'danger',
              icon: Close,
              onActivate: viewControl => {
                viewControl.setPending(true);
                scheduleModel
                  .deleteAvailability(entity.key)
                  .then(() => {
                    viewControl.setPending(false);
                    viewControl.rewind();
                  })
                  .catch(error => {
                    viewControl.setPending(false);
                    viewControl.setError(error);
                    viewControl.rewind();
                  });
              }
            }
          ]
        });
      })
      .catch(error => {
        setPending(false);
        setError(error);
      });
  };

  const confirmAppointment = ({ entity }) => {
    setPending(true);
    Promise.all([
      scheduleModel.fetchLocations([entity.val.LocationID]),
      scheduleModel.fetchUsers([entity.val.StaffID, entity.val.PatientID])
    ])
      .then(results => {
        setPending(false);
        const loc = results[0][0].val;
        const staff = results[1][0].val;
        const patient = results[1][1].val;
        viewControl.actionDialog({
          title: 'Confirm Appointment',
          body: (
            <span>
              <h5>Do you want to confirm this appointment?</h5>
              {scheduleModel.appointmentTable(entity, loc, staff, patient)}
            </span>
          ),
          actions: [
            {
              title: 'Go Back',
              color: 'success',
              onActivate: viewControl => viewControl.rewind()
            },
            {
              title: 'Confirm',
              color: 'danger',
              icon: Close,
              onActivate: viewControl => {
                viewControl.setPending(true);
                scheduleModel
                  .confirmAppointment(entity)
                  .then(() => {
                    viewControl.setPending(false);
                    viewControl.rewind();
                  })
                  .catch(error => {
                    viewControl.setPending(false);
                    viewControl.setError(error);
                    viewControl.rewind();
                  });
              }
            }
          ]
        });
      })
      .catch(error => {
        setPending(false);
        setError(error);
      });
  };

  const cancelAppointment = ({ entity }) => {
    setPending(true);
    Promise.all([
      scheduleModel.fetchLocations([entity.val.LocationID]),
      scheduleModel.fetchUsers([entity.val.StaffID, entity.val.PatientID])
    ])
      .then(results => {
        setPending(false);
        const loc = results[0][0].val;
        const staff = results[1][0].val;
        const patient = results[1][1].val;
        viewControl.actionDialog({
          title: 'Cancel Appointment',
          body: (
            <span>
              <h5>Do you want to cancel this appointment?</h5>
              {scheduleModel.appointmentTable(entity, loc, staff, patient)}
            </span>
          ),
          actions: [
            {
              title: 'Go Back',
              color: 'success',
              onActivate: viewControl => viewControl.rewind()
            },
            {
              title: 'Cancel',
              color: 'danger',
              icon: Close,
              onActivate: viewControl => {
                viewControl.setPending(true);
                scheduleModel
                  .cancelAppointment(entity)
                  .then(() => {
                    viewControl.setPending(false);
                    viewControl.rewind();
                  })
                  .catch(error => {
                    viewControl.setPending(false);
                    viewControl.setError(error);
                    viewControl.rewind();
                  });
              }
            }
          ]
        });
      })
      .catch(error => {
        setPending(false);
        setError(error);
      });
  };

  const deleteAppointment = row => {
    const { entity } = row;
    setPending(true);
    Promise.all([
      scheduleModel.fetchLocations([entity.val.LocationID]),
      scheduleModel.fetchUsers([entity.val.StaffID, entity.val.PatientID])
    ])
      .then(results => {
        setPending(false);
        const loc = results[0][0].val;
        const staff = results[1][0].val;
        const patient = results[1][1].val;
        viewControl.actionDialog({
          title: 'Delete Appointment',
          body: (
            <span>
              <h5>Do you want to delete this appointment?</h5>
              {scheduleModel.appointmentTable(row.entity, loc, staff, patient)}
            </span>
          ),
          actions: [
            {
              title: 'Go Back',
              color: 'success',
              onActivate: viewControl => viewControl.rewind()
            },
            {
              title: 'Delete',
              color: 'danger',
              icon: Close,
              onActivate: viewControl => {
                viewControl.setPending(true);
                scheduleModel
                  .deleteAppointment(entity)
                  .then(() => {
                    viewControl.setPending(false);
                    viewControl.rewind();
                  })
                  .catch(error => {
                    viewControl.setPending(false);
                    viewControl.setError(error);
                    viewControl.rewind();
                  });
              }
            }
          ]
        });
      })
      .catch(error => {
        setPending(false);
        setError(error);
      });
  };

  const headerGroup = {
    id: 'staff-table',
    cols: [{ id: 'title', span: 1, head: staff ? staff.name : 'Loading...' }]
  };

  const availabilityCols = [
    { id: 'status', span: 1, head: 'Status', warn: 'Unavailable' },
    { id: 'time', span: 2, head: 'Time' },
    { id: 'actions', span: 1, head: 'Actions', actions: true }
  ];
  const availabilityGroups = availability
    ? getLocations(availability).map(locId => ({
        id: `availability-${locId}`,
        title: locations[locId]
          ? `Availability in ${locations[locId].val.LocationName}`
          : 'Availability',
        cols: availabilityCols,
        data: availability
          .filter(({ val }) => val.LocationID === locId)
          .map(entity => ({
            id: entity.key,
            entity,
            cells: {
              status: entity.val.Unavailable ? 'Unavailable' : 'Available',
              time: (
                <div>
                  <span>{`${util.hourToString12(
                    entity.val.TimeStart
                  )} - ${util.hourToString12(entity.val.TimeEnd)}`}</span>
                  {entity.val.Recurring ? (
                    <span>
                      <br />
                      {`Repeats: ${util.daySpan(entity.val.Days)}`}
                    </span>
                  ) : null}
                </div>
              ),
              actions: [
                {
                  icon: Delete,
                  title: 'Delete Availability',
                  active: true,
                  onClick: row => deleteAvailability(row)
                }
              ]
            },
            onClick: () =>
              viewControl.presentView({
                id: scheduleModel.availabilityViewId,
                mode: viewMode.READ,
                entity
              })
          }))
      }))
    : [
        {
          id: 'availability',
          title: 'Loading Availability...',
          cols: availabilityCols,
          data: []
        }
      ];

  // TODO sorting
  // TODO distinguish between 'Loading' and 'No data' (and possibly allow adding)
  const appointmentCols = [
    {
      id: 'status',
      span: 1,
      head: 'Status',
      alert: 'Unconfirmed',
      warn: 'Canceled'
    },
    { id: 'patient', span: 2, head: 'Patient' },
    { id: 'actions', span: 1, head: 'Actions', actions: true }
  ];
  const appointmentGroups = appointments
    ? getLocations(appointments).map(locId => ({
        id: `appointments-${locId}`,
        title: locations[locId]
          ? `Appointments in ${locations[locId].val.LocationName}`
          : 'Appointments',
        cols: appointmentCols,
        data: appointments
          .filter(({ val }) => val.LocationID === locId)
          .map(entity => ({
            id: entity.key,
            entity,
            cells: {
              status: entity.val.Canceled
                ? 'Canceled'
                : entity.val.Confirmed
                ? 'Confirmed'
                : 'Unconfirmed',
              patient: (
                <>
                  {entity.val.AtHome && (
                    <Home
                      size="small"
                      style={{
                        color: 'rgba(255,255,255,0.87)',
                        paddingRight: 3
                      }}
                    />
                  )}
                  {patients[entity.val.PatientID]
                    ? patients[entity.val.PatientID].val.name
                    : '...'}
                </>
              ),
              actions: [
                {
                  icon: CheckCircle,
                  title: 'Confirm Appointment',
                  omit: entity.val.canceled || entity.val.Confirmed,
                  active: true,
                  onClick: row => confirmAppointment(row)
                },
                {
                  icon: Cancel,
                  title: 'Cancel Appointment',
                  omit: entity.val.Canceled,
                  active: true,
                  onClick: row => cancelAppointment(row)
                },
                {
                  icon: Delete,
                  title: 'Delete Appointment',
                  active: true,
                  onClick: row => deleteAppointment(row)
                }
              ]
            },
            onClick: () =>
              viewControl.presentView({
                id: scheduleModel.appointmentViewId,
                mode: viewMode.READ,
                entity
              })
          }))
      }))
    : [
        {
          id: 'appointments',
          title: 'Loading Appointments...',
          cols: appointmentCols,
          data: []
        }
      ];

  const tableModel = {
    title: day.format('ddd MMM D, Y'),
    data: [headerGroup, ...availabilityGroups, ...appointmentGroups],
    barButtons: [
      {
        icon: DateRange,
        activate: ({ viewControl }) => viewControl.rewind()
      }
    ]
  };

  useEffect(() => viewControl.setActions(scheduleModel.actions(viewControl)), [
    pending
  ]);

  // console.log('render');
  return <FlexTable viewControl={viewControl} tableModel={tableModel} />;
};

StaffScheduleView.propTypes = {
  viewControl: PropTypes.object,
  adminMode: PropTypes.bool,
  scheduleModel: PropTypes.object.isRequired
};

export default StaffScheduleView;
