import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from 'react-google-maps';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// @material-ui/icons
import PinDrop from '@material-ui/icons/PinDrop';
import Phone from '@material-ui/icons/Phone';
// core components
import GridContainer from '../../mui-pro/components/Grid/GridContainer.jsx';
import GridItem from '../../mui-pro/components/Grid/GridItem.jsx';
import InfoArea from '../../mui-pro/components/InfoArea/InfoArea.jsx';
import CustomInput from '../../mui-pro/components/CustomInput/CustomInput.jsx';
import Button from '../../mui-pro/components/CustomButtons/Button';

import { FirebaseContext } from '../../firebase';
import { AppContext } from '../../appContext';
import HtmlBlock from '../HtmlBlock/HtmlBlock';
import contactStyle from './contactStyle';

const CustomSkinMap = withScriptjs(
  withGoogleMap(({ mapProps }) => (
    <GoogleMap {...mapProps}>
      {mapProps && mapProps.defaultCenter && (
        <Marker position={mapProps.defaultCenter} />
      )}
    </GoogleMap>
  ))
);

const Contact = ({
  classes,
  googleMapApiKey,
  googleMapConfig,
  locationInfo,
  contactInfo
}) => {
  const appControl = useContext(AppContext);
  const firebase = useContext(FirebaseContext);
  const handleSubmit = event => {
    event.preventDefault();
    firebase
      .submitContactMessage({
        name: event.currentTarget.elements.name.value,
        email: event.currentTarget.elements.email.value,
        phone: event.currentTarget.elements.phone.value,
        message: event.currentTarget.elements.message.value
      })
      .then(() => {
        appControl.displayInfoDialog({
          title: 'Message Sent',
          body: (
            <span>
              <p>
                Thank you for contacting us. If your message requires a
                response, we will respond as soon as possible.
              </p>
              <p>Thank you!</p>
            </span>
          )
        });
      })
      .catch(() => {
        appControl.displayAppMessage({
          message: 'Unable to send contact message',
          variant: 'danger'
        });
      });
  };
  return (
    <div className={classes.contactContent}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem md={6} sm={6}>
            <p>
              Please contact us with anything related to our services or
              products. We&apos;ll get in touch with you as soon as possible.
              <br />
            </p>
            <form onSubmit={handleSubmit}>
              <CustomInput
                labelText="Your Name"
                id="name"
                inputProps={{
                  name: 'name'
                }}
                formControlProps={{
                  fullWidth: true
                }}
              />
              <CustomInput
                labelText="Email address"
                id="email"
                inputProps={{
                  name: 'email'
                }}
                formControlProps={{
                  fullWidth: true
                }}
              />
              <CustomInput
                labelText="Phone"
                id="phone"
                inputProps={{
                  name: 'phone'
                }}
                formControlProps={{
                  fullWidth: true
                }}
              />
              <CustomInput
                labelText="Your message"
                id="message"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  multiline: true,
                  rows: 6,
                  name: 'message'
                }}
              />
              <div className={classes.textCenter}>
                <Button color="primary" round type="submit">
                  Contact us
                </Button>
              </div>
            </form>
          </GridItem>
          <GridItem md={4} sm={4} className={classes.mlAuto}>
            <div className={classes.smallMap}>
              <CustomSkinMap
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleMapApiKey}`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={
                  <div
                    style={{
                      height: `100%`,
                      borderRadius: '6px',
                      overflow: 'hidden'
                    }}
                  />
                }
                mapElement={<div style={{ height: `100%` }} />}
                mapProps={googleMapConfig}
              />
            </div>
            <InfoArea
              className={classes.info}
              title={locationInfo.heading}
              description={
                <HtmlBlock
                  html={locationInfo.html}
                  markdown={locationInfo.markdown}
                />
              }
              icon={PinDrop}
              iconColor="primary"
            />
            <InfoArea
              className={classes.info}
              title={contactInfo.heading}
              description={
                <HtmlBlock
                  html={contactInfo.html}
                  markdown={contactInfo.markdown}
                />
              }
              icon={Phone}
              iconColor="primary"
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
};

// eslint-disable-next-line react/display-name
Contact.createFromCms = cmsContext => {
  const {
    key,
    component: { googleMapApiKey, googleMapConfig, locationInfo, contactInfo }
  } = cmsContext;
  const props = {
    googleMapApiKey,
    googleMapConfig,
    locationInfo:
      (locationInfo && locationInfo.value && locationInfo.value[0]) ||
      undefined,
    contactInfo:
      (contactInfo && contactInfo.value && contactInfo.value[0]) || undefined
  };
  const ContactCms = withStyles(contactStyle)(Contact);
  return <ContactCms {...props} key={key} />;
};

Contact.propTypes = {
  classes: PropTypes.object.isRequired,
  googleMapApiKey: PropTypes.string,
  googleMapConfig: PropTypes.object,
  locationInfo: PropTypes.object,
  contactInfo: PropTypes.object
};

export default withStyles(contactStyle)(Contact);
