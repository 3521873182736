import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';

const HtmlBlock = ({ html, markdown, injectClasses, injectStyles }) => {
  const classes = injectClasses ? { className: classNames(injectClasses) } : {};
  const styles = { style: { fontWeight: 400, ...injectStyles } };
  return html && html.length > 0 ? (
    <div
      {...classes}
      dangerouslySetInnerHTML={{
        __html: html
      }}
      {...styles}
    />
  ) : (
    markdown && markdown.length > 0 && (
      <div {...classes} {...styles}>
        <ReactMarkdown source={markdown} />
      </div>
    )
  );
};

// eslint-disable-next-line react/display-name
HtmlBlock.createFromCms = cmsContext => {
  const {
    key,
    styles,
    localStyles,
    component: { html }
  } = cmsContext;
  const contentValue = (html && html.value && html.value[0]) || {};
  const props = {
    html: contentValue.html,
    markdown: contentValue.markdown,
    injectClasses: styles,
    injectStyles: localStyles
  };
  return <HtmlBlock {...props} key={key} />;
};

HtmlBlock.propTypes = {
  html: PropTypes.string,
  markdown: PropTypes.string,
  injectClasses: PropTypes.array,
  injectStyles: PropTypes.object
};

export default HtmlBlock;
