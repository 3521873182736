import React from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import { withStyles } from '@material-ui/core/styles';

import CustomInput from '../../mui-pro/components/CustomInput/CustomInput';

const styles = {};

const DateTimePicker = ({
  inputProps: { onBlur, onFocus, value, onChange, ...restInputProps },
  formControlProps,
  labelText,
  error,
  disabled,
  ...restProps
}) =>
  disabled ? (
    <CustomInput
      inputProps={{
        value: value.format('MMMM D, YYYY'),
        disabled,
        ...restInputProps,
        inputProps: { style: { padding: '12px 0 7px' } }
      }}
      error={error}
      labelText={<div style={{ color: '#3C4858' }}>{labelText}</div>}
      formControlProps={{
        ...formControlProps,
        style: { top: -6, height: 52, paddingTop: 20 }
      }}
      labelProps={{
        style: {
          textTransform: 'uppercase',
          fontWeight: '400',
          fontSize: '12px'
        }
      }}
    />
  ) : (
    <Datetime
      onFocus={onFocus}
      onBlur={onBlur}
      value={value}
      onChange={onChange}
      closeOnSelect
      timeFormat={false}
      dateFormat="MMMM D, YYYY"
      renderInput={(dateInputProps, open, close) => (
        <CustomInput
          inputProps={{
            disabled,
            ...dateInputProps,
            ...restInputProps,
            inputProps: { style: { padding: '12px 0 7px' } }
          }}
          error={error}
          onBlur={close}
          onFocus={() => {
            return disabled ? open() : null;
          }}
          labelText={<div style={{ color: '#3C4858' }}>{labelText}</div>}
          formControlProps={{
            ...formControlProps,
            style: { top: -6, height: 52, paddingTop: 20 }
          }}
          labelProps={{
            style: {
              textTransform: 'uppercase',
              fontWeight: '400',
              fontSize: '12px'
            }
          }}
        />
      )}
      {...restProps}
    />
  );

DateTimePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  labelText: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool
};

export default withStyles(styles)(DateTimePicker);
