import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';

import ActionButton from '../ActionButton/ActionButton';
import ContentSection from '../ContentSection/ContentSection';
import FlexList from '../FlexList/FlexList';
import HtmlBlock from '../HtmlBlock/HtmlBlock';
import HtmlCard from '../HtmlCard/HtmlCard';
import ImageBlock from '../ImageBlock/ImageBlock';
import Parallax from '../Parallax/Parallax';
import ProductCard from '../ProductCard/ProductCard';
import StaffCard from '../StaffCard/StaffCard';
import SubscribeForm from '../SubscribeForm/SubscribeForm';
import TextBlock from '../TextBlock/TextBlock';
import Contact from '../Contact/Contact';

//let cmsClasses;
let cmsStaff;
let cmsProducts;

const cmsComponents = {
  DatoCmsActionButton: ActionButton,
  DatoCmsContentSection: ContentSection,
  DatoCmsFlexList: FlexList,
  DatoCmsHtmlBlock: HtmlBlock,
  DatoCmsHtmlCard: HtmlCard,
  DatoCmsImageBlock: ImageBlock,
  DatoCmsMailchimpSubscribe: SubscribeForm,
  DatoCmsPage: { createFromCms: ({ children }) => children },
  DatoCmsParallax: Parallax,
  DatoCmsProductCard: ProductCard,
  DatoCmsStaffCard: StaffCard,
  DatoCmsTextBlock: TextBlock,
  DatoCmsContactPage: Contact
};

const LandingPage = ({ classes }) => (
  <StaticQuery
    query={graphql`
      fragment CmsImage on DatoCmsAsset {
        width
        height
        url
        alt
      }
      fragment CmsContentValue on DatoCmsSiteContent {
        value {
          __typename
          ... on DatoCmsStringValue {
            string: value
          }
          ... on DatoCmsImageValue {
            image {
              ...CmsImage
            }
          }
          ... on DatoCmsMarkdownValue {
            markdown: content
            heading
          }
          ... on DatoCmsHtmlValue {
            html: content
            heading
          }
        }
      }
      fragment CmsPageRoot on DatoCmsUnionForDatoCmsSiteLayoutComponent {
        ... on DatoCmsPage {
          pageTitle {
            ...CmsContentValue
          }
        }
      }
      fragment CmsContentSection on DatoCmsUnionForDatoCmsSiteLayoutComponent {
        ... on DatoCmsContentSection {
          backgroundImage {
            ...CmsContentValue
          }
          backgroundImageClasses {
            styleKey
          }
          backgroundImageStyles
          transparent
          expandVertical
        }
      }
      fragment CmsParallax on DatoCmsUnionForDatoCmsSiteLayoutComponent {
        ... on DatoCmsParallax {
          id
        }
      }
      fragment CmsFlexList on DatoCmsUnionForDatoCmsSiteLayoutComponent {
        ... on DatoCmsFlexList {
          direction
          wrap
          justify
          align
        }
      }
      fragment CmsTextBlock on DatoCmsUnionForDatoCmsSiteLayoutComponent {
        ... on DatoCmsTextBlock {
          text {
            ...CmsContentValue
          }
          element
        }
      }
      fragment CmsHtmlBlock on DatoCmsUnionForDatoCmsSiteLayoutComponent {
        ... on DatoCmsHtmlBlock {
          html {
            ...CmsContentValue
          }
        }
      }
      fragment CmsImageBlock on DatoCmsUnionForDatoCmsSiteLayoutComponent {
        ... on DatoCmsImageBlock {
          image {
            ...CmsContentValue
          }
        }
      }
      fragment CmsActionButton on DatoCmsUnionForDatoCmsSiteLayoutComponent {
        ... on DatoCmsActionButton {
          buttonText
          appAction
        }
      }
      fragment CmsStaffCard on DatoCmsUnionForDatoCmsSiteLayoutComponent {
        ... on DatoCmsStaffCard {
          showFeatured
        }
      }
      fragment CmsMailchimpSubscribe on DatoCmsUnionForDatoCmsSiteLayoutComponent {
        ... on DatoCmsMailchimpSubscribe {
          id
        }
      }
      fragment CmsHtmlCard on DatoCmsUnionForDatoCmsSiteLayoutComponent {
        ... on DatoCmsHtmlCard {
          headingStyle
          content {
            ...CmsContentValue
          }
          color
        }
      }
      fragment CmsProductCard on DatoCmsUnionForDatoCmsSiteLayoutComponent {
        ... on DatoCmsProductCard {
          color
        }
      }
      fragment CmsContactPage on DatoCmsUnionForDatoCmsSiteLayoutComponent {
        ... on DatoCmsContactPage {
          googleMapApiKey
          googleMapConfig
          locationInfo {
            ...CmsContentValue
          }
          contactInfo {
            ...CmsContentValue
          }
        }
      }
      fragment CmsComponent on DatoCmsUnionForDatoCmsSiteLayoutComponent {
        __typename
        ...CmsPageRoot
        ...CmsContentSection
        ...CmsParallax
        ...CmsFlexList
        ...CmsTextBlock
        ...CmsHtmlBlock
        ...CmsImageBlock
        ...CmsMailchimpSubscribe
        ...CmsActionButton
        ...CmsStaffCard
        ...CmsHtmlCard
        ...CmsProductCard
        ...CmsContactPage
      }
      fragment CmsSection on DatoCmsSiteLayout {
        key: originalId
        sectionKey
        enabled
        styles {
          styleKey
        }
        localStyles
        component {
          ...CmsComponent
        }
      }
      fragment CmsPage on DatoCmsSiteLayout {
        ...CmsSection
        children: treeChildren {
          ...CmsSection
          children: treeChildren {
            ...CmsSection
            children: treeChildren {
              ...CmsSection
              children: treeChildren {
                ...CmsSection
                children: treeChildren {
                  ...CmsSection
                  children: treeChildren {
                    ...CmsSection
                    children: treeChildren {
                      ...CmsSection
                      children: treeChildren {
                        ...CmsSection
                        children: treeChildren {
                          ...CmsSection
                          children: treeChildren {
                            ...CmsSection
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      query {
        site: datoCmsSiteConfig {
          landingPage {
            ...CmsPage
          }
        }
        staff: allDatoCmsStaff(sort: { fields: [position], order: ASC }) {
          edges {
            node {
              name
              avatar {
                ...CmsImage
              }
              biography
              featuredProfile
              staffid
              subtitle
              title
            }
          }
        }
        products: allDatoCmsProduct(sort: { fields: [position], order: ASC }) {
          edges {
            node {
              image {
                ...CmsImage
              }
              productId
              name
            }
          }
        }
      }
    `}
    render={data => {
      // sanity checks on data
      const checkComponent = section => {
        if (
          section.component &&
          section.component[0] &&
          section.component[0].__typename
        ) {
          if (
            section.children &&
            section.children.findIndex(child => !checkComponent(child)) !== -1
          ) {
            // eslint-disable-next-line no-console
            console.error(`Found invalid child in ${section.sectionKey}`);
            return false;
          }
        } else {
          // eslint-disable-next-line no-console
          console.error(`Section '${section.sectionKey}' has no component.`);
          return false;
        }
        return true;
      };
      // console.log('Sanity check...', { data });
      checkComponent(data.site.landingPage);
      // if (!cmsClasses) {
      //   const rawStyles = {};
      //   data.styles.edges.forEach(({ node: { styleKey, style } }) => {
      //     rawStyles[styleKey] = style;
      //   });
      //   cmsClasses = makeStyles(rawStyles)();
      // }
      const cmsClasses = classes;
      if (!cmsStaff) {
        cmsStaff = data.staff.edges.map(({ node }) => ({ ...node }));
      }
      if (!cmsProducts) {
        cmsProducts = data.products.edges.map(({ node }) => ({ ...node }));
      }

      const renderSection = section => {
        const {
          sectionKey,
          enabled,
          styles,
          component: [{ __typename, ...component }],
          children,
          ...cmsProps
        } = section;
        if (!enabled) {
          return null;
        }
        const cmsContext = {
          sectionKey,
          cmsClasses,
          data: {
            staff: cmsStaff,
            products: cmsProducts
          },
          styles: styles.map(({ styleKey }) => {
            if (!cmsClasses[styleKey]) {
              // eslint-disable-next-line no-console
              console.error(`Missing style ${styleKey}`);
            }
            return cmsClasses[styleKey];
          }),
          children: children
            .filter(({ enabled }) => enabled)
            .map(section => renderSection(section)),
          component,
          ...cmsProps
        };
        return cmsComponents[__typename].createFromCms(cmsContext);
      };
      return renderSection(data.site.landingPage);
    }}
  />
);

LandingPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default LandingPage;
