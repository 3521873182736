const subscribeFormStyle = {
  card: {
    width: '80%',
    maxWidth: 500
  },
  cardBody: {
    padding: '15px',
    '& form': {
      marginBottom: '0'
    }
  },
  cardForm: {
    margin: '0 0 0 14px',
    padding: 0,
    top: 10
  },
  subscribeButton: {}
};

export default subscribeFormStyle;
