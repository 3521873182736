import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import Button from '../../mui-pro/components/CustomButtons/Button';

import styles from './modalConfirmStyle';

const ModalConfirm = ({ classes, viewControl, confirm }) => {
  const { pending } = viewControl;

  const createActions = actions =>
    actions.map((action, index) => (
      <Button
        size="sm"
        color={action.color}
        key={index}
        disabled={pending || action.isActive === false}
        onClick={() => action.onActivate(viewControl)}
      >
        {action.iocn && <action.icon />}
        {action.title}
      </Button>
    ));

  const actions = confirm.actions
    ? createActions(confirm.actions)
    : [
        <Button
          variant="contained"
          color="primary"
          size="sm"
          onClick={viewControl.rewind}
          disabled={pending}
          key="1"
        >
          OK
        </Button>
      ];

  useEffect(() => viewControl.setActions(actions), []);

  // console.log('render');
  return (
    <>
      <h4 className={`${classes.description} ${classes.textCenter}`}>
        {confirm.title}
      </h4>

      <h4 className={`${classes.description}`}>{confirm.body}</h4>
    </>
  );
};

ModalConfirm.propTypes = {
  classes: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  viewControl: PropTypes.object
};

export default withStyles(styles)(ModalConfirm);
