import {
  description,
  grayColor,
  infoColor,
  successColor,
  dangerColor,
  roseColor
} from '../../mui-pro/assets/jss/material-kit-pro-react.jsx';

export const toolbarColor = 'rgba(255,255,255,1)';
const dayViewRowHeight = 45;
const dayViewHeaderHeight = 30;

const selectable = {
  cursor: 'pointer',
  pointerEvents: 'auto',
  '& *': { pointerEvents: 'none', cursor: 'pointer' }
};

const calendarStyle = {
  selectable,
  calendar: {
    backgroundColor: grayColor[1],
    width: '100%',
    borderRadius: 3,
    overflow: 'hidden',
    marginBottom: 20
  },
  titleBar: {
    backgroundColor: infoColor[0],
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    //marginBottom: 5,
    color: toolbarColor
  },
  controlGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  title: {
    ...description,
    display: 'inline',
    padding: 0,
    margin: 0,
    color: 'inherit',
    textAlign: 'center'
  },
  headerRow: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
  headerCell: {
    flex: '1',
    textAlign: 'center'
  },
  dayHeading: {
    fontSize: 12,
    color: 'rgba(255,255,255,0.75)',
    fontWeight: 600
  },
  week: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  calendarBody: {
    margin: '0 2px 2px 2px'
  },
  cellWrapper: {
    flex: 1
  },
  cellContainer: {
    width: '100%',
    paddingTop: '100%',
    position: 'relative'
  },
  cellContents: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 2,
    borderRadius: 3,
    overflow: 'hidden',
    boxSizing: 'border-box',
    padding: 3,
    display: 'flex',
    flexDirection: 'column'
  },
  cellNormal: {
    backgroundColor: infoColor[0]
  },
  cellSelected: {
    //backgroundColor: primaryColor[0]
    backgroundColor: infoColor[0],
    border: '2px solid white'
  },
  cellSelectable: {
    ...selectable,
    '&:hover': {
      backgroundColor: roseColor[0]
    }
  },
  day: {
    fontSize: 14,
    position: 'relative',
    top: -3,
    color: 'rgba(255,255,255,1)',
    fontWeight: 400,
    lineHeight: '18px'
  },
  '@media (min-width: 450px)': {
    day: {
      lineHeight: 'unset'
    }
  },
  dayPast: {
    color: 'rgba(255, 255, 255, 0.5)'
  },
  daySelected: {
    fontWeight: 700
  },
  actionIcon: {
    fontSize: 20
  },
  colorInactive: {
    display: 'none'
  },
  colorActive: {
    color: 'rgba(255,255,255,0.87)'
  },
  colorSuccess: {
    color: successColor[0]
  },
  colorDanger: {
    color: 'rgba(255,255,255,0.87)',
    backgroundColor: dangerColor[0],
    borderRadius: 5
  },
  cellIcons: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  // day view styles
  dayViewContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row'
  },
  dayViewTimeCol: {
    width: 40,
    backgroundColor: infoColor[0],
    display: 'flex',
    flexDirection: 'column'
  },
  dayViewScheduleCol: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  dayViewCorner: {
    height: dayViewHeaderHeight,
    color: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  timeCell: {
    height: dayViewRowHeight,
    fontSize: 12,
    color: 'rgba(255,255,255,0.87)',
    fontWeight: 600,
    textAlign: 'center'
  },
  dayViewScheduleHead: { width: '100%', display: 'flex', flexDirection: 'row' },
  dayViewGroupHead: {
    height: dayViewHeaderHeight,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  dayViewGroupTitle: {
    fontSize: 12,
    fontWeight: 600,
    color: 'rgba(255,255,255,0.87)',
    lineHeight: '14px'
  },
  dayViewCellBorder: {
    borderRight: '1px solid rgba(255,255,255,0.2)',
    borderBottom: '1px solid rgba(255,255,255,0.2)'
  },
  dayViewBody: {
    width: '100%',
    position: 'relative'
  },
  dayViewBg: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: dayViewHeaderHeight
  },
  dayViewBgRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row'
  },
  dayViewBgCell: {
    height: dayViewRowHeight
  },
  dayViewContentWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    padding: 2
  },
  dayViewRowContainer: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    top: -2
  },
  dayViewRow: {
    position: 'absolute',
    left: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    pointerEvents: 'none'
  },
  emptyCell: {
    flex: 1
  },
  dayViewCellWrapper: { paddingTop: 2, paddingBottom: 2 },
  dayViewCell: {
    //width: '100%',
    height: '100%',
    borderRadius: 8,
    overflow: 'hidden',
    boxSizing: 'border-box',
    //margin: 2
    marginRight: 2,
    marginLeft: 2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12,
    fontWeight: 600,
    color: 'rgba(255, 255, 255, 0.87)',
    lineHeight: '14px',
    textAlign: 'center'
  },
  dayViewColor0: {
    backgroundColor: infoColor[1]
  },
  dayViewColor1: {
    backgroundColor: successColor[1]
  },
  dayViewColor2: {
    backgroundColor: roseColor[1]
  },
  dayViewColor0Selectable: {
    ...selectable,
    '&:hover': {
      backgroundColor: infoColor[0]
    }
  },
  dayViewColor1Selectable: {
    ...selectable,
    '&:hover': {
      backgroundColor: successColor[0]
    }
  },
  dayViewColor2Selectable: {
    ...selectable,
    '&:hover': {
      backgroundColor: roseColor[0]
    }
  },
  dayViewAlert: {
    border: '2px solid white'
  },
  dayViewCellHeight1: {
    height: dayViewRowHeight
  },
  dayViewCellHeight2: {
    height: dayViewRowHeight * 2
  },
  dayViewCellHeight3: {
    height: dayViewRowHeight * 3
  },
  dayViewCellHeight4: {
    height: dayViewRowHeight * 4
  },
  dayViewCellHeight5: {
    height: dayViewRowHeight * 5
  },
  dayViewCellHeight6: {
    height: dayViewRowHeight * 6
  },
  dayViewCellHeight7: {
    height: dayViewRowHeight * 7
  },
  dayViewCellHeight8: {
    height: dayViewRowHeight * 8
  },
  dayViewCellHeight9: {
    height: dayViewRowHeight * 9
  },
  dayViewCellHeight10: {
    height: dayViewRowHeight * 10
  },
  dayViewCellHeight11: {
    height: dayViewRowHeight * 11
  },
  dayViewCellHeight12: {
    height: dayViewRowHeight * 12
  },
  dayViewCellHeight13: {
    height: dayViewRowHeight * 13
  },
  dayViewCellHeight14: {
    height: dayViewRowHeight * 14
  },
  dayViewCellHeight15: {
    height: dayViewRowHeight * 15
  },
  dayViewCellHeight16: {
    height: dayViewRowHeight * 16
  },
  dayViewCellHeight17: {
    height: dayViewRowHeight * 17
  },
  dayViewCellHeight18: {
    height: dayViewRowHeight * 18
  },
  dayViewCellHeight19: {
    height: dayViewRowHeight * 19
  },
  dayViewCellHeight20: {
    height: dayViewRowHeight * 20
  },
  dayViewCellHeight21: {
    height: dayViewRowHeight * 21
  },
  dayViewCellHeight22: {
    height: dayViewRowHeight * 22
  },
  dayViewCellHeight23: {
    height: dayViewRowHeight * 23
  },
  dayViewCellHeight24: {
    height: dayViewRowHeight * 24
  },
  dayViewCellTop0: {
    top: 0
  },
  dayViewCellTop1: {
    top: dayViewRowHeight * 1
  },
  dayViewCellTop2: {
    top: dayViewRowHeight * 2
  },
  dayViewCellTop3: {
    top: dayViewRowHeight * 3
  },
  dayViewCellTop4: {
    top: dayViewRowHeight * 4
  },
  dayViewCellTop5: {
    top: dayViewRowHeight * 5
  },
  dayViewCellTop6: {
    top: dayViewRowHeight * 6
  },
  dayViewCellTop7: {
    top: dayViewRowHeight * 7
  },
  dayViewCellTop8: {
    top: dayViewRowHeight * 8
  },
  dayViewCellTop9: {
    top: dayViewRowHeight * 9
  },
  dayViewCellTop10: {
    top: dayViewRowHeight * 10
  },
  dayViewCellTop11: {
    top: dayViewRowHeight * 11
  },
  dayViewCellTop12: {
    top: dayViewRowHeight * 12
  },
  dayViewCellTop13: {
    top: dayViewRowHeight * 13
  },
  dayViewCellTop14: {
    top: dayViewRowHeight * 14
  },
  dayViewCellTop15: {
    top: dayViewRowHeight * 15
  },
  dayViewCellTop16: {
    top: dayViewRowHeight * 16
  },
  dayViewCellTop17: {
    top: dayViewRowHeight * 17
  },
  dayViewCellTop18: {
    top: dayViewRowHeight * 18
  },
  dayViewCellTop19: {
    top: dayViewRowHeight * 19
  },
  dayViewCellTop20: {
    top: dayViewRowHeight * 20
  },
  dayViewCellTop21: {
    top: dayViewRowHeight * 21
  },
  dayViewCellTop22: {
    top: dayViewRowHeight * 22
  },
  dayViewCellTop23: {
    top: dayViewRowHeight * 23
  }
};

export default calendarStyle;
