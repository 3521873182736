import React from 'react';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// material-ui icons
import SkipNext from '@material-ui/icons/SkipNext';
import SkipPrev from '@material-ui/icons/SkipPrevious';
import FilterList from '@material-ui/icons/FilterList';
// core components
import Button from '../../mui-pro/components/CustomButtons/Button.jsx';

import style, { toolbarColor } from './flextableStyle';

const TitleBar = ({ classes, tableControl }) => {
  const {
    viewControl: { pending, editDetail },
    tableModel: { title, next, prev, filterViewId, barButtons, filterData },
    resolve
  } = tableControl;

  // console.log('render');
  return (
    <div className={classes.titlebar}>
      {(next || prev) && (
        <Button
          justIcon
          round
          color="transparent"
          disabled={!prev || pending}
          onClick={prev}
          size="sm"
        >
          <SkipPrev style={{ color: toolbarColor }} />
        </Button>
      )}
      <div className={classes.controlGroup}>
        <h4 className={classes.title}>{resolve(title)}</h4>
      </div>
      {(filterViewId || barButtons) && (
        <div>
          {filterViewId && (
            <Button
              justIcon
              round
              color="transparent"
              disabled={pending}
              onClick={() =>
                editDetail(resolve(filterData), resolve(filterViewId))
              }
              size="sm"
            >
              <FilterList style={{ color: 'white' }} />
            </Button>
          )}
          {barButtons &&
            barButtons.map(
              (ctl, i) =>
                !ctl.omit && (
                  <Button
                    justIcon
                    round
                    color="transparent"
                    disabled={pending && !ctl.disabled}
                    onClick={() => resolve(ctl.activate)}
                    size="sm"
                    key={i}
                  >
                    <ctl.icon style={{ color: 'white' }} />
                  </Button>
                )
            )}
        </div>
      )}
      {(next || prev) && (
        <Button
          justIcon
          round
          color="transparent"
          disabled={!next || pending}
          onClick={next}
          size="sm"
        >
          <SkipNext style={{ color: 'white' }} />
        </Button>
      )}
    </div>
  );
};

TitleBar.propTypes = {
  classes: PropTypes.object.isRequired,
  tableControl: PropTypes.object.isRequired
};

export default withStyles(style)(TitleBar);
