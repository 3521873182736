import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';

import Button from '../../mui-pro/components/CustomButtons/Button.jsx';
import Footer from '../../mui-pro/components/Footer/Footer.jsx';

import styles from './footerStyle';

const SectionFooter = ({ classes }) => (
  <Footer
    theme="dark"
    content={
      <div>
        <ul className={classes.socialButtons}>
          <li>
            <Button
              justIcon
              simple
              href="https://www.facebook.com/BaduriaBiokineticsAndPhysicalTherapyClinic/"
              color="facebook"
            >
              <i className="fab fa-facebook-square" />
            </Button>
          </li>
        </ul>
        <div className={classNames(classes.pullCenter, classes.copyRight)}>
          Copyright &copy; {1900 + new Date().getYear()}{' '}
          <a href="https://baduriabiokinetics.com">Baduria Biokinetics</a> All
          Rights Reserved.
        </div>
      </div>
    }
  >
    <div className={classes.footer} />
  </Footer>
);

SectionFooter.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SectionFooter);
