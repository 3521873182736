import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import style from './flextableStyle';

const GroupHead = ({ classes, tableControl, group }) => {
  const { resolve } = tableControl;
  const { title, cols } = group;

  // console.log('render');
  return (
    <>
      {title && <div className={classes.groupheadTitle}>{resolve(title)}</div>}
      <div className={classes.tablerow}>
        {cols.map(({ id, span, head }) => (
          <div
            className={classes.tablecellWrapper}
            key={resolve(id)}
            style={{ flex: resolve(span) }}
          >
            <div className={classes.columnHead}>{resolve(head)}</div>
          </div>
        ))}
      </div>
    </>
  );
};

GroupHead.propTypes = {
  classes: PropTypes.object.isRequired,
  tableControl: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired
};

export default withStyles(style)(GroupHead);
