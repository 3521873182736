import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';

import TableCell from './TableCell';

import style from './flextableStyle';

const TableRow = ({ classes, tableControl, group, row }) => {
  const {
    resolve,
    viewControl: { pending }
  } = tableControl;
  const { cols } = group;
  const { cells, id, onClick } = row;

  // console.log('render');
  return (
    <div
      className={classNames({
        [classes.tablerow]: true,
        [classes.tablerowSelectable]: onClick && !pending
      })}
      onClick={event => {
        event.stopPropagation();
        !pending && onClick && onClick(row);
      }}
    >
      {cols.map(col => (
        <TableCell
          tableControl={tableControl}
          row={row}
          col={col}
          cell={resolve(cells)[col.id]}
          key={`${id}.${col.id}`}
        />
      ))}
    </div>
  );
};

TableRow.propTypes = {
  classes: PropTypes.object.isRequired,
  tableControl: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired
};

export default withStyles(style)(TableRow);
