import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Close from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';

import Danger from '../../mui-pro/components/Typography/Danger';
import Button from '../../mui-pro/components/CustomButtons/Button';
import Card from '../../mui-pro/components/Card/Card';
import CardHeader from '../../mui-pro/components/Card/CardHeader';
import CardBody from '../../mui-pro/components/Card/CardBody';

import { viewMode, stdView } from '../viewControl';
import ModalConfirm from '../ModalConfirm/ModalConfirm';

import styles from './modalDialogStyles';

// eslint-disable-next-line react/display-name
const Transition = forwardRef((props, ref) => (
  <Slide direction="down" {...props} />
));

// Todo: this was moved outside of the function to avoid
// having it bound as stale state
// but this now means we can only have one instance of
// ModalDialog open - which is OK for now but a better
// yet simple solution is to key state on an id...
let viewEvents = {};
let pendingCount = 0;

const modalDialog = ({
  classes,
  open,
  onClose,
  onEnter,
  title,
  initialView,
  wide,
  wider,
  children,
  customClasses,
  variableHeight,
  noErrorDisplay
}) => {
  const myChildren = {};
  (Array.isArray(children) ? children : [children]).forEach(child => {
    if (child) {
      myChildren[child.props.id] = child;
    }
  });
  const [viewStack, setViewStack] = useState([initialView]);
  const currentView = viewStack[viewStack.length - 1];
  const [viewActions, setViewActions] = useState(null);
  const [confirm, setConfirm] = useState({});
  myChildren.confirm = <ModalConfirm confirm={confirm} id="confirm" />;

  //useEffect(() => () => (pendingCount = 0), []);

  const [viewStateMap, setViewStateMap] = useState({});
  const viewState = viewStateMap[currentView.id];
  const [error, setError] = useState(false);
  const [pending, setPending] = useState(false);

  const canViewClose = () =>
    !pending && (!viewEvents.canClose || viewEvents.canClose());

  const viewWillClose = () => viewEvents.willClose && viewEvents.willClose();

  const handleClose = () => {
    if (canViewClose()) {
      viewWillClose();
      setTimeout(onClose);
    }
  };

  const pushView = (view, returnParms) => {
    if (canViewClose()) {
      viewWillClose();
      setTimeout(() => {
        if (returnParms && viewStack.length > 0) {
          const prev = viewStack.pop();
          viewStack.push({ ...prev, ...returnParms });
        }
        setViewStack([...viewStack, view]);
      });
    }
  };

  const popView = (all = false) => {
    if (canViewClose()) {
      const len = viewStack.length;
      if (all || len < 2) {
        handleClose();
      } else {
        viewWillClose();
        setTimeout(() => setViewStack(viewStack.slice(0, len - 1)));
      }
    }
  };

  const replaceView = view => {
    if (canViewClose()) {
      const len = viewStack.length;
      if (len > 0) {
        viewWillClose();
        const newStack = viewStack.slice(0, len - 1);
        newStack.push(view);
        setTimeout(() => setViewStack(newStack));
      }
    }
  };

  const handleEnter = () => {
    if (onEnter) {
      setTimeout(onEnter);
    }
  };

  let viewComponent = null;
  if (open) {
    const id = currentView.id;
    const viewControl = {
      view: currentView,
      initState: viewState,
      setPending: isPending => {
        const prev = pendingCount === 0;
        pendingCount += isPending ? 1 : -1;
        if (pendingCount < 0) {
          // eslint-disable-next-line no-console
          console.error('Invalid pending count');
          pendingCount = 0;
        }
        const next = pendingCount === 0;
        if (prev !== next) {
          setPending(pendingCount !== 0);
        }
      },
      pending,
      setError: error =>
        setError(error && error.message ? error.message : error),
      saveState: newState =>
        setViewStateMap({ ...viewStateMap, [id]: newState }),
      presentView: pushView,
      rewind: () => popView(),
      rewindAll: () => popView(true),
      handleEvents: events => (viewEvents = events),
      replaceView: replaceView,
      setActions: setViewActions,
      confirmDialog: (conf, push = false, id = stdView.CONFIRM) => {
        setConfirm(conf);
        if (push) {
          pushView({ id });
        } else {
          replaceView({ id });
        }
      },
      actionDialog: (dlg, id = stdView.CONFIRM) => {
        setConfirm(dlg);
        pushView({ id });
      },
      editDetail: (entity, id = stdView.DETAIL, parms = []) => {
        pushView({
          id,
          mode: viewMode.UPDATE,
          entity,
          ...parms
        });
      },
      createNew: (id = stdView.DETAIL, parms = []) =>
        pushView({
          id,
          mode: viewMode.CREATE,
          ...parms
        })
    };
    viewComponent = React.cloneElement(myChildren[id], { viewControl });
  }

  const modalRootClasses = classNames({
    [classes.modalRoot]: true
  });
  const classDef = {
    [classes.modalRegister]: true,
    [classes.modalCustom]: true,
    [classes.variableHeight]: variableHeight,
    [classes.wide]: wide && !wider,
    [classes.wider]: wider
  };
  if (customClasses) {
    customClasses.forEach(classname => {
      classDef[classname] = true;
    });
  }
  const modalClasses = classNames(classDef);
  const contentClasses = classNames({
    [classes.modalBody]: true,
    [classes.modalBodyAdjust]: true,
    [classes.variableHeight]: variableHeight
  });

  // console.log('render');
  return (
    <Dialog
      classes={{
        root: modalRootClasses,
        paper: modalClasses
      }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onEnter={handleEnter}
      onClose={handleClose}
      closeAfterTransition
      aria-labelledby="login-modal-slide-title"
      aria-describedby="login-modal-slide-description"
    >
      <Card plain className={`${classes.modalLoginCard} ${classes.cardAdjust}`}>
        <DialogTitle
          id="login-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <CardHeader
            plain
            color="primary"
            className={`${classes.textCenter} ${classes.cardLoginHeader}`}
          >
            <Button
              simple
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              onClick={handleClose}
              disabled={!canViewClose()}
              style={{
                padding: 'unset',
                marginRight: -7,
                marginTop: -3
              }}
            >
              <Close className={classes.modalClose} />
            </Button>
            <h5 className={classes.cardTitleWhite}>{title}</h5>
          </CardHeader>
        </DialogTitle>
        <DialogContent
          id="login-modal-slide-description"
          className={contentClasses}
        >
          <CardBody
            className={`${classes.cardLoginBody} ${classes.cardBodyAdjust}`}
          >
            {viewComponent}
          </CardBody>
        </DialogContent>
        <DialogActions
          className={`${classes.modalFooter} ${classes.justifyContentCenter} ${classes.footerBorder}`}
        >
          <div className={classes.wrapper}>
            {(pending || (error && !noErrorDisplay)) &&
              (pending ? (
                <div className={classes.progressWrapper}>
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                </div>
              ) : (
                <div className={classes.error}>
                  <Danger>{error}</Danger>
                </div>
              ))}
            {viewActions}
          </div>
        </DialogActions>
      </Card>
    </Dialog>
  );
};

modalDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  initialView: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onEnter: PropTypes.func,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  wide: PropTypes.bool,
  wider: PropTypes.bool,
  customClasses: PropTypes.array,
  variableHeight: PropTypes.bool,
  noErrorDisplay: PropTypes.bool
};

export default withStyles(styles)(modalDialog);
