/* eslint-disable react/display-name */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '../../mui-pro/components/CustomButtons/Button';
import CustomInput from '../../mui-pro/components/CustomInput/CustomInput';
import Checkbox from '../Checkbox/Checkbox';
import * as moment from 'moment';
import Close from '@material-ui/icons/Close';
import Build from '@material-ui/icons/Build';
import ContactPhone from '@material-ui/icons/ContactPhone';
import DateRange from '@material-ui/icons/DateRange';

import { viewMode, stdView, viewModes } from '../viewControl';
import DataTable from '../DataTable/DataTable';
import ModalDialog from '../ModalDialog/ModalDialog';
import EmbeddedForm from '../EmbeddedForm/EmbeddedForm';
import { AppContext } from '../../appContext';
import { FirebaseContext, AuthContext } from '../../firebase';

import styles from './usersModalStyles';

const NAME = 'name';
const ADDRESS = 'address';
const CITY = 'city';
const PROVINCE = 'province';
const PHONENUMBER = 'phoneNumber';
const EMAILADDRESS = 'emailAddress';
const EMERGENCYNAME = 'emergencyName';
const EMERGENCYPHONE = 'emergencyPhone';
const PASSWORD = 'password';
const VERIFYPASSWORD = 'verifyPassword';
const ISTHERAPIST = 'isTherapist';
const ISADMIN = 'isAdmin';
const NOTIFYEMAIL = 'notifyEmail';
const NOTIFYSMS = 'notifySMS';
const CHANGEPASSWORD = 'changePassword';

const PROFILE_VIEW = 'profile';
const REGISTER_VIEW = 'register';

const UsersModal = ({
  classes,
  open,
  onClose,
  postRegister,
  mode,
  entity,
  title,
  role
}) => {
  const appControl = useContext(AppContext);
  const firebase = useContext(FirebaseContext);
  const authControl = useContext(AuthContext);
  const loggedIn = authControl.loggedIn;
  role = role || authControl.ROLE_USER;

  const initialView = {
    id:
      mode === viewMode.CREATE
        ? loggedIn
          ? PROFILE_VIEW
          : REGISTER_VIEW
        : mode === viewMode.UPDATE
        ? PROFILE_VIEW
        : stdView.MASTER,
    mode,
    entity
  };

  const getValue = (fieldState, id, defaultValue = '') => {
    const value = fieldState(id).value;
    return value === undefined || value === null ? defaultValue : value;
  };

  const registerUser = ({ fieldState, formData }) => {
    const provider = formData;
    return (provider
      ? provider()
      : firebase.registerNewUser(
          getValue(fieldState, EMAILADDRESS),
          getValue(fieldState, PASSWORD)
        )
    ).then(() => ({
      replaceView: { id: PROFILE_VIEW, mode: viewMode.CREATE }
    }));
  };

  const canEditRole = mode =>
    (role === authControl.ROLE_STAFF || role === authControl.ROLE_ADMIN) &&
    mode === viewMode.UPDATE &&
    authControl.isAdmin();

  // user is administrator
  // user is self, update mode
  const canChangePassword = mode =>
    mode === viewMode.UPDATE &&
    (authControl.isAdmin() ||
      (entity && authControl.user && entity.key === authControl.user.uid));

  const updateProfile = ({ fieldState, entity, view }) => {
    if (authControl.profile.val && !entity) {
      // This is an edge case where the user selects 'Register'
      // but then registers with an already-registered
      // social media accont.
      return new Promise(resolve => resolve({ success: true }));
    }
    const profile = {
      name: getValue(fieldState, NAME),
      address: getValue(fieldState, ADDRESS),
      city: getValue(fieldState, CITY),
      province: getValue(fieldState, PROVINCE),
      phoneNumber: getValue(fieldState, PHONENUMBER),
      emergencyName: getValue(fieldState, EMERGENCYNAME),
      emergencyPhone: getValue(fieldState, EMERGENCYPHONE),
      notifyEmail: getValue(fieldState, NOTIFYEMAIL),
      notifySMS: getValue(fieldState, NOTIFYSMS)
    };
    const isAdmin = getValue(fieldState, ISADMIN);
    if (getValue(fieldState, CHANGEPASSWORD)) {
      profile.newPassword = getValue(fieldState, PASSWORD);
    }
    return (authControl.profile.val
      ? Promise.all([
          firebase.updateProfile(entity.key, profile),
          canEditRole(view.mode)
            ? firebase.setTherapistRole(
                entity.key,
                getValue(fieldState, ISTHERAPIST)
              )
            : true,
          canEditRole(view.mode) && entity.key !== authControl.user.uid
            ? isAdmin
              ? firebase.makeAdminUser(entity.key)
              : firebase.makeStaffUser(entity.key)
            : true
        ])
      : firebase.createProfile(profile)
    ).then(() => {
      if (!entity || entity.key === authControl.profile.key) {
        authControl.reloadUserProfile();
      }
      if (postRegister) {
        postRegister({ delay: 300 });
      } else {
        if (initialView.mode !== viewMode.LIST) {
          appControl.displayAppMessage({
            message: 'User profile updated.'
          });
        }
      }
      return { success: true };
    });
  };

  /////////////////////////////////////////////////
  // Master Table View

  const userTable = entity => (
    <table>
      <tbody>
        <tr>
          <td>Name:</td>
          <td>{getName(entity)}</td>
        </tr>
        <tr>
          <td>Address:</td>
          <td>{entity.val ? entity.val.address : ''}</td>
        </tr>
        <tr>
          <td>City/Town:</td>
          <td>{entity.val ? entity.val.city : ''}</td>
        </tr>
        <tr>
          <td>Province:</td>
          <td>{entity.val ? entity.val.province : ''}</td>
        </tr>
        <tr>
          <td>Email:</td>
          <td>{entity.auth ? entity.auth.email : ''}</td>
        </tr>
        <tr>
          <td>Phone Number:</td>
          <td>{entity.val ? entity.val.phoneNumber : ''}</td>
        </tr>
      </tbody>
    </table>
  );

  const confirmMakeStaff = (entity, col, viewControl) =>
    viewControl.actionDialog({
      title: 'Convert User to Staff User',
      body: (
        <div>
          <h5>Are you sure you want to make this user a BKPT Staff User?</h5>
          {userTable(entity)}
        </div>
      ),
      actions: [
        {
          title: 'Go Back',
          color: 'success',
          onActivate: viewControl => viewControl.rewind()
        },
        {
          title: 'Make Staff',
          color: 'warning',
          icon: Build,
          onActivate: viewControl => {
            viewControl.setPending(true);
            firebase
              .makeStaffUser(entity.key)
              .then(() => {
                viewControl.setPending(false);
                viewControl.rewind();
              })
              .catch(error => {
                viewControl.setPending(false);
                viewControl.setError(error.message);
                viewControl.rewind();
              });
          }
        }
      ]
    });

  const confirmDeleteUser = (entity, col, viewControl) =>
    viewControl.actionDialog({
      title: 'Verify Delete User',
      body: (
        <div>
          <h5>Are you sure you want to delete this user?</h5>
          {userTable(entity)}
        </div>
      ),
      actions: [
        {
          title: 'Go Back',
          color: 'success',
          onActivate: viewControl => viewControl.rewind()
        },
        {
          title: 'Delete User',
          color: 'danger',
          icon: Close,
          onActivate: viewControl => {
            viewControl.setPending(true);
            const localDate = moment()
              .format()
              .substring(0, 10);
            firebase
              .deleteUser(entity.key, localDate)
              .then(() => {
                viewControl.setPending(false);
                viewControl.rewind();
              })
              .catch(error => {
                viewControl.setPending(false);
                viewControl.setError(error.message);
                viewControl.rewind();
              });
          }
        }
      ]
    });

  const formatCell = values => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {values.map((value, index) =>
        value ? (
          <div key={index} style={{ fontSize: '14px' }}>
            {value}
          </div>
        ) : null
      )}
    </div>
  );

  const getName = entity =>
    entity.val
      ? entity.val.name
      : entity.auth
      ? entity.auth.displayName
      : undefined;

  const tableModel = {
    pageRows: 3,
    heading: role === authControl.ROLE_STAFF ? 'BKPT Staff' : 'BKPT Users',
    columns: [
      {
        heading: 'Name',
        fetch: (entity, col) => formatCell([getName(entity) || 'Error'])
      },
      {
        heading: 'Contact',
        fetch: (entity, col) =>
          formatCell([
            entity.auth && entity.auth.email,
            entity.val && entity.val.phoneNumber
          ])
      },
      {
        heading: 'Actions',
        actions: [
          {
            title: 'Edit',
            color: 'success',
            icon: ContactPhone,
            show: true,
            isActive: (entity, col) => true,
            onActivate: (entity, col, viewControl) =>
              viewControl.editDetail(entity, PROFILE_VIEW)
          },
          {
            title: 'Make Staff',
            color: 'warning',
            icon: Build,
            show: role === authControl.ROLE_USER,
            isActive: (entity, col) => true,
            onActivate: confirmMakeStaff
          },
          {
            title: 'Appointments',
            color: 'info',
            icon: DateRange,
            show: role === authControl.ROLE_USER,
            isActive: (entity, col) => true,
            onActivate: (entity, col, viewControl) => {
              viewControl.rewind();
              appControl.openScheduleDialog({
                patientId: entity.key,
                adminMode: true,
                delay: 100
              });
            }
          },
          {
            title: 'Schedule',
            color: 'info',
            icon: DateRange,
            show: role === authControl.ROLE_STAFF,
            isActive: (entity, col) => true,
            onActivate: (entity, col, viewControl) => {
              viewControl.rewind();
              appControl.openScheduleDialog({
                staffId: entity.key,
                adminMode: true,
                delay: 100
              });
            }
          },
          {
            title: 'Delete',
            color: 'danger',
            icon: Close,
            show: true,
            isActive: (entity, col) => true,
            onActivate: confirmDeleteUser
          }
        ]
      }
    ],
    loadDataSet: () => firebase.loadUsers(role),
    dataSort: (a, b) =>
      getName(a) < getName(b) ? -1 : getName(a) > getName(b) ? 1 : 0,
    displayFilter: null,
    noDataMessage: 'No users were found.',
    loadingDataMessage: 'Loading users.'
  };

  const registerForm = {
    onSubmit: registerUser,
    title: 'Register with your email',
    fields: [
      {
        id: EMAILADDRESS,
        component: CustomInput,
        type: 'email',
        label: 'Email',
        required: true,
        validate: [{ email: 'Please enter a valid email address.' }]
      },
      {
        id: PASSWORD,
        component: CustomInput,
        type: 'password',
        label: 'Password',
        required: true,
        validate: [
          {
            minLength: [8, 'Minimum password length is 8 characters.']
          }
        ]
      },
      {
        id: VERIFYPASSWORD,
        component: CustomInput,
        type: 'password',
        label: 'Verify Password',
        required: true,
        validate: [
          {
            compareEqual: [PASSWORD, "Your passwords don't match."]
          }
        ],
        initForUpdate: ({ entity }) => ({ value: entity.verifyPassword })
      }
    ],
    saveText: 'Register',
    // eslint-disable-next-line react/prop-types
    footer: ({ handleSubmit, viewControl }) => (
      <>
        <h5 className={`${classes.loginOption} ${classes.textCenter}`}>
          Or register with
        </h5>
        <div className={classes.socialLine}>
          <Button
            justIcon
            round
            color="facebook"
            size="sm"
            title="Facebook"
            className={classes.socialLineButton}
            onClick={() => handleSubmit(authControl.facebookLogin, false)}
            // eslint-disable-next-line react/prop-types
            disabled={viewControl.pending}
          >
            <i className="fab fa-facebook" />
          </Button>
          <Button
            justIcon
            round
            color="twitter"
            size="sm"
            title="Twitter"
            className={classes.socialLineButton}
            onClick={() => handleSubmit(authControl.twitterLogin, false)}
            // eslint-disable-next-line react/prop-types
            disabled={viewControl.pending}
          >
            <i className="fab fa-twitter" />
          </Button>
          <Button
            justIcon
            round
            color="google"
            size="sm"
            title="Google"
            className={classes.socialLineButton}
            onClick={() => handleSubmit(authControl.googleLogin, false)}
            // eslint-disable-next-line react/prop-types
            disabled={viewControl.pending}
          >
            <i className="fab fa-google-plus-g" />
          </Button>
        </div>
      </>
    ),
    effects: []
  };

  const profileForm = {
    onSubmit: updateProfile,
    fields: [
      {
        id: NAME,
        component: CustomInput,
        label: 'Your Name',
        required: true,
        validate: [{ required: 'Please enter your name.' }],
        initForUpdate: ({ entity }) => ({
          value: getName(entity) || ''
        })
      },
      {
        id: ISTHERAPIST,
        omitField: ({ view }) => !canEditRole(view.mode),
        component: Checkbox,
        label: 'Enable Customer Scheduling',
        initForUpdate: ({ entity }) => ({
          value:
            entity && entity.auth && entity.auth.customClaims
              ? entity.auth.customClaims.therapist
              : false
        })
      },
      {
        id: ISADMIN,
        omitField: ({ view }) =>
          !canEditRole(view.mode) ||
          !view.entity ||
          view.entity.key === authControl.user.uid,
        component: Checkbox,
        label: 'Make BKPT Administrator',
        initForUpdate: ({ entity }) => ({
          value:
            entity && entity.auth && entity.auth.customClaims
              ? entity.auth.customClaims.role === authControl.ROLE_ADMIN
              : false
        })
      },
      {
        id: ADDRESS,
        component: CustomInput,
        label: 'Address',
        initForUpdate: ({ entity }) => ({
          value: entity && entity.val ? entity.val.address : ''
        })
      },
      {
        id: CITY,
        component: CustomInput,
        label: 'Town/City',
        initForUpdate: ({ entity }) => ({
          value: entity && entity.val ? entity.val.city : ''
        })
      },
      {
        id: PROVINCE,
        component: CustomInput,
        label: 'Province',
        initForUpdate: ({ entity }) => ({
          value: entity && entity.val ? entity.val.province : ''
        })
      },
      {
        id: PHONENUMBER,
        component: CustomInput,
        type: 'tel',
        label: 'Phone Number',
        required: true,
        validate: [{ required: 'Please enter a phone number.' }],
        initForUpdate: ({ entity }) => ({
          value: entity && entity.val ? entity.val.phoneNumber : ''
        })
      },
      {
        id: NOTIFYEMAIL,
        component: Checkbox,
        label: 'EMail Notifications',
        initForCreate: true,
        initForUpdate: ({ entity }) => ({
          value: Boolean(entity && entity.val ? entity.val.notifyEmail : true)
        })
      },
      {
        id: NOTIFYSMS,
        component: Checkbox,
        label: 'SMS Notifications',
        initForCreate: false,
        initForUpdate: ({ entity }) => ({
          value: Boolean(entity && entity.val ? entity.val.notifySMS : false)
        })
      },
      {
        id: EMERGENCYNAME,
        component: CustomInput,
        label: 'Emergency Contact',
        initForUpdate: ({ entity }) => ({
          value: entity && entity.val ? entity.val.emergencyName : ''
        })
      },
      {
        id: EMERGENCYPHONE,
        component: CustomInput,
        label: 'Emergency Contact Phone #',
        initForUpdate: ({ entity }) => ({
          value: entity && entity.val ? entity.val.emergencyPhone : ''
        })
      },
      {
        id: CHANGEPASSWORD,
        omitField: ({ view }) => !canChangePassword(view.mode),
        component: Checkbox,
        label: 'Change Password',
        initForUpdate: { value: false },
        initForCreate: { value: false }
      },
      {
        id: PASSWORD,
        omitField: ({ view, fieldState }) =>
          !canChangePassword(view.mode) || !fieldState(CHANGEPASSWORD).value,
        component: CustomInput,
        type: 'password',
        label: 'New Password',
        required: true,
        validate: [
          {
            minLength: [8, 'Minimum password length is 8 characters.']
          }
        ]
      },
      {
        id: VERIFYPASSWORD,
        omitField: ({ view, fieldState }) =>
          !canChangePassword(view.mode) || !fieldState(CHANGEPASSWORD).value,
        component: CustomInput,
        type: 'password',
        label: 'Verify New Password',
        required: true,
        validate: [
          {
            compareEqual: [PASSWORD, "Your passwords don't match."]
          }
        ],
        initForUpdate: ({ entity }) => ({ value: entity.verifyPassword })
      }
    ],
    effects: []
  };

  // console.log('render');
  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title={title}
      initialView={initialView}
    >
      <DataTable id={stdView.MASTER} tableModel={tableModel} />
      <EmbeddedForm
        id={REGISTER_VIEW}
        form={registerForm}
        key={REGISTER_VIEW}
      />
      <EmbeddedForm id={PROFILE_VIEW} form={profileForm} key={PROFILE_VIEW} />
    </ModalDialog>
  );
};

UsersModal.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(viewModes),
  postRegister: PropTypes.func,
  entity: PropTypes.object,
  title: PropTypes.string.isRequired,
  role: PropTypes.string
};

export default withStyles(styles)(UsersModal);
