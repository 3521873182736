const parallaxStyle = {
  parallax: {
    position: 'fixed',
    height: '100vh',
    width: '100%',
    zIndex: -1,
    top: 0,
    left: 0,
    margin: 0,
    padding: 0,
    border: 0
  }
};

export default parallaxStyle;
