import { createMuiTheme } from '@material-ui/core/styles';

// Use the default Material-UI theme, but
// use their "new" tpography variants

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  }
});

export default theme;
