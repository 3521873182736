import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';

import style from './flextableStyle';

const TableCell = ({ classes, tableControl, col, row, cell }) => {
  const {
    resolve,
    viewControl: { pending }
  } = tableControl;
  const { span, alert, warn, actions } = col;
  const { onClick } = row;

  // console.log('render');
  return (
    <div className={classes.tablecellWrapper} style={{ flex: resolve(span) }}>
      <div
        className={classNames({
          [classes.tablecell]: true,
          [classes.alertcell]: alert && resolve(alert) === cell,
          [classes.warncell]: warn && resolve(warn) === cell,
          [classes.selectable]: !actions && !pending && onClick
        })}
      >
        {actions
          ? cell.map(
              (action, i) =>
                action.omit || (
                  <action.icon
                    className={classNames({
                      [classes.actionicon]: true,
                      [classes.actioniconSelectable]:
                        action.active && !pending && action.onClick
                    })}
                    titleAccess={action.title}
                    size="small"
                    onClick={event => {
                      event.stopPropagation();
                      !pending &&
                        action.active &&
                        action.onClick &&
                        action.onClick(row, action);
                    }}
                    key={i}
                  />
                )
            )
          : cell}
      </div>
    </div>
  );
};

TableCell.propTypes = {
  classes: PropTypes.object.isRequired,
  tableControl: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  col: PropTypes.object.isRequired,
  cell: PropTypes.any.isRequired
};

export default withStyles(style)(TableCell);
