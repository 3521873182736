const sectionStyle = {
  section: {
    width: '100%',
    paddingTop: 90,
    height: '100vh',
    backgroundColor: 'white'
  },
  expandVertical: {
    height: 'unset',
    minHeight: '100vh'
  },
  transparent: {
    backgroundColor: 'transparent'
  },
  backgroundImage: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    height: '100%'
  }
};

export default sectionStyle;
