import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import sectionStyle from './sectionStyle';

const Section = ({
  id,
  classes,
  children,
  transparent,
  expandVertical,
  backgroundImage,
  customClass,
  customStyle
}) => {
  const sectionClasses = classNames({
    [classes.section]: true,
    [classes.expandVertical]: expandVertical,
    [classes.transparent]: transparent,
    [customClass]: customClass
  });

  return (
    <section id={id} className={sectionClasses} style={customStyle}>
      {backgroundImage && backgroundImage.image ? (
        <div
          className={classNames([
            classes.backgroundImage,
            ...(backgroundImage.classes || [])
          ])}
          style={{
            backgroundImage: `url(${backgroundImage.image.url})`,
            ...backgroundImage.styles
          }}
        >
          {children}
        </div>
      ) : (
        children
      )}
    </section>
  );
};

Section.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string,
  children: PropTypes.node,
  transparent: PropTypes.bool,
  expandVertical: PropTypes.bool,
  backgroundImage: PropTypes.object,
  customClass: PropTypes.string,
  customStyle: PropTypes.object
};

export default withStyles(sectionStyle)(Section);
