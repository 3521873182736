export const viewMode = {
  READ: 'read',
  UPDATE: 'update',
  CREATE: 'create',
  MASTER: 'master'
};
export const viewModes = [viewMode.CREATE, viewMode.UPDATE, viewMode.MASTER];
export const stdView = {
  MASTER: 'master',
  DETAIL: 'detail',
  CONFIRM: 'confirm'
};
export const stdViews = [stdView.MASTER, stdView.DETAIL, stdView.CONFIRM];
