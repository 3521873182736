import React from 'react';
import PropTypes from 'prop-types';

import Section from '../Section/Section';

const ContentSection = ({
  id,
  children,
  transparent,
  expandVertical,
  injectClasses,
  injectStyles,
  backgroundImage
}) => {
  const customClasses = injectClasses ? { customClasses: injectClasses } : {};
  const customStyles = injectStyles ? { customStyle: injectStyles } : {};
  return (
    <Section
      id={id}
      {...customClasses}
      transparent={transparent}
      expandVertical={expandVertical}
      backgroundImage={backgroundImage}
      {...customStyles}
    >
      {children}
    </Section>
  );
};

// eslint-disable-next-line react/display-name
ContentSection.createFromCms = cmsContext => {
  const {
    cmsClasses,
    key,
    children,
    styles,
    localStyles,
    component: {
      transparent,
      expandVertical,
      backgroundImage,
      backgroundImageClassses,
      backgroundImageStyles
    }
  } = cmsContext;
  const props = {
    id: key,
    children,
    backgroundImage: backgroundImage &&
      backgroundImage.value &&
      backgroundImage.value[0] && {
        image: backgroundImage.value[0].image,
        classes: backgroundImageClassses
          ? backgroundImageClassses.map(styleKey => cmsClasses[styleKey])
          : undefined,
        styles: backgroundImageStyles
      },
    transparent,
    expandVertical,
    injectClasses: styles,
    injectStyles: localStyles
  };
  return <ContentSection {...props} key={key} />;
};

ContentSection.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node,
  backgroundImage: PropTypes.object,
  transparent: PropTypes.bool,
  expandVertical: PropTypes.bool,
  injectClasses: PropTypes.array,
  injectStyles: PropTypes.object
};

export default ContentSection;
