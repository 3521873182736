import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TextBlock = ({ content, element, injectStyles, injectClasses }) => {
  const classes = injectClasses ? { className: classNames(injectClasses) } : {};
  const styles = injectStyles ? { style: injectStyles } : {};
  switch (element) {
    case 'p':
      return (
        <p {...classes} {...styles}>
          {content}
        </p>
      );
    case 'div':
      return (
        <div {...classes} {...styles}>
          {content}
        </div>
      );
    case 'span':
      return (
        <span {...classes} {...styles}>
          {content}
        </span>
      );
    case 'h1':
      return (
        <h1 {...classes} {...styles}>
          {content}
        </h1>
      );
    case 'h2':
      return (
        <h2 {...classes} {...styles}>
          {content}
        </h2>
      );
    case 'h3':
      return (
        <h3 {...classes} {...styles}>
          {content}
        </h3>
      );
    case 'h4':
      return (
        <h4 {...classes} {...styles}>
          {content}
        </h4>
      );
    case 'h5':
      return (
        <h5 {...classes} {...styles}>
          {content}
        </h5>
      );
    case 'h6':
      return (
        <h6 {...classes} {...styles}>
          {content}
        </h6>
      );
    default:
      return content;
  }
};

// eslint-disable-next-line react/display-name
TextBlock.createFromCms = cmsContext => {
  const {
    key,
    styles,
    localStyles,
    component: { element, text }
  } = cmsContext;
  const props = {
    content:
      (text && text.value && text.value[0] && text.value[0].string) ||
      undefined,
    element,
    injectClasses: styles,
    injectStyles: localStyles
  };
  return <TextBlock {...props} key={key} />;
};

TextBlock.propTypes = {
  content: PropTypes.string,
  element: PropTypes.string.isRequired,
  injectClasses: PropTypes.array,
  injectStyles: PropTypes.object
};

export default TextBlock;
