import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import Warning from '@material-ui/icons/Warning';
import SwapHoriz from '@material-ui/icons/SwapHoriz';
import { util } from 'bkptshared';

import style from './calendarStyle';

const range = (start, end) =>
  Array(end - start)
    .fill(0)
    .map((_, i) => start + i);

const Day = ({ classes, viewControl, calendarModel }) => {
  const [group, setGroup] = useState(0);
  const { pending } = viewControl;

  let {
    dayView: { earliest, latest }
  } = calendarModel;
  const {
    dayView: { groupSets }
  } = calendarModel;
  let groups;
  let data;
  if (groupSets && groupSets[group]) {
    groups = groupSets[group].cols;
    data = groupSets[group].data;
  }

  const schedule = [];
  if (groups) {
    for (let i = 0; i < groups.length; i++) {
      const hours = new Array(24).fill(null);
      const group = [hours];
      schedule.push(group);
    }
  }

  const getGroupItems = index => data[groups[index].id];

  const putItem = (groupNum, item) => {
    const { from, to } = item;
    if (from < earliest) {
      earliest = from;
    }
    if (to + 1 > latest) {
      latest = to + 1;
    }
    const group = schedule[groupNum];
    let slot = -1;
    for (let s = 0; s < group.length; s++) {
      let clear = true;
      for (let h = from; h < to; h++) {
        if (group[s][h] !== null) {
          clear = false;
          break;
        }
      }
      if (clear) {
        slot = s;
        break;
      }
    }
    if (slot === -1) {
      slot = group.length;
      group.push(new Array(24).fill(null));
    }
    for (let h = from; h < to; h++) {
      group[slot][h] = { ...item };
    }
  };

  if (data && groups) {
    for (let g = 0; g < groups.length; g++) {
      getGroupItems(g).forEach(item => putItem(g, item));
    }
  }

  const slotList = [];
  const colorList = [
    [classes.dayViewColor0, classes.dayViewColor0Selectable],
    [classes.dayViewColor1, classes.dayViewColor1Selectable],
    [classes.dayViewColor2, classes.dayViewColor2Selectable]
  ];
  let nextColor = 0;
  const colorMap = {};
  schedule.forEach(group =>
    group.forEach((slot, s) => {
      slotList.push(slot);
      for (let h = 0; h < 24; h++) {
        if (slot[h] !== null) {
          const { from, to, id } = slot[h];
          // assign a color
          if (!colorMap[id]) {
            colorMap[id] = colorList[nextColor % colorList.length];
            nextColor++;
          }
          let span = 1;
          for (let s2 = s + 1; s2 < group.length; s2++) {
            let isClear = true;
            for (let h2 = from; h2 < to; h2++) {
              if (group[s2][h2] !== null) {
                isClear = false;
                break;
              }
            }
            if (isClear) {
              span++;
            } else {
              break;
            }
          }
          slot[h].span = span;
          for (let h2 = from + 1; h2 < to; h2++) {
            slot[h2] = null;
          }
          h += to - from - 1;
        }
      }
    })
  );

  const heightClasses = [];
  const topClasses = [];
  for (let h = 0; h < 24; h++) {
    heightClasses.push(classes[`dayViewCellHeight${h + 1}`]);
    topClasses.push(classes[`dayViewCellTop${h}`]);
  }

  const emptyCell = key => <div className={classes.emptyCell} key={key} />;

  const dayViewRow = (cells, row) => (
    <div className={`${classes.dayViewRow} ${topClasses[row]}`} key={`r${row}`}>
      {cells}
    </div>
  );

  const dayViewCell = (item, key) => {
    const { from, to, text, span, id, onClick, alert } = item;
    const height = to - from - 1;
    return (
      <div
        className={`${classes.dayViewCellWrapper} ${heightClasses[height]}`}
        style={{ flex: span }}
        key={key}
      >
        <div
          className={classNames({
            [classes.dayViewCell]: true,
            [colorMap[id][0]]: true,
            [colorMap[id][1]]: onClick && !pending,
            [classes.dayViewAlert]: alert
          })}
          onClick={() => !pending && onClick && onClick(item)}
        >
          {alert && <Warning color="inherit" fontSize="small" />}
          {text}
        </div>
      </div>
    );
  };

  // render the rows
  const scheduleRows = [];
  for (let h = earliest; h < latest; h++) {
    const cols = [];
    let hasCell = false;
    slotList.forEach(slot => {
      const cell = slot[h];
      cols.push(cell);
      if (cell !== null) {
        hasCell = true;
      }
    });
    if (hasCell) {
      const cells = [];
      for (let c = 0; c < cols.length; c++) {
        if (cols[c]) {
          // render cell of appropriate height and span
          cells.push(dayViewCell(cols[c], `h${h}.c${c}`));
          c += cols[c].span - 1;
        } else {
          // render an empty 1-span cell
          cells.push(emptyCell(`h${h}.c${c}`));
        }
      }
      scheduleRows.push(dayViewRow(cells, h - earliest));
    }
  }

  return (
    <div className={classes.dayViewContainer}>
      <div className={classes.dayViewTimeCol}>
        <div className={classes.dayViewCorner}>
          {groupSets.length > 1 && (
            <SwapHoriz
              size="small"
              color="inherit"
              onClick={() =>
                setGroup(group + 1 < groupSets.length ? group + 1 : 0)
              }
            />
          )}
        </div>
        {range(earliest, latest).map(h => (
          <div className={classes.timeCell} key={`h${h}`}>
            {util.hourToString12Short(h)}
          </div>
        ))}
      </div>
      <div className={classes.dayViewScheduleCol}>
        <div className={classes.dayViewScheduleHead}>
          {groups &&
            groups.map((group, g) => (
              <div
                className={`${classes.dayViewGroupHead} ${classes.dayViewGroupTitle} ${classes.dayViewCellBorder}`}
                key={`g${g}`}
                style={{ flex: schedule[g].length }}
              >
                {group.title}
              </div>
            ))}
        </div>
        <div classes={classes.dayViewBody} style={{ position: 'relative' }}>
          <div className={classes.dayViewBg}>
            {range(earliest, latest).map(h => (
              <div className={classes.dayViewBgRow} key={`bg.h${h}`}>
                {groups &&
                  groups.map((group, g) => (
                    <div
                      className={`${classes.dayViewBgCell} ${classes.dayViewCellBorder}`}
                      key={`bg.h${h}.g${g}`}
                      style={{ flex: schedule[g].length }}
                    />
                  ))}
              </div>
            ))}
          </div>
          <div className={classes.dayViewContentWrapper}>
            <div className={classes.dayViewRowContainer}>{scheduleRows}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

Day.propTypes = {
  classes: PropTypes.object.isRequired,
  viewControl: PropTypes.object.isRequired,
  calendarModel: PropTypes.object.isRequired
};

export default withStyles(style)(Day);
