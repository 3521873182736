import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// material-ui icons
import SkipNext from '@material-ui/icons/SkipNext';
import SkipPrev from '@material-ui/icons/SkipPrevious';
import FilterList from '@material-ui/icons/FilterList';
// core components
import Table from '../../mui-pro/components/Table/Table.jsx';
import Button from '../../mui-pro/components/CustomButtons/Button.jsx';

import style from './dataTableStyle';

const DataTable = ({ classes, tableModel, viewControl }) => {
  const [dataSet, setDataSet] = useState(null);
  const [page, setPage] = useState(
    viewControl.initState && viewControl.initState.page
      ? viewControl.initState.page
      : {
          entities: [],
          prev: null,
          next: null,
          first: null
        }
  );
  const [fetchPage, setFetchPage] = useState(
    viewControl.initState && viewControl.initState.fetchPage
      ? viewControl.initState.fetchPage
      : { next: null }
  );
  const [tableData, setTableData] = useState([]);
  const { pending, setPending, setError } = viewControl;

  //const tableModel = tableModelFn(viewControl);
  const tableDependencies = tableModel.dependencies || [];

  viewControl.handleEvents({
    willClose: () => {
      return viewControl.saveState({ page, fetchPage });
    }
  });

  const tableProps = useMemo(() => {
    const customCellClasses = [];
    const customClassesForCells = [];
    const customHeadCellClasses = [];
    const customHeadClassesForCells = [];
    const tableHead = tableModel.columns.map((column, index) => {
      if (column.customCellClass) {
        customCellClasses.push(column.customCellClass);
        customClassesForCells.push(index);
      }
      if (column.customHeaderClass) {
        customHeadCellClasses.push(column.customHeaderClass);
        customHeadClassesForCells.push(index);
      }
      return column.heading;
    });
    return {
      tableHead,
      customCellClasses,
      customClassesForCells,
      customHeadCellClasses,
      customHeadClassesForCells
    };
  }, []);

  const loadNext = () => page.next && setFetchPage({ next: page.next });

  const loadPrev = () => page.prev && setFetchPage({ prev: page.prev });

  const filterNext = (entities, start, rows) => {
    const filter = tableModel.displayFilter;
    const result = [];
    let index = start ? start.index : 0;
    while (result.length < rows && index >= 0 && index < entities.length) {
      const entity = entities[index];
      if (!filter || filter(entity)) {
        result.push(entity);
      }
      index++;
    }
    return result;
  };

  const filterPrev = (entities, end, rows) => {
    const filter = tableModel.displayFilter;
    const result = [];
    let index = end ? end.index : entities.length - 1;
    if (!end) {
      rows--;
    }
    while (result.length < rows && index >= 0 && index < entities.length) {
      const entity = entities[index];
      if (!filter || filter(entity)) {
        result.unshift(entity);
      }
      index--;
    }
    return result;
  };

  const loadDataSet = () => {
    if (dataSet) {
      return new Promise(resolve => resolve(dataSet));
    } else {
      setPending(true);
      return tableModel.loadDataSet().then(result => {
        let entities = result;
        const { dataSort } = tableModel;
        if (dataSort) {
          entities = entities.sort((a, b) => dataSort(a, b));
          entities.forEach((entity, index) => (entity.index = index));
        }
        setPending(false);
        setDataSet(entities);
        return entities;
      });
    }
  };

  useEffect(() => {
    const fetchRows = tableModel.pageRows + 1;
    const isReload = !dataSet && page.first;
    loadDataSet()
      .then(result => ({
        data: result,
        entities: fetchPage.prev
          ? filterPrev(result, fetchPage.prev, fetchRows)
          : filterNext(result, fetchPage.next, fetchRows)
      }))
      .then(result => {
        let { data, entities } = result;
        if (entities.length === 0 && data.length > 0) {
          entities = filterPrev(data, null, fetchRows);
        }
        let first = page.first;
        if (isReload && !data.find(entity => entity.key === first.key)) {
          first = null;
          entities = filterNext(data, null, fetchRows);
        }
        const count = entities.length;
        if (!first) {
          first = count ? entities[0] : null;
        }
        const prev =
          count && first && entities[0].key !== first.key ? entities[0] : null;
        const next = count > tableModel.pageRows ? entities.pop() : null;
        setPage({ entities, first, prev, next });
      })
      .catch(error => {
        setError(error.message);
        setPending(false);
      });
  }, [fetchPage, ...tableDependencies]);

  useEffect(() => {
    const createActions = (entity, col) => {
      const actions = [];
      col.actions.forEach((action, index) => {
        if (action.show) {
          actions.push(
            <Button
              justIcon
              size="sm"
              color={action.color}
              key={index}
              title={action.title}
              disabled={
                pending ||
                action.isActive === undefined ||
                !action.isActive(entity, col)
              }
              onClick={() => action.onActivate(entity, col, viewControl)}
            >
              <action.icon />
            </Button>
          );
        }
      });
      return actions;
    };
    const table = [];
    page.entities.forEach(entity => {
      const row = [];
      tableModel.columns.forEach(column => {
        row.push(
          column.actions
            ? createActions(entity, column)
            : column.fetch(entity, column)
        );
      });
      table.push(row);
    });
    setTableData(table);
  }, [page, ...tableDependencies, viewControl]);

  const actions = (
    <>
      {tableModel.createNew && (
        <Button
          color="primary"
          size="sm"
          onClick={() => tableModel.createNew(viewControl)}
          disabled={pending}
        >
          {tableModel.createLabel}
        </Button>
      )}
      <Button
        color="primary"
        size="sm"
        onClick={viewControl.rewind}
        disabled={pending}
      >
        Close
      </Button>
    </>
  );
  useEffect(() => {
    return viewControl.setActions(actions);
  }, [pending]);

  // console.log('render');
  return (
    <>
      <div className={classes.controlRow}>
        {page.first && (page.prev || page.next) && (
          <Button
            justIcon
            round
            color="transparent"
            disabled={!page.first || pending || !page.prev}
            onClick={loadPrev}
            size="sm"
          >
            <SkipPrev style={{ color: 'white' }} />
          </Button>
        )}
        <div className={classes.controlGroup}>
          <h4 className={`${classes.heading} ${classes.textCenter}`}>
            {tableModel.heading}
          </h4>
          {tableModel.filterViewId && (
            <Button
              justIcon
              round
              color="transparent"
              disabled={pending}
              onClick={() =>
                viewControl.editDetail(
                  tableModel.filterData,
                  tableModel.filterViewId
                )
              }
              size="sm"
            >
              <FilterList style={{ color: 'white' }} />
            </Button>
          )}
        </div>
        {page.first && (page.prev || page.next) && (
          <Button
            justIcon
            round
            color="transparent"
            disabled={!page.first || pending || !page.next}
            onClick={loadNext}
            size="sm"
          >
            <SkipNext style={{ color: 'white' }} />
          </Button>
        )}
      </div>
      {page.first ? (
        <Table tableData={tableData} {...tableProps} />
      ) : (
        <div className={classes.noTableMessage}>
          <h5>
            {pending ? tableModel.loadingDataMessage : tableModel.noDataMessage}
          </h5>
        </div>
      )}
    </>
  );
};

DataTable.propTypes = {
  classes: PropTypes.object.isRequired,
  tableModel: PropTypes.object.isRequired,
  viewControl: PropTypes.object.isRequired
};

export default withStyles(style)(DataTable);
