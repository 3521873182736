import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import * as moment from 'moment';

import style from './calendarStyle';

const Month = ({ classes, viewControl: { pending }, calendarModel }) => {
  const {
    earliest,
    selected,
    calendarDate,
    setCalendarDate,
    setDayMode,
    dayView,
    getCellForDate
  } = calendarModel;

  const week = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const monthStart = moment(calendarDate).date(1);
  const currentMonth = calendarDate.month();
  const date = moment(monthStart).day(0);
  const dateTo = moment(monthStart)
    .add(1, 'M')
    .subtract(1, 'd')
    .day(6);
  const month = [];
  while (date.isSameOrBefore(dateTo)) {
    month.push(
      week.map((_, d) => {
        const dd = moment(date).day(d);
        return dd.month() === currentMonth ? dd : null;
      })
    );
    date.add(7, 'd');
  }

  const dayOfMonth = day => (
    <span
      className={classNames({
        [classes.day]: true,
        [classes.dayPast]: day.isBefore(earliest, 'day'),
        [classes.daySelected]: selected && day.isSame(selected, 'day')
      })}
    >
      {day.date()}
    </span>
  );

  const dayIcons = (day, cellData) => (
    <div className={classes.cellIcons}>
      {cellData.actions &&
        cellData.actions.map(action => {
          const { key, state, onClick, active } = action;
          const colors = {
            inactive: classes.colorInactive,
            active: classes.colorActive,
            success: classes.colorSuccess,
            danger: classes.colorDanger
          };
          const tagClasses = {
            fontSizeSmall: classes.actionIcon,
            colorPrimary: colors[state]
          };
          if (active) {
            tagClasses.root = classes.selectable;
          }
          return (
            <action.icon
              key={key}
              color="primary"
              fontSize="small"
              classes={tagClasses}
              onClick={() => active && onClick && onClick({ day, cellData })}
            />
          );
        })}
    </div>
  );

  const dayCell = (day, w, d) => {
    const cellData = day && getCellForDate(day);
    const isSelected = selected && day && day.isSame(selected, 'day');
    return (
      <div className={classes.cellWrapper} key={`${w}.${d}`}>
        <div className={classes.cellContainer}>
          {cellData && (
            <div
              className={classNames({
                [classes.cellContents]: true,
                [classes.cellNormal]: !isSelected,
                [classes.cellSelected]: isSelected,
                [classes.cellSelectable]:
                  !pending && (cellData.onClick || dayView)
              })}
              onClick={() => {
                if (!pending) {
                  if (cellData.onClick) {
                    cellData.onClick({ cellData, day });
                  } else if (dayView) {
                    setCalendarDate(day);
                    setDayMode(true);
                  }
                }
              }}
            >
              {dayOfMonth(day)}
              {dayIcons(day, cellData)}
            </div>
          )}
        </div>
      </div>
    );
  };

  // console.log('render');
  return (
    <>
      <div className={classes.headerRow}>
        {week.map(day => (
          <div className={classes.headerCell} key={day}>
            <span className={classes.dayHeading}>{day}</span>
          </div>
        ))}
      </div>
      <div className={classes.calendarBody}>
        {month.map((week, w) => (
          <div className={classes.week} key={w}>
            {week.map((day, d) => dayCell(day, w, d))}
          </div>
        ))}
      </div>
    </>
  );
};

Month.propTypes = {
  classes: PropTypes.object.isRequired,
  viewControl: PropTypes.object.isRequired,
  calendarModel: PropTypes.object.isRequired
};

export default withStyles(style)(Month);
