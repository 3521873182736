import green from '@material-ui/core/colors/green';

import {
  cardTitle,
  description,
  whiteColor,
  grayColor
} from '../../mui-pro/assets/jss/material-kit-pro-react.jsx';

import modalStyle from '../../mui-pro/assets/jss/material-kit-pro-react/modalStyle.jsx';

const loginModalStyles = theme => ({
  description,
  cardTitle,
  ...modalStyle(theme),
  section: {
    padding: '70px 0 0'
  },
  loginCard: { maxWidth: 300, height: 'unset' },
  icon: {
    width: '24px',
    height: '24px',
    color: grayColor[13]
  },
  textCenter: {
    textAlign: 'center'
  },
  cardTitleWhite: {
    ...cardTitle,
    color: whiteColor + '  !important'
  },
  socialLine: {
    marginTop: '1rem',
    textAlign: 'center',
    padding: '0'
  },
  socialLineButton: {
    '&, &:hover': { color: whiteColor },
    marginLeft: '5px',
    marginRight: '5px'
  },
  cardLoginHeader: {
    marginTop: '-40px',
    padding: '20px 0',
    width: '100%',
    marginBottom: '15px'
  },
  cardLoginBody: {
    paddingTop: '0',
    paddingBottom: '0'
  },
  justifyContentCenter: {
    WebkitBoxPack: 'center !important',
    MsFlexPack: 'center !important',
    justifyContent: 'center !important'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
});

export default loginModalStyles;
