import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';

import SnackbarContent from '../../mui-pro/components/Snackbar/SnackbarContent';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  danger: ErrorIcon,
  info: InfoIcon,
  primary: InfoIcon
};

const snackbarMessage = ({
  open,
  onClose,
  message,
  variant,
  anchorOrigin,
  duration,
  color
}) => (
  <Snackbar
    anchorOrigin={
      anchorOrigin
        ? anchorOrigin
        : {
            vertical: 'bottom',
            horizontal: 'center'
          }
    }
    open={open}
    autoHideDuration={duration ? duration : 5000}
    onClose={onClose}
    style={{
      display: 'block',
      minWidth: 300
    }}
  >
    <SnackbarContent
      close
      color={color ? color : 'primary'}
      message={
        <span
          id="client-snackbar"
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {/* <Icon className={classNames(classes.icon, classes.iconVariant)} /> */}
          {message}
        </span>
      }
      icon={variantIcon[variant ? variant : 'primary']}
    />
  </Snackbar>
);

snackbarMessage.propTypes = {
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string,
  open: PropTypes.bool,
  variant: PropTypes.oneOf(['info', 'success', 'warning', 'danger', 'primary']),
  color: PropTypes.oneOf(['info', 'success', 'warning', 'danger', 'primary']),
  anchorOrigin: PropTypes.object,
  duration: PropTypes.number
};

export default snackbarMessage;
