import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';

import { AppContext } from '../../appContext';

import actionButtonStyle from './actionButtonStyle';

const ActionButton = ({ classes, buttonText, appAction }) => {
  const appControl = useContext(AppContext);
  return (
    <Fab
      className={classes.fab}
      classes={{ primary: classes.primary, secondary: classes.secondary }}
      variant="extended"
      color="secondary"
      onClick={() =>
        appAction && appControl[appAction] && appControl[appAction]()
      }
    >
      {buttonText}
    </Fab>
  );
};

// eslint-disable-next-line react/display-name
ActionButton.createFromCms = cmsContext => {
  const {
    key,
    component: { buttonText, appAction }
  } = cmsContext;
  const props = {
    buttonText,
    appAction
  };
  const ActionButtonCms = withStyles(actionButtonStyle)(ActionButton);
  return <ActionButtonCms {...props} key={key} />;
};

ActionButton.propTypes = {
  classes: PropTypes.object.isRequired,
  buttonText: PropTypes.string.isRequired,
  appAction: PropTypes.string
};

export default withStyles(actionButtonStyle)(ActionButton);
