import {
  description,
  dangerColor
} from '../../mui-pro/assets/jss/material-kit-pro-react.jsx';

const modalConfirmStyle = theme => ({
  description,
  error: {
    color: dangerColor[0],
    width: '100%',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    paddingRight: 10
  },
  buttonProgress: {
    color: '#9c27b0',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  textCenter: {
    textAlign: 'center'
  }
});

export default modalConfirmStyle;
