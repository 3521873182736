import {
  primaryColor,
  infoColor
} from '../../mui-pro/assets/jss/material-kit-pro-react.jsx';
const actionButtonStyle = {
  fab: {
    position: 'fixed',
    bottom: 20,
    border: '2px solid white',
    zIndex: 1100,
    transform: 'translate(-50%)',
    left: '50%',
    lineHeight: '16px'
  },
  '@media (min-width: 768px)': {
    fab: {
      right: 80,
      zIndex: 1100,
      bottom: 60,
      left: 'unset',
      transform: 'unset'
    }
  },
  primary: { backgroundColor: primaryColor[0] },
  secondary: { backgroundColor: infoColor[0] }
};

export default actionButtonStyle;
