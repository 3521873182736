import React, { useState, useEffect } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import CssBaseline from '@material-ui/core/CssBaseline';
import Helmet from 'react-helmet';
import { ThemeProvider } from '@material-ui/styles';

import '../mui-pro/assets/scss/material-kit-pro-react.scss?v=1.7.0';

import firebase, { AuthContext, FirebaseContext } from '../firebase';
import Layout from '../layouts/Layout';
import { viewMode } from '../components/viewControl';
import { AppContext } from '../appContext';
import StaticCMSLandingPage from '../components/StaticCMSLandingPage/StaticCMSLandingPage';
import LoginModal from '../components/LoginModal/LoginModal';
import UsersModal from '../components/UsersModal/UsersModal';
import SnackbarMessage from '../components/SnackbarMessage/SnackbarMessage';
import ModalInfoDialog from '../components/ModalInfoDialog/ModalInfoDialog';
import ScheduleModal from '../components/ScheduleModal/ScheduleModal';

import theme from '../theme';

// Role constants
const ROLE_ADMIN = 'admin';
const ROLE_STAFF = 'staff';
const ROLE_USER = 'user';
const ROLE_ANON = 'anon';

const App = props => (
  <StaticQuery
    query={graphql`
      query {
        site: datoCmsSiteConfig {
          title: siteTitle
        }
      }
    `}
    render={({ site: { title } }) => {
      // state of the login modal dialog
      const [loginModal, setLoginModal] = useState({ open: false });
      // state of the appMessage
      const [appMessage, setAppMessage] = useState({ open: false });
      // state of the default infoDialog
      const [infoDialog, setInfoDialog] = useState({ open: false });
      // state of the usersModal dialog
      const [usersModal, setUsersModal] = useState({ open: false });
      // state of the scheduleModal dialog
      const [scheduleModal, setScheduleModal] = useState({ open: false });
      // the currently logged in user
      const [authUser, setAuthUser] = useState(null);
      // registered postAuth callback
      const [postAuth, setPostAuth] = useState(null);
      // the current user's Profile record
      const [profile, setProfile] = useState({});
      // Global site loading flag
      const [siteLoading, setSiteLoading] = useState(true);

      // const siteData = processCockpitQuery(data);

      // appControl is passed through the AppContext object
      // to descendent components. appControl the
      // master view controller for the application.
      const appControl = {
        //siteData,
        // Displays a temporary app message in a popup message bar
        // (called a snackbar in Material UI)
        displayAppMessage: ({ message, variant, props, delay = 0 }) => {
          // Many functions in appControl use setTimeout to put the
          // action into the message queue. The main purpose
          // is to allow other pending actions to complete
          // before this action is executed. For tihis, the timeout
          // delay can be th default 0, but if you want an actual time delay
          // you can specify that.
          setTimeout(() => {
            if (!appMessage.open) {
              setAppMessage({
                open: true,
                message,
                variant,
                props
              });
            }
          }, delay);
        },

        // Displays a modal dialog with an OK button or other
        // specified action buttons
        displayInfoDialog: ({ title, body, actions, delay = 0 }) => {
          setTimeout(
            () => setInfoDialog({ open: true, title, body, actions }),
            delay
          );
        },

        // Opens a dialog to schedule appointments
        // This uses the scheduleModal in CREATE+user mode
        // If no user is logged in, this will instead give
        // the user the chance to login or register, then
        // will launch the appointment dialog once the
        // login/register is complete
        openMakeAppointmentDialog: (delay = 0) => {
          if (firebase.currentUser() !== null) {
            // user is logged in - open the schedulModal dialog
            setTimeout(
              () =>
                setScheduleModal({
                  open: true,
                  mode: viewMode.CREATE,
                  userMode: true
                }),
              delay
            );
          } else {
            // User not logged in- give them opportunity to
            // sign in or register
            appControl.displayInfoDialog({
              title: 'Make an Appointment',
              body:
                'To make an online appointment, please Sign In or Register.',
              actions: [
                {
                  text: 'Sign In',
                  handler: () =>
                    authControl.showAuthDialog(
                      appControl.openMakeAppointmentDialog
                    )
                },
                {
                  text: 'Register',
                  handler: () =>
                    authControl.showRegisterDialog({
                      postRegister: appControl.openMakeAppointmentDialog
                    })
                }
              ],
              delay
            });
          }
        },

        // Opens the scheduleModal dialog in the mode for managing
        // appointments
        openAppointmentsDialog: ({ delay = 0, admin = false }) => {
          setTimeout(
            () =>
              setScheduleModal({
                open: true,
                mode: viewMode.MASTER,
                userMode: true
              }),
            delay
          );
        },

        // Opens the scheduleModal dialog in calendar mode
        openScheduleDialog: ({ delay = 0, adminMode = false, ...rest }) => {
          setTimeout(
            () =>
              setScheduleModal({
                open: true,
                mode: viewMode.MASTER,
                adminMode,
                ...rest
              }),
            delay
          );
        },

        // opens the userDialog in manage staff mode
        openStaffDialog: ({ delay = 0 }) => {
          setTimeout(
            () =>
              setUsersModal({
                open: true,
                mode: viewMode.LIST,
                title: 'Manage BKPT Staff',
                role: ROLE_STAFF
              }),
            delay
          );
        },

        // opens the usersDialog in manage users mode
        openUsersDialog: ({ delay = 0 }) => {
          setTimeout(
            () =>
              setUsersModal({
                open: true,
                mode: viewMode.LIST,
                title: 'Manage BKPT Users',
                role: ROLE_USER
              }),
            delay
          );
        }
      };

      // AuthControls is passed to descendents through the AuthContext
      // react context object. It provides access to logged in user
      // info and authentication/securityr related functionality
      const authControl = {
        // constants for role definitions
        ROLE_USER,
        ROLE_STAFF,
        ROLE_ADMIN,
        ROLE_ANON,

        // True if currently logged in
        loggedIn: firebase.currentUser() !== null,

        // the current auth user
        user: firebase.currentUser(),

        // the current user Profile
        profile: profile.user,

        // opens the login dialog, with optional postLogin callback
        showAuthDialog: postLogin => {
          setLoginModal({ open: true, postLogin });
        },

        // Sets a post auth callback
        setPostAuthHandler: handler => setPostAuth({ handler }),

        // opens the Register dialog, with optional postRegister callback
        // This uses usersModal in Create mode
        showRegisterDialog: ({ postRegister }) => {
          setUsersModal({
            open: true,
            mode: viewMode.CREATE,
            postRegister,
            title: 'Create Account'
          });
        },

        // Opens the update profile dialog. This uses usersModal in
        // Update mode
        showProfileDialog: ({ entity, title }) => {
          setUsersModal({
            open: true,
            mode: viewMode.UPDATE,
            entity,
            title
          });
        },

        // Login to firebase auth with username and password
        login: (emailAddress, password) => {
          return firebase.doSignInWithEmailAndPassword(emailAddress, password);
        },

        // Login to Firebase using OAuth to Facebook, Twitter, or Google
        facebookLogin: firebase.doFacebookLogin,
        twitterLogin: firebase.doTwitterLogin,
        googleLogin: firebase.doGoogleLogin,

        // Log out
        logout: () => {
          firebase
            .doSignOut()
            .then(() => {
              appControl.displayAppMessage({ message: 'User logged out.' });
            })
            // eslint-disable-next-line no-console
            .catch(error => console.error(error));
        },

        // Reload the user profile
        reloadUserProfile: () => loadProfile(firebase.currentUser().uid),

        // Send password reset email
        doPasswordReset: firebase.doPasswordReset,

        // determine the current user role.
        isAdmin: () => profile.user && profile.user.role === ROLE_ADMIN,
        isStaff: () => profile.user && profile.user.role === ROLE_STAFF,
        isUser: () => profile.user && profile.user.role === ROLE_USER,

        // flag set while site is authenticating
        siteLoading
      };

      // Set up a listener to receive notification of authentication
      // events from Firebase Auth.
      useEffect(() => {
        // Register the listener
        const releaseAuthObserver = firebase.registerAuthHandler(user => {
          setAuthUser(user);
        });
        return () => {
          // When dismounting, release the listener
          if (releaseAuthObserver) {
            releaseAuthObserver();
          }
        };
      }, []); // trigger on component mount & dismount

      // This function loads the user Profile
      // for the current authenticated user
      const loadProfile = () => {
        // initialize the profile state
        setProfile({ authUser });
        setSiteLoading(true);
        // Load the user Profile
        return firebase
          .getUser(authUser.uid)
          .then(user => {
            setSiteLoading(false);
            // eslint-disable-next-line no-console
            console.log('Profile', user);
            // Store the profile
            setProfile({ authUser, user });
            if (postAuth) {
              // invoke the postAuth callback
              const { handler } = postAuth;
              setPostAuth(null);
              handler();
            } else {
              if (!user.val && !usersModal.open) {
                // We end up here is the user has registered but not
                // created their profile. We'll encourage them to
                // create the profile by automatically opening it
                authControl.showProfileDialog({
                  entity: user,
                  title: 'My User Profile'
                });
              }
            }
          })
          .catch(error => {
            setSiteLoading(false);
            // eslint-disable-next-line no-console
            console.error('Didnt load profile');
          });
      };

      // This effect hook loads the user Profile whenever
      // the authenticed user changes
      useEffect(() => {
        if (authUser) {
          // there's a user logged in
          if (authUser !== profile.authUser) {
            loadProfile();
          }
        } else {
          setSiteLoading(false);
          // no user logged in
          if (profile.authUser) {
            // eslint-disable-next-line no-console
            console.log('signed out');
            setProfile({});
            setPostAuth(null);
          }
        }
      }, [authUser, postAuth, profile]);

      return (
        <FirebaseContext.Provider value={firebase}>
          <AuthContext.Provider value={authControl}>
            <AppContext.Provider value={appControl}>
              <CssBaseline />
              <Helmet htmlAttributes={{ lang: 'en' }} title={title}>
                <link
                  rel="preload"
                  as="style"
                  href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700"
                  onLoad="this.rel='stylesheet'"
                />
                <link
                  rel="preload"
                  as="style"
                  href="https://use.fontawesome.com/releases/v5.0.10/css/all.css"
                  onLoad="this.rel='stylesheet'"
                />
                <link rel="preconnect" href="https://maps.gstatic.com" />
                <link rel="preconnect" href="https://maps.googleapis.com" />
                <link rel="preconnect" href="https://cloud.google.com" />
                <link rel="preconnect" href="https://developers.google.com" />
                <link
                  rel="preconnect"
                  href="https://marketingplatform.google.com"
                />
                <link rel="preconnect" href="https://www.google.com" />
              </Helmet>
              <ThemeProvider theme={theme}>
                <>
                  <Layout>
                    <StaticCMSLandingPage />
                  </Layout>
                  {authControl.loggedIn ||
                    (loginModal.open && (
                      <LoginModal
                        {...loginModal}
                        onClose={() => setLoginModal({ open: false })}
                      />
                    ))}
                  {usersModal.open && (
                    <UsersModal
                      {...usersModal}
                      onClose={() => setUsersModal({ open: false })}
                    />
                  )}
                  {scheduleModal.open && (
                    <ScheduleModal
                      {...scheduleModal}
                      onClose={() => setScheduleModal({ open: false })}
                    />
                  )}
                  {appMessage.open && (
                    <SnackbarMessage
                      {...appMessage}
                      onClose={() => setAppMessage({ open: false })}
                    />
                  )}
                  {infoDialog.open && (
                    <ModalInfoDialog
                      {...infoDialog}
                      onClose={() => setInfoDialog({ open: false })}
                    />
                  )}
                </>
              </ThemeProvider>
            </AppContext.Provider>
          </AuthContext.Provider>
        </FirebaseContext.Provider>
      );
    }}
  />
);

export default App;
