import React from 'react';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// material-ui icons
import SkipNext from '@material-ui/icons/SkipNext';
import SkipPrev from '@material-ui/icons/SkipPrevious';
import FilterList from '@material-ui/icons/FilterList';
import DateRange from '@material-ui/icons/DateRange';
// core components
import Button from '../../mui-pro/components/CustomButtons/Button.jsx';
import * as moment from 'moment';
import { util } from 'bkptshared';

import style, { toolbarColor } from './calendarStyle';

const TitleBar = ({ classes, viewControl, calendarModel }) => {
  const { pending } = viewControl;
  const { calendarDate, setCalendarDate, dayMode, setDayMode } = calendarModel;

  const next = () => {
    if (dayMode) {
      setCalendarDate(moment(util.parseDate(calendarDate)).add(1, 'd'));
    } else {
      setCalendarDate(
        moment(util.parseDate(calendarDate))
          .date(1)
          .add(1, 'M')
      );
    }
  };
  const prev = () => {
    if (dayMode) {
      setCalendarDate(moment(util.parseDate(calendarDate)).subtract(1, 'd'));
    } else {
      setCalendarDate(
        moment(util.parseDate(calendarDate))
          .date(1)
          .subtract(1, 'M')
      );
    }
  };

  // console.log('render');
  return (
    <div className={classes.titleBar}>
      <Button
        justIcon
        round
        color="transparent"
        disabled={pending}
        onClick={prev}
        size="sm"
      >
        <SkipPrev style={{ color: toolbarColor }} />
      </Button>
      <div className={classes.controlGroup}>
        <h4 className={classes.title}>
          {dayMode
            ? calendarDate.format('ddd MMM D, Y')
            : calendarDate.format('MMMM Y')}
        </h4>
      </div>
      {(calendarModel.filter || dayMode) && (
        <div>
          {calendarModel.filter && (
            <Button
              justIcon
              round
              color="transparent"
              disabled={pending}
              onClick={() =>
                viewControl.presentView(
                  {
                    ...calendarModel.filter,
                    date: calendarDate
                  },
                  { calendarDate, dayMode }
                )
              }
              size="sm"
            >
              <FilterList style={{ color: 'white' }} />
            </Button>
          )}
          {dayMode && (
            <Button
              justIcon
              round
              color="transparent"
              disabled={pending}
              onClick={() => setDayMode(false)}
              size="sm"
            >
              <DateRange style={{ color: 'white' }} />
            </Button>
          )}
        </div>
      )}
      <Button
        justIcon
        round
        color="transparent"
        disabled={pending}
        onClick={next}
        size="sm"
      >
        <SkipNext style={{ color: 'white' }} />
      </Button>
    </div>
  );
};

TitleBar.propTypes = {
  classes: PropTypes.object.isRequired,
  viewControl: PropTypes.object.isRequired,
  calendarModel: PropTypes.object.isRequired
};

export default withStyles(style)(TitleBar);
