import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
// @material-ui/icons
import Close from '@material-ui/icons/Close';
// core components
import Button from '../../mui-pro/components/CustomButtons/Button';

import modalStyle from '../../mui-pro/assets/jss/material-kit-pro-react/modalStyle';

// eslint-disable-next-line react/display-name
const Transition = forwardRef((props, ref) => (
  <Slide direction="down" {...props} />
));

const modalInfoDialog = ({ classes, onClose, open, title, body, actions }) => {
  const handleAction = action => {
    setTimeout(onClose);
    action.handler();
  };

  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal
      }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby="classic-modal-slide-title"
      aria-describedby="classic-modal-slide-description"
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          simple
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          onClick={onClose}
        >
          {' '}
          <Close className={classes.modalClose} />
        </Button>
        <h4 className={classes.modalTitle}>{title}</h4>
      </DialogTitle>
      <DialogContent
        id="classic-modal-slide-description"
        className={classes.modalBody}
      >
        {body}
      </DialogContent>
      <div style={{ paddingLeft: 15, paddingRight: 15, height: 25 }}>
        <hr />
      </div>
      <DialogActions className={classes.modalFooter}>
        {actions ? (
          actions.map(action => (
            <Button
              color="primary"
              key={action.text}
              onClick={() => handleAction(action)}
            >
              {action.text}
            </Button>
          ))
        ) : (
          <Button color="primary" onClick={onClose}>
            OK
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

modalInfoDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  body: PropTypes.node,
  actions: PropTypes.array
};

export default withStyles(modalStyle)(modalInfoDialog);
