import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';

import CustomInput from '../../mui-pro/components/CustomInput/CustomInput';

import { viewMode, stdView } from '../viewControl';
import EmbeddedForm from '../EmbeddedForm/EmbeddedForm';
import Checkbox from '../Checkbox/Checkbox';
import SelectList from '../SelectList/SelectList';
import { AuthContext } from '../../firebase';
import { util } from 'bkptshared';

// Field IDs - define them as constants to avoid typos
const DATE = 'date';
const TIME = 'time';
const ATHOME = 'atHome';
const NOTES = 'notes';
const PHONENUMBER = 'phoneNumber';
const EMAILADDRESS = 'emailAddress';

const AppointmentView = ({
  adminMode,
  userMode,
  scheduleModel,
  viewControl
}) => {
  const authControl = useContext(AuthContext);
  const { STAFF, LOCATION, PATIENT } = scheduleModel;
  const { view } = viewControl;

  const loadAvailabilityForSchedule = ({ fieldState, setFieldState }) => {
    const time = fieldState(TIME);
    if (!time.loading) {
      const location = fieldState(LOCATION);
      const staffField = fieldState(STAFF);
      const date = fieldState(DATE);
      const setTime = setFieldState(TIME);
      if (!location.value) {
        setTime({ ...time, head: 'Please select a location' });
      } else if (!staffField.value) {
        setTime({ ...time, head: 'Please select a staff' });
      } else if (!date.value) {
        setTime({
          ...time,
          head: 'Please select an appointment date'
        });
      } else {
        setTime({
          ...time,
          head: 'Loading availability...',
          loaded: false,
          loading: true
        });
        scheduleModel
          .appointmentTimes(
            location.value,
            staffField.value,
            util.parseDate(date.value),
            view.entity ? view.entity.key : null
          )
          .then(availability => {
            let times = [];
            times = availability.map(t => {
              const key = parseInt(t);
              return { key, value: util.hourToString12(key) };
            });
            if (times.length > 0) {
              setTime({
                value: time.value,
                list: times,
                loaded: true
              });
            } else {
              setTime({
                head: 'No availability. Please select a different date.',
                loaded: true
              });
            }
          })
          .catch(error => {
            // eslint-disable-next-line no-console
            console.error('Error loading appointment times:', error);
            setTime({ error: true, head: 'Data load error.' });
          });
      }
    }
  };

  const loadPatients = ({
    fieldState,
    setFieldState,
    viewControl: { setError, setPending }
  }) => {
    const patient = fieldState(PATIENT);
    if (!(patient.loaded || patient.loading)) {
      const setPatient = setFieldState(PATIENT);
      setPatient({
        value: patient.value,
        loading: true,
        head: 'Loading patients...'
      });
      setPending(true);
      scheduleModel
        .fetchUsers()
        .then(users => {
          setPending(false);
          if (users.length > 0) {
            const patients = users
              .map(({ key, val: { name, email } }) => ({
                key,
                value: name,
                email
              }))
              .sort((a, b) =>
                a.value < b.value ? -1 : a.value > b.value ? 1 : 0
              );
            setPatient({
              value: patient.value,
              list: patients,
              loaded: true
            });
          } else {
            setPatient({ head: 'No patients found.' });
          }
        })
        .catch(error => {
          setPending(false);
          setError(error);
          setPatient({ error: true, head: 'Data load error.' });
        });
    }
  };

  /////////////////////////////////////////////////
  // Detail Form View

  const handleSubmit = ({ fieldState, view }) => {
    const fields = fieldState.all();
    const appointment = {
      PatientID: fields[PATIENT].value,
      LocationID: fields[LOCATION].value,
      StaffID: fields[STAFF].value,
      DateTime: util.dateTimeStr(fields[DATE].value, fields[TIME].value),
      Notes: fields[NOTES].value || '',
      EmailAddress: fields[EMAILADDRESS].value,
      PhoneNumber: fields[PHONENUMBER].value,
      AtHome: Boolean(fields[ATHOME].value),
      Canceled: view.entity ? Boolean(view.entity.Canceled) : false,
      Confirmed: view.entity ? Boolean(view.entity.Confirmed) : false
    };
    // TODO handle adding update history with timestamps
    if (view.mode === viewMode.CREATE) {
      return scheduleModel.makeAppointment(appointment).then(resp => ({
        confirm: {
          title: 'Appointment Request',
          body: (
            <span>
              <p>
                Your appointment request has been submitted. Our staff will
                contact you by email or phone to confirm the appointment.
              </p>
              <p>Thank you!</p>
            </span>
          )
        }
      }));
    } else {
      return scheduleModel
        .updateAppointment(view.entity.key, appointment)
        .then(resp => ({ close: true }));
    }
  };

  const detailForm = {
    onSubmit: handleSubmit,
    title: ({ view }) =>
      view.mode === viewMode.UPDATE
        ? 'Modify Appointment'
        : 'Schedule New Appointment',
    fields: [
      {
        id: PATIENT,
        omitField: userMode,
        component: SelectList,
        label: 'Patient',
        required: true,
        validate: [{ required: 'Please select a patient.' }],
        initForUpdate: ({ entity }) => ({ value: entity.val.PatientID }),
        initForCreate: userMode ? { value: authControl.user.uid } : undefined
      },
      {
        id: LOCATION,
        component: SelectList,
        label: 'Location',
        required: true,
        validate: [{ required: 'Please select a location.' }],
        initForUpdate: ({ entity }) => ({ value: entity.val.LocationID })
      },
      {
        id: STAFF,
        component: SelectList,
        label: 'Staff',
        required: true,
        validate: [{ required: 'Please selecte a staff.' }],
        initForUpdate: ({ entity }) => ({ value: entity.val.StaffID })
      },
      {
        id: DATE,
        component: SelectList,
        label: 'Appointment Date',
        calendar: true,
        required: true,
        validate: [{ validDate: 'Please selecte a date.' }],
        initForUpdate: ({ entity }) => ({
          value: moment(util.parseDate(entity.val.DateTime))
        })
      },
      {
        id: TIME,
        component: SelectList,
        label: 'Appointment Time',
        required: true,
        validate: [{ required: 'Please select a time.' }],
        initForUpdate: ({ entity }) => ({
          value: util.parseHour(entity.val.DateTime),
          list: [
            {
              key: util.parseHour(entity.val.DateTime),
              value: util.hourToString12(util.parseHour(entity.val.DateTime))
            }
          ]
        })
      },
      {
        id: ATHOME,
        component: Checkbox,
        label: 'Request Home Visit',
        initForUpdate: ({ entity }) => ({ value: Boolean(entity.val.AtHome) })
      },
      {
        id: NOTES,
        component: CustomInput,
        label: 'Requested Service',
        initForUpdate: ({ entity }) => ({ value: entity.val.Notes })
      },
      {
        id: PHONENUMBER,
        component: CustomInput,
        type: 'tel',
        label: 'Phone Number',
        required: true,
        validate: [{ required: 'Please enter a phone number.' }],
        initForCreate: {
          value:
            authControl.profile &&
            authControl.profile.val &&
            authControl.profile.val.phoneNumber
        },
        initForUpdate: ({ entity }) => ({ value: entity.val.PhoneNumber })
      },
      {
        id: EMAILADDRESS,
        component: CustomInput,
        type: 'email',
        label: 'Email Address',
        required: true,
        isEmail: true,
        validate: [{ email: 'Please enter a valid email address.' }],
        initForCreate: userMode ? { value: authControl.user.email } : {},
        initForUpdate: ({ entity }) => ({
          value: entity.val.EmailAddress
        })
      }
    ],
    effects: [
      {
        disabled: userMode,
        run: loadPatients
      },
      {
        run: scheduleModel.fetchAppointmentData
      },
      {
        run: loadAvailabilityForSchedule,
        fieldDeps: [LOCATION, STAFF, DATE]
      },
      {
        disabled: !userMode,
        run: ({ fieldState, setFieldState, view }) => {
          if (view.mode === viewMode.CREATE) {
            const emailAddress = fieldState(EMAILADDRESS);
            const setEmailAddress = setFieldState(EMAILADDRESS);
            const email = authControl.user.email;
            if (!emailAddress.value && email) {
              setEmailAddress({ value: email });
            }
            const phoneNumber = fieldState(PHONENUMBER);
            const setPhoneNumber = setFieldState(PHONENUMBER);
            const phone =
              authControl.profile && authControl.profile.val
                ? authControl.profile.val.phoneNumber
                : null;
            if (!phoneNumber.value && phone) {
              setPhoneNumber({ value: phone });
            }
          }
        },
        valueDeps: [authControl.user, authControl.profile]
      },
      {
        disabled: userMode,
        run: ({ fieldState, setFieldState }) => {
          const patient = fieldState(PATIENT);
          if (patient.value && patient.list) {
            const patientVal = patient.list.find(
              ({ key }) => key === patient.value
            );
            if (patientVal) {
              setFieldState(EMAILADDRESS)({ value: patientVal.email });
            }
          }
        },
        fieldDeps: [PATIENT]
      }
    ]
  };

  // console.log('render');
  return (
    <EmbeddedForm
      id={stdView.DETAIL}
      viewControl={viewControl}
      form={detailForm}
    />
  );
};

AppointmentView.propTypes = {
  viewControl: PropTypes.object,
  adminMode: PropTypes.bool,
  userMode: PropTypes.bool,
  scheduleModel: PropTypes.object.isRequired
};

export default AppointmentView;
