import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';

import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';

const layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        site: datoCmsSiteConfig {
          title: siteTitle
          logo: headerLogo {
            alt
            fixed(height: 70) {
              ...GatsbyDatoCmsFixed_noBase64
            }
          }
        }
      }
    `}
    render={data => {
      const {
        site: { title, logo }
      } = data;
      return (
        <>
          <Header
            title={title}
            fixed
            changeOnScroll={{
              triggerPos: 300,
              full: {
                logo,
                color: 'info',
                height: 70
              },
              compact: {
                logo,
                color: 'info',
                height: 70
              }
            }}
          />
          <main>{children}</main>
          <Footer />
        </>
      );
    }}
  />
);

layout.propTypes = {
  children: PropTypes.element.isRequired
};

export default layout;
