import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';

import Month from './Month';
import Day from './Day';
import TitleBar from './TitleBar';

import style from './calendarStyle';

const Calendar = ({ classes, calendarModel, viewControl }) => {
  const { dayMode } = calendarModel;

  // console.log('render');
  return (
    <div className={classes.calendar}>
      <TitleBar viewControl={viewControl} calendarModel={calendarModel} />
      {dayMode ? (
        <Day viewControl={viewControl} calendarModel={calendarModel} />
      ) : (
        <Month viewControl={viewControl} calendarModel={calendarModel} />
      )}
    </div>
  );
};

Calendar.propTypes = {
  classes: PropTypes.object.isRequired,
  viewControl: PropTypes.object.isRequired,
  calendarModel: PropTypes.object.isRequired
};

export default withStyles(style)(Calendar);
