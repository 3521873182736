import * as fb from '@firebase/app';
import 'firebase/auth';
import 'firebase/functions';

// Configure Firebase
const config = {
  apiKey: 'AIzaSyAoYSB-SAXdwblUSXfhAQXSBXEb_URBwCs',
  authDomain: 'bkpt-d265a.firebaseapp.com',
  databaseURL: 'https://bkpt-d265a.firebaseio.com',
  projectId: 'bkpt-d265a',
  storageBucket: 'bkpt-d265a.appspot.com',
  messagingSenderId: '366740657160'
};

// This prevents Firebase from initializing during
// static rendering
let firebase, auth, functions;
if (typeof window !== 'undefined') {
  firebase = fb.firebase;
  firebase.initializeApp(config);
  auth = firebase.auth();
  functions = firebase.functions();
  // Initialize firebase auth to use local persistence
  auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).catch(error => {
    // eslint-disable-next-line no-console
    console.error(error.message);
  });
} else {
  // eslint-disable-next-line no-console
  console.log('Skipping firebase, not in browser.');
}

// The xxxHookResponse and xxxHookError functions are
// used to standardize success and error responses from the
// various Firebase methods. All firebase promises
// pass through these (.then and .catch) prior to
// returning the promise to the caller

// Hook all firebase calls here
const firebaseHookResponse = response => {
  if (!response.success) {
    // eslint-disable-next-line no-console
    console.error('Unexpected response: missing success flag');
  }
  // eslint-disable-next-line no-console
  //console.log('Firebase Response:', response);
  return response.result;
};

// hook all firebase errors
// Refer to the API documentation in the Firebase functions
// for more detailed documentation of each API
const firebaseHookError = error => {
  if (!error || !error.message) {
    // eslint-disable-next-line no-console
    console.log('Unexpected error response');
  }
  // eslint-disable-next-line no-console
  console.error(error);
  throw error;
};

// hook calls to firebase functions
const functionResult = (response, from) => {
  if (!response.data) {
    // eslint-disable-next-line no-console
    console.error('Unexpected response: missing data attribute');
  }
  return firebaseHookResponse({ ...response.data, api: 'function', from });
};

// hook errors from firebase functions
const functionError = (error, from) => {
  error.from = from;
  error.api = 'function';
  firebaseHookError(error);
};

// hook calls to firebsae auth
const authResult = (result, from) =>
  firebaseHookResponse({ success: true, ...result, api: 'auth', from });

// hook errors from firebase auth
const authError = (error, from) => {
  error.from = from;
  error.api = 'auth';
  firebaseHookError(error);
};

// firebaseInterface puts all the firebase
// access methods in one place
const firebaseInterface = {
  // Sign in using email/password
  doSignInWithEmailAndPassword: (email, password) =>
    auth &&
    auth
      .signInWithEmailAndPassword(email, password)
      .then(result => authResult({ result }, 'doSignInWithEmailAndPassword'))
      .catch(error => authError(error, 'doSignInWithEmailAndPassword')),

  // sign out
  doSignOut: () =>
    auth &&
    auth
      .signOut()
      .then(() => authResult({}, 'doSignOut'))
      .catch(error => authError(error, 'doSignOut')),

  // login with Facebook auth
  doFacebookLogin: () => {
    const provider = firebase
      ? firebase.auth.FacebookAuthProvider()
      : undefined;
    // todo integrate this with your I18n
    auth && auth.useDeviceLanguage();
    return (
      auth &&
      auth
        .signInWithPopup(provider)
        .then(result => authResult({ result }, 'doFacebookLogin'))
        .catch(error => authError(error, 'doFacebookLogin'))
    );
  },

  // sign in with twitter auth
  doTwitterLogin: () => {
    const provider = firebase
      ? new firebase.auth.TwitterAuthProvider()
      : undefined;
    // todo integrate this with your I18n
    auth && auth.useDeviceLanguage();
    return (
      auth &&
      auth
        .signInWithPopup(provider)
        .then(result => authResult({ result }, 'doTwitterLogin'))
        .catch(error => authError(error, 'doTwitterLogin'))
    );
  },

  // sign in with Google auth
  doGoogleLogin: () => {
    const provider = firebase
      ? new firebase.auth.GoogleAuthProvider()
      : undefined;
    // todo integrate this with your I18n
    auth && auth.useDeviceLanguage();
    return (
      auth &&
      auth
        .signInWithPopup(provider)
        .then(result => authResult({ result }, 'doGoogleLogin'))
        .catch(error => authError(error, 'doGoogleLogin'))
    );
  },

  // todo implement password reset
  doPasswordReset: email =>
    auth &&
    auth
      .sendPasswordResetEmail(email)
      .then(result => authResult({ result }, 'doPasswordReset'))
      .catch(error => authError(error, 'doPasswordReset')),

  // submit a 'Contact Us' message
  submitContactMessage: message =>
    functions &&
    functions
      .httpsCallable('contactUs')(message)
      .then(result => functionResult(result, 'submitContactMessage'))
      .catch(error => functionError(error, 'submitContactMessage')),

  // Fetch the specified user
  getUser: uid =>
    functions &&
    functions
      .httpsCallable('getUser')({ uid })
      .then(result => functionResult(result, 'getUser'))
      .catch(error => functionError(error, 'getUser')),

  // Fetch appointment times for a specified location, staff and date
  appointmentTimes: (LocationID, StaffID, Date, excludeAppointment) =>
    functions &&
    functions
      .httpsCallable('appointmentTimes')({
        LocationID: LocationID,
        StaffID,
        Date,
        excludeAppointment
      })
      .then(result => functionResult(result, 'appointmentTimes'))
      .catch(error => functionError(error, 'appointmentTimes')),

  // Create a new appointment
  makeAppointment: appointment =>
    functions &&
    functions
      .httpsCallable('makeAppointment')(appointment)
      .then(result => functionResult(result, 'makeAppointment'))
      .catch(error => functionError(error, 'makeAppointment')),

  // Convert ROLE_USER to ROLE_STAFF
  makeStaffUser: uid =>
    functions &&
    functions
      .httpsCallable('makeStaffUser')({ uid })
      .then(result => functionResult(result, 'makeStaffUser'))
      .catch(error => functionError(error, 'makeStaffUser')),

  // Make a staff member a "Therapist" role
  setTherapistRole: (uid, therapist) =>
    functions &&
    functions
      .httpsCallable('setTherapistRole')({ uid, therapist })
      .then(result => functionResult(result, 'setTherapistRole'))
      .catch(error => functionError(error, 'setTherapistRole')),

  // Promote ROLE_STAFF to ROLE_ADMIN
  makeAdminUser: uid =>
    functions &&
    functions
      .httpsCallable('makeAdminUser')({ uid })
      .then(result => functionResult(result, 'makeAdminUser'))
      .catch(error => functionError(error, 'makeAdminUser')),

  // Delete a user and associated data
  deleteUser: (uid, localDate) =>
    functions &&
    functions
      .httpsCallable('deleteUser')({ uid, localDate })
      .then(result => functionResult(result, 'deleteUser'))
      .catch(error => functionError(error, 'deleteUser')),

  // Retrieve reference data for creating appointments
  appointmentData: (staff = true, locations = true) =>
    functions &&
    functions
      .httpsCallable('appointmentData')({ staff, locations })
      .then(result => functionResult(result, 'appointmentData'))
      .catch(error => functionError(error, 'appointmentData')),

  // Register a new user auth record
  registerNewUser: (email, password) =>
    auth &&
    auth
      .createUserWithEmailAndPassword(email, password)
      .then(result => authResult({ result }, 'registerNewUser'))
      .catch(error => authError(error, 'registerNewUser')),

  // create a new user profile
  createProfile: profile =>
    functions &&
    functions
      .httpsCallable('registerNewUser')({ val: profile })
      .then(result => functionResult(result, 'createProfile'))
      .catch(error => functionError(error, 'createProfile')),

  // update a user profile
  updateProfile: (uid, profile) =>
    functions &&
    functions
      .httpsCallable('updateProfile')({ key: uid, val: profile })
      .then(result => functionResult(result, 'updateProfile'))
      .catch(error => functionError(error, 'updateProfile')),

  // Register a firebase auth event listener
  registerAuthHandler: callback => auth && auth.onAuthStateChanged(callback),

  // Get the current firebase user
  currentUser: () => auth && auth.currentUser,

  // Retrieve appointments for current user
  myAppointments: fromDate =>
    functions &&
    functions
      .httpsCallable('myAppointments')({ fromDate })
      .then(result => functionResult(result, 'myAppointments'))
      .catch(error => functionError(error, 'myAppointments')),

  // Update specified appointment
  updateAppointment: (key, appointment) =>
    functions &&
    functions
      .httpsCallable('updateAppointment')({ key, val: appointment })
      .then(result => functionResult(result, 'updateAppointment'))
      .catch(error => functionError(error, 'updateAppointment')),

  // load users for role
  loadUsers: (role, getProfile = true) =>
    functions &&
    functions
      .httpsCallable('loadUsers')({ role, getProfile })
      .then(result => functionResult(result, 'loadUsers'))
      .catch(error => functionError(error, 'loadUsers')),

  // load appointments in date range
  loadAppointments: (dateFrom, dateTo, allStaff) =>
    functions &&
    functions
      .httpsCallable('loadAppointments')({ dateFrom, dateTo, allStaff })
      .then(result => functionResult(result, 'loadAppointments'))
      .catch(error => functionError(error, 'loadAppointments')),

  // load availability for one or more staff
  loadAvailability: allStaff =>
    functions &&
    functions
      .httpsCallable('loadAvailability')({ allStaff })
      .then(result => functionResult(result, 'loadAvailability'))
      .catch(error => functionError(error, 'loadAvailability')),

  // delete availability record
  deleteAvailability: key =>
    functions &&
    functions
      .httpsCallable('deleteAvailability')({ key })
      .then(result => functionResult(result, 'deleteAvailability'))
      .catch(error => functionError(error, 'deleteAvailability')),

  // create new availability record
  createAvailability: availability =>
    functions &&
    functions
      .httpsCallable('createAvailability')({ val: availability })
      .then(result => functionResult(result, 'createAvailability'))
      .catch(error => functionError(error, 'createAvailability')),

  // Update an availability record
  updateAvailability: (key, availability) =>
    functions &&
    functions
      .httpsCallable('updateAvailability')({ key, val: availability })
      .then(result => functionResult(result, 'updateAvailability'))
      .catch(error => functionError(error, 'updateAvailability')),

  // load display data for list of users
  loadDisplayData: users =>
    functions &&
    functions
      .httpsCallable('loadDisplayData')({ users })
      .then(result => functionResult(result, 'loadDisplayData'))
      .catch(error => functionError(error, 'loadDisplayData')),

  // Confirm specified appointment
  confirmAppointment: key =>
    functions &&
    functions
      .httpsCallable('confirmAppointment')({ key })
      .then(result => functionResult(result, 'confirmAppointment'))
      .catch(error => functionError(error, 'confirmAppointment')),

  // cancel specified appointment
  cancelAppointment: key =>
    functions &&
    functions
      .httpsCallable('cancelAppointment')({ key })
      .then(result => functionResult(result, 'cancelAppointment'))
      .catch(error => functionError(error, 'cancelAppointment')),

  // Delete specified appointment
  deleteAppointment: key =>
    functions &&
    functions
      .httpsCallable('deleteAppointment')({ key })
      .then(result => functionResult(result, 'deleteAppointment'))
      .catch(error => functionError(error, 'deleteAppointment'))
};

export default firebaseInterface;
