import React, { useState, useContext, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import InputAdornment from '@material-ui/core/InputAdornment';
import Close from '@material-ui/icons/Close';
import Mail from '@material-ui/icons/Mail';
import Lock from '@material-ui/icons/Lock';

import Button from '../../mui-pro/components/CustomButtons/Button';
import Card from '../../mui-pro/components/Card/Card';
import CardHeader from '../../mui-pro/components/Card/CardHeader';
import CardBody from '../../mui-pro/components/Card/CardBody';
import CustomInput from '../../mui-pro/components/CustomInput/CustomInput';
import Danger from '../../mui-pro/components/Typography/Danger';

import loginModalStyles from './loginModalStyles';
import { AuthContext } from '../../firebase';
import { AppContext } from '../../appContext';

// eslint-disable-next-line react/display-name
const Transition = forwardRef((props, ref) => (
  <Slide direction="down" {...props} />
));

const loginModal = ({ classes, open, postLogin, onClose }) => {
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [pending, setPending] = useState(false);
  const [error, setError] = useState(null);
  const [resetPwMode, setResetPwMode] = useState(false);
  const authControl = useContext(AuthContext);
  const appControl = useContext(AppContext);

  const initLogin = () => {
    setEmailAddress('');
    setPassword('');
    setPending(false);
    setError(false);
  };

  const onEmailAddressChange = event => {
    setEmailAddress(event.target.value);
    // console.log('email address', event.target.value);
  };

  const onPasswordChange = event => {
    setPassword(event.target.value);
    // console.log('password', event.target.value);
  };

  const handleClose = () => {
    initLogin();
    setTimeout(onClose);
  };

  const validateForm = () => {
    return true;
  };

  const handleResetPassword = () => {
    if (validateForm()) {
      setPending(true);
      setError(null);
      authControl
        .doPasswordReset(emailAddress)
        .then(() => {
          setPending(false);
          handleClose();
          appControl.displayInfoDialog({
            title: 'Password Reset',
            body: (
              <span>
                <p>
                  You will recieve an email from Baduria Biokinetics with a link
                  to reset your password..
                </p>
                <p>Thank you!</p>
              </span>
            )
          });
        })
        .catch(error => {
          authControl.setPostAuthHandler(null);
          setPending(false);
          setError(error.message);
        });
    }
  };

  const handleLogin = loginProvider => {
    if (validateForm()) {
      setPending(true);
      setError(null);
      if (postLogin) {
        authControl.setPostAuthHandler(postLogin);
      }
      loginProvider(emailAddress, password)
        .then(() => {
          setPending(false);
          handleClose();
          if (!postLogin) {
            appControl.displayAppMessage({
              message: 'You are signed in.',
              delay: 500
            });
          }
        })
        .catch(error => {
          authControl.setPostAuthHandler(null);
          setPending(false);
          setError(error.message);
        });
    }
  };

  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: `${classes.modal} ${classes.modalLogin}`
      }}
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
    >
      <Card plain className={classes.modalLoginCard}>
        <DialogTitle
          id="login-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <CardHeader
            plain
            color="primary"
            className={`${classes.textCenter} ${classes.cardLoginHeader}`}
          >
            <Button
              simple
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              onClick={handleClose}
              disabled={pending}
              style={{
                padding: 'unset',
                marginRight: -7,
                marginTop: -3
              }}
            >
              <Close className={classes.modalClose} />
            </Button>
            <h5 className={classes.cardTitleWhite}>
              {resetPwMode ? 'Reset Password' : 'Sign In'}
            </h5>
            {!resetPwMode && (
              <div className={classes.socialLine}>
                <Button
                  justIcon
                  link
                  className={classes.socialLineButton}
                  onClick={() => handleLogin(authControl.facebookLogin)}
                  disabled={pending}
                >
                  <i className="fab fa-facebook-square" />
                </Button>
                <Button
                  justIcon
                  link
                  className={classes.socialLineButton}
                  onClick={() => handleLogin(authControl.twitterLogin)}
                  disabled={pending}
                >
                  <i className="fab fa-twitter" />
                </Button>
                <Button
                  justIcon
                  link
                  className={classes.socialLineButton}
                  onClick={() => handleLogin(authControl.googleLogin)}
                  disabled={pending}
                >
                  <i className="fab fa-google-plus-g" />
                </Button>
              </div>
            )}
          </CardHeader>
        </DialogTitle>
        <DialogContent
          id="login-modal-slide-description"
          className={classes.modalBody}
        >
          <form>
            <CardBody className={classes.cardLoginBody}>
              <h5 className={`${classes.description} ${classes.textCenter}`}>
                {resetPwMode
                  ? "Enter your email address, and we'll send a link to reset your password."
                  : 'Login With Email'}
              </h5>
              <CustomInput
                id="login-modal-email"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: 'login',
                  type: 'email',
                  required: true,
                  autoComplete: 'email',
                  value: emailAddress,
                  onChange: onEmailAddressChange,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Mail className={classes.icon} />
                    </InputAdornment>
                  ),
                  placeholder: 'Email…'
                }}
              />
              {!resetPwMode && (
                <CustomInput
                  id="login-modal-pass"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    name: 'password',
                    type: 'password',
                    required: true,
                    autoComplete: 'on',
                    value: password,
                    onChange: onPasswordChange,
                    startAdornment: (
                      <InputAdornment position="start">
                        <Lock className={classes.icon} />
                      </InputAdornment>
                    ),
                    placeholder: 'Password…'
                  }}
                />
              )}
            </CardBody>
          </form>
          {error && (
            <div className={classes.textCenter}>
              <Danger>{error}</Danger>
            </div>
          )}
          <div className={`${classes.wrapper} ${classes.textCenter}`}>
            <Button
              variant="contained"
              color="primary"
              simple
              size="sm"
              onClick={() => {
                setError(null);
                setResetPwMode(prev => !prev);
              }}
              disabled={pending}
            >
              {resetPwMode ? 'Return to Sign In' : 'Forgot Password?'}
            </Button>
          </div>
        </DialogContent>
        <DialogActions
          className={`${classes.modalFooter} ${classes.justifyContentCenter}`}
        >
          <div className={classes.wrapper}>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                resetPwMode
                  ? handleResetPassword()
                  : handleLogin(authControl.login)
              }
              disabled={pending}
            >
              {resetPwMode ? 'Reset Password' : 'Sign In'}
            </Button>
            {pending && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </Card>
    </Dialog>
  );
};

loginModal.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  postLogin: PropTypes.func,
  onClose: PropTypes.func.isRequired
};

export default withStyles(loginModalStyles)(loginModal);
