import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import Card from '../../mui-pro/components/Card/Card';
import CardBody from '../../mui-pro/components/Card/CardBody.jsx';
import CardAvatar from '../../mui-pro/components/Card/CardAvatar.jsx';

import staffCardStyle from './staffCardStyle';

const StaffCard = ({
  classes,
  staff,
  showFeatured,
  injectClasses,
  injectStyles
}) => {
  const profileClasses = classNames([
    classes.profile,
    showFeatured && classes.featuredProfile,
    ...injectClasses
  ]);
  const styles = injectStyles ? { style: injectStyles } : {};
  return staff
    .filter(({ featuredProfile }) => featuredProfile === Boolean(showFeatured))
    .map(({ staffid, avatar, name, title, subtitle, biography }) => (
      <Card className={profileClasses} profile {...styles} key={staffid}>
        <CardAvatar profile>
          <img alt={avatar.alt} src={avatar.url} />
        </CardAvatar>
        <CardBody>
          <h4 className={classes.profileName}>{name}</h4>
          <h6 className={classes.profileTitle}>
            {title}
            {showFeatured && (
              <>
                <br />
                {subtitle}
              </>
            )}
          </h6>
          {showFeatured && (
            <div
              dangerouslySetInnerHTML={{
                __html: biography
              }}
              style={{ fontWeight: 400 }}
            />
          )}
        </CardBody>
      </Card>
    ));
};

// eslint-disable-next-line react/display-name
StaffCard.createFromCms = cmsContext => {
  const {
    key,
    styles,
    localStyles,
    component: { showFeatured },
    data: { staff }
  } = cmsContext;
  const props = {
    staff,
    showFeatured,
    injectClasses: styles,
    injectStyles: localStyles
  };
  const StaffCardCms = withStyles(staffCardStyle)(StaffCard);
  return <StaffCardCms {...props} key={key} />;
};

StaffCard.propTypes = {
  classes: PropTypes.object.isRequired,
  staff: PropTypes.array.isRequired,
  showFeatured: PropTypes.bool,
  injectClasses: PropTypes.array,
  injectStyles: PropTypes.object
};

export default withStyles(staffCardStyle)(StaffCard);
