import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Mail from '@material-ui/icons/Mail';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import GridContainer from '../../mui-pro/components/Grid/GridContainer.jsx';
import GridItem from '../../mui-pro/components/Grid/GridItem.jsx';
import CustomInput from '../../mui-pro/components/CustomInput/CustomInput.jsx';
import Button from '../../mui-pro/components/CustomButtons/Button';
import Card from '../../mui-pro/components/Card/Card.jsx';
import CardBody from '../../mui-pro/components/Card/CardBody.jsx';

import { AppContext } from '../../appContext';

import subscribeFormStyle from './subscribeFormStyle';

const SubscribeForm = ({ classes, mailchimp }) => {
  const appControl = useContext(AppContext);
  const handleSubmit = event => {
    event.preventDefault();
    const email = event.currentTarget.elements.subscribeEmail.value;
    addToMailchimp(email)
      .then(data => {
        appControl.displayInfoDialog({
          title: 'Email Subscribe',
          body: (
            <span>
              <h3>{data.result}</h3>
              <p>{data.msg}</p>
            </span>
          )
        });
      })
      .catch(() => {
        appControl.displayAppMessage({
          message: 'Unable to subscribe at this time',
          variant: 'danger'
        });
      });
  };
  return (
    <Card raised className={classes.card}>
      <CardBody className={classes.cardBody}>
        <form onSubmit={handleSubmit}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={8} lg={8}>
              <CustomInput
                id="subscribeEmail"
                formControlProps={{
                  fullWidth: true,
                  className: classes.cardForm
                }}
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Mail />
                    </InputAdornment>
                  ),
                  placeholder: 'Your Email...',
                  name: 'subscribeEmail'
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={4} lg={4}>
              <Button
                color="primary"
                block
                className={classes.subscribeButton}
                type="submit"
              >
                subscribe
              </Button>
            </GridItem>
          </GridContainer>
        </form>
      </CardBody>
    </Card>
  );
};

// eslint-disable-next-line react/display-name
SubscribeForm.createFromCms = cmsContext => {
  const {
    key,
    component: { mailchimp }
  } = cmsContext;
  const props = {
    mailchimp
  };
  const SubscribeFormCms = withStyles(subscribeFormStyle)(SubscribeForm);
  return <SubscribeFormCms {...props} key={key} />;
};

SubscribeForm.propTypes = {
  classes: PropTypes.object.isRequired,
  mailchimp: PropTypes.string
};

export default withStyles(subscribeFormStyle)(SubscribeForm);
