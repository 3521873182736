import { description } from '../../mui-pro/assets/jss/material-kit-pro-react.jsx';

const embeddedFormStyles = theme => ({
  formHeader: {
    ...description,
    marginBottom: 0
  },
  buttonProgress: {
    color: '#9c27b0',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  textCenter: {
    textAlign: 'center'
  },
  fieldContainer: {
    height: 74
  },
  checkboxContainer: {
    height: 48
  },
  fieldAlert: {
    fontSize: 10.5,
    color: '#f44336',
    position: 'relative',
    top: -20,
    fontWeight: 400
  }
});

export default embeddedFormStyles;
