import {
  description,
  infoColor
} from '../../mui-pro/assets/jss/material-kit-pro-react.jsx';
import sectionStyles from '../../mui-pro/assets/jss/material-kit-pro-react/views/componentsSections/contentAreas.jsx';

const dataTableStyle = {
  ...sectionStyles,
  heading: {
    ...description,
    display: 'inline',
    padding: 0,
    margin: 0,
    color: 'white'
  },
  textCenter: {
    textAlign: 'center'
  },
  noTableMessage: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    height: '100%'
  },
  controlRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: infoColor[0],
    borderRadius: 3,
    marginBottom: 5
  },
  controlGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
};

export default dataTableStyle;
