import React, { useEffect, useContext } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import withStyles from '@material-ui/core/styles/withStyles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Lock from '@material-ui/icons/Lock';
import LockOpen from '@material-ui/icons/LockOpen';
import Settings from '@material-ui/icons/Settings';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ContactMail from '@material-ui/icons/ContactMail';
import CalendarToday from '@material-ui/icons/CalendarToday';
import DateRange from '@material-ui/icons/DateRange';
import Group from '@material-ui/icons/Group';
import Face from '@material-ui/icons/Face';
import { ScrollTo } from 'react-scroll-to';

import { AppContext } from '../../appContext';
import CustomDropdown from '../../mui-pro/components/CustomDropdown/CustomDropdown';
import Button from '../../mui-pro/components/CustomButtons/Button';

import { AuthContext } from '../../firebase';

import headerLinksStyle from './headerLinksStyle';

// HeaderLinks contains the links that are displayed in the site header
// Using mobile-first responsive CSS media queries, the links are displayed
// either in the menu bar, or in a side-drawer menu on smaller devices

const headerLinks = ({
  classes,
  dropdownHoverColor,
  drawer: { mobileOpen, setMobileOpen }
}) => (
  <StaticQuery
    query={graphql`
      query {
        site: datoCmsSiteConfig {
          mainMenu {
            links {
              text: title
              section {
                id: originalId
              }
            }
          }
        }
      }
    `}
    render={({
      site: {
        mainMenu: { links }
      }
    }) => {
      const authControl = useContext(AuthContext);
      const appControl = useContext(AppContext);

      const sections = links.map(({ text, section: { id } }) => ({
        id,
        text
      }));

      // Determine which is the current section based on scroll position
      const findTopSection = () => {
        let topSection = null;
        const pageY = window.pageYOffset;
        let closest = Number.POSITIVE_INFINITY;
        for (const section of sections) {
          if (section.sectionRef) {
            const sectionY = section.sectionRef.offsetTop;
            if (Math.abs(sectionY - pageY) < closest) {
              closest = Math.abs(sectionY - pageY);
              topSection = section;
            }
          }
        }
        return topSection;
      };

      // track the currently active section
      // this doesn't use reat state- this scroll state
      // is mangaed outside of react
      let activeSection = null;

      // function to update the active link as the page is scrolled
      const updateActiveLink = () => {
        const topSection = findTopSection();
        if (topSection !== activeSection) {
          if (activeSection && activeSection.menuRef) {
            activeSection.menuRef.style.borderBottomColor =
              'rgba(255,255,255,0)';
          }
          if (topSection && topSection.menuRef) {
            topSection.menuRef.style.borderBottomColor =
              'rgba(255,255,255,0.6)';
          }
          activeSection = topSection;
        }
      };

      // update the active header link based on the current scroll position
      useEffect(() => {
        for (const section of sections) {
          section.sectionRef = document.getElementById(section.id);
          section.menuRef = document.getElementById(`nav.${section.id}`);
        }
        updateActiveLink();
      });

      // mouse listener to handle smooth scrolling
      useEffect(() => {
        if (window.innerWidth >= 768) {
          window.addEventListener('scroll', updateActiveLink);
          return () => {
            window.removeEventListener('scroll', updateActiveLink);
          };
        }
      }, []);

      // the menu header for authenticated users
      let authHeader = undefined;
      if (authControl.loggedIn) {
        authHeader = (
          <h6 style={{ fontWeight: 700 }}>
            <AccountCircle className={classes.dropdownIcons} />
            {authControl.profile &&
            authControl.profile.val &&
            authControl.profile.val.name
              ? authControl.profile.val.name
              : authControl.profile &&
                authControl.profile.auth &&
                authControl.profile.auth.displayName
              ? authControl.profile.auth.displayName
              : 'User Account'}
            <hr />
          </h6>
        );
      }

      // menu for unauthenticated ROLE_ANON users
      const anonAccountMenuModel = [
        {
          id: 'login',
          handleClick: () => authControl.showAuthDialog(),
          text: 'Sign In',
          icon: LockOpen
        },
        {
          id: 'register',
          handleClick: () => authControl.showRegisterDialog({}),
          text: 'Register',
          icon: LockOpen
        }
      ];

      // profile menu option
      const profileItem = {
        id: 'profile',
        handleClick: () =>
          authControl.showProfileDialog({
            entity: authControl.profile,
            title: 'My User Profile'
          }),
        text: 'My User Profile',
        icon: ContactMail
      };

      // logout menu item
      const logoutItem = {
        id: 'logout',
        handleClick: () => authControl.logout(),
        text: 'Sign Out',
        icon: Lock
      };

      // Account menu for authenticated ROLE_USER users
      const userAccountMenuModel = [
        profileItem,
        {
          id: 'appointments',
          handleClick: () => appControl.openAppointmentsDialog({}),
          text: 'My Appointments',
          icon: CalendarToday
        },
        logoutItem
      ];

      // Account menu for ROLE_STAFF users
      const staffAccountMenuModel = [
        profileItem,
        {
          id: 'myschedule',
          handleClick: () => appControl.openScheduleDialog({}),
          text: 'My Schedule',
          icon: DateRange
        },
        logoutItem
      ];

      // Admin menu for ROLE_ADMIN users
      const adminMenuModel = [
        {
          id: 'adminsched',
          handleClick: () => appControl.openScheduleDialog({ adminMode: true }),
          text: 'Manage Schedules',
          icon: DateRange
        },
        {
          id: 'adminstaff',
          handleClick: () => appControl.openStaffDialog({}),
          text: 'Manage Staff',
          icon: Group
        },
        {
          id: 'adminusers',
          handleClick: () => appControl.openUsersDialog({}),
          text: 'Manage Users',
          icon: Face
        }
      ];

      // determine which account menu to use based
      // on the current user's role
      const accountMenuModel = authControl.loggedIn
        ? authControl.isStaff() || authControl.isAdmin()
          ? staffAccountMenuModel
          : userAccountMenuModel
        : anonAccountMenuModel;

      // helper function to render a menu
      const renderMenu = menuModel => {
        const menu = [];
        menuModel.forEach(
          item =>
            !item.omit &&
            menu.push(
              <Link
                key={item.id}
                to={item.to || ''}
                onClick={e => {
                  e.preventDefault();
                  mobileOpen && setMobileOpen(false);
                  item.handleClick && item.handleClick();
                }}
                className={classes.dropdownLink}
              >
                <item.icon className={classes.dropdownIcons} />
                {item.text}
              </Link>
            )
        );
        return menu;
      };

      // header for admin dropdown
      const adminHeader = (
        <h6 style={{ fontWeight: 700 }}>
          <Settings className={classes.dropdownIcons} />
          Admin Menu
          <hr />
        </h6>
      );

      // Render the header links
      return (
        <>
          <List
            className={`${classes.list} ${classes.mlAuto} ${classes.toolbarList}`}
          >
            {authControl.isAdmin() && (
              <ListItem className={classes.listItem}>
                <CustomDropdown
                  noLiPadding
                  navDropdown
                  hoverColor={dropdownHoverColor}
                  buttonProps={{
                    className: `${classes.navLink} ${classes.headerButtons}`,
                    color: 'transparent',
                    title: 'Admin Menu',
                    disabled: authControl.siteLoading
                  }}
                  buttonIcon={Settings}
                  dropPlacement="bottom-end"
                  dropdownList={renderMenu(adminMenuModel)}
                  dropdownHeader={adminHeader}
                />
              </ListItem>
            )}
            <ListItem className={classes.listItem}>
              <CustomDropdown
                noLiPadding
                navDropdown
                hoverColor={dropdownHoverColor}
                buttonProps={{
                  className: `${classes.navLink} ${classes.headerButtons}`,
                  color: 'transparent',
                  title: 'Sign in or register',
                  disabled: authControl.siteLoading
                }}
                buttonIcon={AccountCircle}
                dropPlacement="bottom-end"
                dropdownList={renderMenu(accountMenuModel)}
                dropdownHeader={authHeader}
              />
            </ListItem>
          </List>
          <List
            className={`${classes.list} ${classes.mlAuto} ${classes.menuList}`}
          >
            {sections.map(section => (
              <ListItem className={classes.listItem} key={section.id}>
                <ScrollTo>
                  {({ scrollTo }) => (
                    <Button
                      id={`nav.${section.id}`}
                      className={`${classes.navLink} ${classes.headerNavButtons}`}
                      onClick={e => {
                        e.preventDefault();
                        mobileOpen && setMobileOpen(false);
                        const scrollTarget = document.getElementById(
                          section.id
                        );
                        if (scrollTarget) {
                          scrollTo({ y: scrollTarget.offsetTop, smooth: true });
                          //TODO in gatsby this is defeating the smooth
                          //scrolll, but is needed to set the link in the
                          //address bar
                          //routing.navigate(`#${section.id}`)
                        }
                      }}
                      color="transparent"
                      style={{ borderRadius: 'unset' }}
                    >
                      {section.text}
                    </Button>
                  )}
                </ScrollTo>
              </ListItem>
            ))}
          </List>
        </>
      );
    }}
  />
);

headerLinks.defaultProps = {
  hoverColor: 'primary'
};

headerLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  dropdownHoverColor: PropTypes.oneOf([
    'dark',
    'primary',
    'info',
    'success',
    'warning',
    'danger',
    'rose'
  ]),
  drawer: PropTypes.object.isRequired
};

export default withStyles(headerLinksStyle)(headerLinks);
