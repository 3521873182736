const moment = require('moment');

export const resolveAvailability = (
  availability,
  forDate,
  forLocationId,
  forStaffId,
  appointments,
  excludeAppointment,
  includeUnavailability
) => {
  const resolved = {};
  // process hours
  const recordAvailability = avail => {
    const locationId = avail.LocationID;
    const staffId = avail.StaffID;
    const unavailable = avail.Unavailable;
    const from = parseInt(avail.TimeStart);
    const to = parseInt(avail.TimeEnd);
    let hours;
    if (resolved[locationId] && resolved[locationId][staffId]) {
      hours = resolved[locationId][staffId];
    } else {
      hours = [new Array(24).fill(null), new Array(24).fill(null)];
    }
    for (let hour = from; hour < to; hour += 1) {
      if (unavailable) {
        // marking hours that are unavailable
        hours[0][hour] = false;
        hours[1][hour] = true;
      } else {
        // marking hours that are available
        if (hours[0][hour] === null) {
          hours[0][hour] = true;
        }
      }
    }
    if (!resolved[locationId]) {
      resolved[locationId] = {};
    }
    resolved[locationId][staffId] = hours;
  };
  // process availability entries
  availability.forEach(availRec => {
    const avail = availRec.val;
    if (
      (!forLocationId || avail.LocationID === forLocationId) &&
      (!forStaffId || avail.StaffID === forStaffId) &&
      forDate >= avail.DateStart &&
      forDate <= avail.DateEnd
    ) {
      if (avail.Recurring) {
        // this is a recurring availablity
        if (avail.Days[moment(forDate).day()] === true) {
          recordAvailability(avail);
        }
      } else {
        // this is a one time availability
        recordAvailability(avail);
      }
    }
  });
  // process appointments
  if (appointments) {
    appointments.forEach(appt => {
      if (appt.key !== excludeAppointment) {
        const appointment = appt.val;
        const staffId = appointment.StaffID;
        const locationId = appointment.LocationID;
        if (
          (!forLocationId || locationId === forLocationId) &&
          (!forStaffId || staffId === forStaffId) &&
          forDate === appointment.DateTime.substring(0, 10) &&
          !appointment.Canceled
        ) {
          if (resolved[locationId] && resolved[locationId][staffId]) {
            const hour = parseInt(appointment.DateTime.substring(11, 13));
            resolved[locationId][staffId][0][hour] = false;
          }
        }
      }
    });
  }
  // resolved contains:
  // {
  //   "locationId": {
  //     "staffId": [a0, a1, ... a23],
  //     ...
  //   },
  //   ...
  // }
  // a0 ... a23 is a 24-element array, where each element represents
  // the availability on the specified day. These elements can be one of:
  // null: the availability was not specified- which means unavailable
  // true: the hour was marked as available, with no overriding unavailbility
  // false: the hour wasmarked as unavailable
  // We'll reduce this array to [h,h,h...]
  // which is a list of the hours that were true (or empty list if none)
  const result = {};
  const unavail = {};
  for (const locationId in resolved) {
    const location = resolved[locationId];
    for (const staffId in location) {
      const hours = [];
      const unhours = [];
      location[staffId][0].forEach(
        (hour, index) => hour === true && hours.push(index)
      );
      location[staffId][1].forEach(
        (hour, index) => hour === true && unhours.push(index)
      );
      if (hours.length > 0) {
        if (!result[locationId]) {
          result[locationId] = {};
        }
        result[locationId][staffId] = hours;
      }
      if (unhours.length > 0) {
        if (!unavail[locationId]) {
          unavail[locationId] = {};
        }
        unavail[locationId][staffId] = unhours;
      }
    }
  }
  return includeUnavailability
    ? { available: result, unavailable: unavail }
    : result;
};

const dayList = [
  { key: 'Sun', value: 'Sunday' },
  { key: 'Mon', value: 'Monday' },
  { key: 'Tue', value: 'Tuesday' },
  { key: 'Wed', value: 'Wednesday' },
  { key: 'Thu', value: 'Thursday' },
  { key: 'Fri', value: 'Friday' },
  { key: 'Sat', value: 'Saturday' }
];

const FOREVER = moment('2999-12-31');
const isForever = date => moment(util.parseDate(date)).isSameOrAfter(FOREVER);

const availList = [
  { key: 'Regular', value: 'Regular' },
  { key: 'On Request', value: 'On Request' }
];

const notAvailList = [
  { key: 'Holiday', value: 'Holiday' },
  { key: 'Vacation', value: 'Vacation' },
  { key: 'Out of Office', value: 'Out of Office' }
];
const statusList = [
  { key: 'Available', value: 'Available' },
  { key: 'Not Available', value: 'Not Available' }
];

export const util = {
  // In the dateabase we store the appointment DateTime as a string
  // with the format YYYY-MM-DD hh
  // YY = year eg 2019, MM = month 01-12, DD = day 01-31, hh = hour 0-23
  // For initializing the date field on the form, we extract YYYY-MM-DD
  // from the DateTime, then convert this to a moment (react-moment)
  // and also as the field display value
  // For the time field, we use the int 0-23 as the value, and
  // the 12-hour formatted time '12:00 AM' to '11:00 PM' as the
  // list display value.
  // These are some useful functions for converting between these
  // formats
  // Convert int hour to formatted time string '12:00 AM' to '11:00 PM'
  hourToString12: hr =>
    `${hr === 0 ? 12 : hr < 13 ? hr : hr - 12}:00 ${hr < 12 ? 'AM' : 'PM'}`,
  hourToString12Short: hr =>
    `${hr === 0 ? 12 : hr < 13 ? hr : hr - 12} ${hr < 12 ? 'AM' : 'PM'}`,
  // Parse a date string YYYY-MM-DD from a DateTime string, an ISO string or
  // a date or moment value.
  parseDate: dateTime =>
    (dateTime
      ? dateTime.format
        ? dateTime.format()
        : dateTime.toISOString
        ? dateTime.toISOString()
        : dateTime
      : ''
    ).substring(0, 10),
  // converts a date and hour value to the format 'YYYY-MM-DD hh'
  // date can be a DateTime string, an ISO string, a Date or moment value
  dateTimeStr: (date, hour) =>
    `${util.parseDate(date)} ${hour < 10 ? '0' : ''}${hour}`,
  // parse an hour value (int 0-23) from a DateTime string
  parseHour: dateTime => parseInt(dateTime.substring(11, 13)),
  daySpan: (days, short = false) => {
    const findSpan = (arr, index, from, to) => {
      if (index < arr.length) {
        if (arr[index]) {
          if (from === -1) {
            return findSpan(arr, index + 1, index, to);
          }
          if (to !== -1) {
            return { span: false };
          }
        } else {
          if (from !== -1 && to === -1) {
            return findSpan(arr, index + 1, from, index - 1);
          }
        }
        return findSpan(arr, index + 1, from, to);
      }
      return { span: from !== -1, from, to: to === -1 ? index - 1 : to };
    };
    const field = short ? 'key' : 'value';
    const { span, from, to } = findSpan(days, 0, -1, -1);
    if (span) {
      // There's a continuous day range from..to
      return from === to
        ? dayList[from][field]
        : `${dayList[from][field]} to ${dayList[to][field]}`;
    } else {
      // the day range isn't continuous, so just list all dates
      const days2 = days
        .map((d, i) => (d ? dayList[i][field] : null))
        .filter(d => d);
      return days2.length > 0 ? days2.join(', ') : 'ERROR';
    }
  },
  dateRangeString: (dateFrom, dateTo, format) => {
    dateFrom = moment(util.parseDate(dateFrom));
    dateTo = moment(util.parseDate(dateTo));
    return isForever(dateTo)
      ? `Starting ${dateFrom.format(format)}`
      : dateFrom.isSame(dateTo)
      ? `${dateFrom.format(format)}`
      : `${dateFrom.format(format)} to ${dateTo.format(format)}`;
  },
  dayList,
  FOREVER,
  isForever,
  availList,
  notAvailList,
  statusList
};

/*   const colors = (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    {[
      { text: 'primary', list: mui.primaryColor },
      { text: 'secondary', list: mui.secondaryColor },
      { text: 'warning', list: mui.warningColor },
      { text: 'danger', list: mui.dangerColor },
      { text: 'success', list: mui.successColor },
      { text: 'info', list: mui.infoColor },
      { text: 'rose', list: mui.roseColor },
      { text: 'gray', list: mui.grayColor }
    ].map((color, i) => (
      <>
        {color.text}
        <br />
        <div key={i} style={{ display: 'flex' }}>
          {color.list.map((subcolor, j) => (
            <div
              key={j}
              style={{ backgroundColor: subcolor, flex: '1', height: 30 }}
            >
              {j}
            </div>
          ))}
        </div>
      </>
    ))}
  </div>
); */
