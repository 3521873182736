import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';

import parallaxStyle from './parallaxStyle';

const Parallax = ({
  classes,
  background,
  foreground,
  injectClasses,
  injectStyles
}) => {
  const bg = useRef(null);
  const prevPos = useRef(0);

  const parallaxEffect = () => {
    const offset = Math.floor(window.pageYOffset / 3);
    if (offset !== prevPos.current) {
      prevPos.current = offset;
      bg.current.style.transform = `translate(0, ${-offset}px)`;
    }
  };

  useEffect(() => {
    if (window.innerWidth >= 768) {
      window.addEventListener('scroll', parallaxEffect);
      return () => {
        window.removeEventListener('scroll', parallaxEffect);
      };
    }
  }, []);

  const parallaxClasses = classNames([classes.parallax, ...injectClasses]);
  const inlineStyles = injectStyles ? { style: { ...injectStyles } } : {};

  return (
    <>
      <div className={parallaxClasses} ref={bg} {...inlineStyles}>
        {background}
      </div>
      {foreground}
    </>
  );
};

// eslint-disable-next-line react/display-name
Parallax.createFromCms = cmsContext => {
  const { key, children, styles, localStyles, component } = cmsContext;
  const props = {
    background: children[0],
    foreground: children[1],
    injectClasses: styles,
    injectStyles: localStyles,
    ...component
  };
  const ParallaxCms = withStyles(parallaxStyle)(Parallax);
  return <ParallaxCms {...props} key={key} />;
};

Parallax.propTypes = {
  classes: PropTypes.object.isRequired,
  background: PropTypes.element.isRequired,
  foreground: PropTypes.element,
  injectClasses: PropTypes.array,
  injectStyles: PropTypes.object
};

export default withStyles(parallaxStyle)(Parallax);
