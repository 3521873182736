import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown';

import Card from '../../mui-pro/components/Card/Card';
import CardBody from '../../mui-pro/components/Card/CardBody.jsx';
import CardHeader from '../../mui-pro/components/Card/CardHeader.jsx';

import htmlCardStyles from './htmlCardStyle';

const HtmlCard = ({
  classes,
  title,
  subtitle,
  headingStyle,
  html,
  markdown,
  image,
  color,
  injectStyles,
  injectClasses
}) => {
  const cardClasses = classNames([classes.card, ...injectClasses]);
  const styles = injectStyles ? { style: injectStyles } : {};
  const simpleHeading = !(
    headingStyle === 'Primary' || headingStyle === 'Info'
  );
  return (
    <Card
      className={cardClasses}
      {...((color === 'info' || color === 'primary') && { color })}
      {...styles}
    >
      {image && (
        <img
          src={image.url}
          alt={image.alt}
          style={{ width: '100%', height: 'auto' }}
        />
      )}
      {!simpleHeading && title && title.length > 0 && (
        <CardHeader color={headingStyle === 'Info' ? 'info' : 'primary'}>
          <h4 className={classes.cardTitle} style={{ color: 'white' }}>
            {title}
          </h4>
        </CardHeader>
      )}
      <CardBody>
        {simpleHeading && title && title.length > 0 && (
          <h4
            className={classes.cardTitle}
            {...((color === 'info' || color === 'primary') && {
              style: { color: 'white' }
            })}
          >
            {title}
          </h4>
        )}
        {subtitle && subtitle.length > 0 && (
          <h6 className={classes.cardSubtitle}>{subtitle}</h6>
        )}
        {html && html.length > 0 ? (
          <div
            dangerouslySetInnerHTML={{
              __html: html
            }}
            style={{ fontWeight: 400 }}
          />
        ) : (
          markdown &&
          markdown.length > 0 && (
            <div style={{ fontWeight: 400 }}>
              <ReactMarkdown source={markdown} />
            </div>
          )
        )}
      </CardBody>
    </Card>
  );
};

// eslint-disable-next-line react/display-name
HtmlCard.createFromCms = cmsContext => {
  const {
    key,
    styles,
    localStyles,
    component: { headingStyle, content, color }
  } = cmsContext;
  const contentValue = (content && content.value && content.value[0]) || {};
  const [title, subtitle] = contentValue.heading
    ? contentValue.heading.split('|').map(s => s.trim())
    : [];
  const props = {
    html: contentValue.html,
    markdown: contentValue.markdown,
    title,
    subtitle,
    headingStyle,
    color,
    injectClasses: styles,
    injectStyles: localStyles
  };
  const HtmlCardCms = withStyles(htmlCardStyles)(HtmlCard);
  return <HtmlCardCms {...props} key={key} />;
};

HtmlCard.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  headingStyle: PropTypes.string,
  html: PropTypes.string,
  markdown: PropTypes.string,
  image: PropTypes.object,
  color: PropTypes.string,
  injectClasses: PropTypes.array,
  injectStyles: PropTypes.object
};

export default withStyles(htmlCardStyles)(HtmlCard);
