import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import People from '@material-ui/icons/People';
import DateRange from '@material-ui/icons/DateRange';

import { util, resolveAvailability } from 'bkptshared';
import Calendar from '../Calendar/Calendar';
import { viewMode } from '../viewControl';

const CalendarView = ({ scheduleModel, viewControl }) => {
  const { pending, setPending, setError, view } = viewControl;
  const [today] = useState(util.parseDate(moment()));
  const [calendarDate, setCalendarDate] = useState(
    view.calendarDate ? view.calendarDate : moment(today).date(1)
  );
  const [dayMode, setDayMode] = useState(view.dayMode ? true : false);
  const [monthData, setMonthData] = useState({});
  const [userData, setUserData] = useState(null);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    setPending(true);
    scheduleModel
      .fetchLocations()
      .then(result => {
        setPending(false);
        setLocations(result);
      })
      .catch(error => {
        setPending(false);
        setError(error);
      });
  }, []);

  useEffect(() => {
    if (calendarDate) {
      const from = moment(calendarDate).date(1);
      const to = moment(from).add(1, 'M');
      if (!monthData.date || !monthData.date.isSame(from, 'day')) {
        setPending(true);
        setError(null);
        Promise.all([
          scheduleModel.fetchAppointments(
            util.parseDate(from),
            util.parseDate(to)
          ),
          scheduleModel.fetchAvailability()
        ])
          .then(results => {
            setMonthData({
              date: from,
              appointments: results[0],
              availability: results[1]
            });
            setPending(false);
          })
          .catch(error => {
            setPending(false);
            setError(error);
          });
      }
    }
  }, [calendarDate]);

  useEffect(() => {
    if (dayMode && calendarDate) {
      loadUserData();
    } else if (!dayMode) {
      setUserData(null);
    }
  }, [calendarDate, monthData, dayMode]);

  /////////////////////////////////////////////////
  // Calendar View

  const getScheduleSummary = date => {
    const dateStr = util.parseDate(date);
    const { appointments, availability } = monthData;
    const daySchedule = {
      hasSchedule: 0,
      hasConfirmed: 0,
      hasUnconfirmed: 0
    };
    if (availability) {
      const resolved = resolveAvailability(availability, dateStr);
      daySchedule.hasSchedule = Object.values(resolved).reduce(
        (count, location) =>
          count +
          Object.values(location).reduce(
            (count, hours) => (count + hours.length ? 1 : 0),
            0
          ),
        0
      );
    }
    if (appointments) {
      appointments.forEach(appt => {
        const { DateTime, Canceled, Confirmed } = appt.val;
        if (util.parseDate(DateTime) === dateStr && !Canceled) {
          if (Confirmed) {
            daySchedule.hasConfirmed += 1;
          } else {
            daySchedule.hasUnconfirmed += 1;
          }
        }
      });
    }
    return daySchedule;
  };

  const hourSpans = hours => {
    const spans = [];
    if (hours.length > 0) {
      let from = hours[0];
      let to = from;
      hours.forEach(hour => {
        if (hour > to + 1) {
          spans.push({ from, to: to + 1 });
          from = hour;
          to = hour;
        } else {
          to = hour;
        }
      });
      spans.push({ from, to: to + 1 });
    }
    return spans;
  };

  const loadUserData = () => {
    const { appointments, availability } = monthData;
    const fetch = {};
    if (availability) {
      const resolved = resolveAvailability(
        availability,
        util.parseDate(calendarDate),
        null,
        null,
        null,
        null,
        true
      );
      if (resolved.available) {
        for (const locationId in resolved.available) {
          for (const staffId in resolved.available[locationId]) {
            fetch[staffId] = true;
          }
        }
      }
      if (resolved.unavailable) {
        for (const locationId in resolved.unavailable) {
          for (const staffId in resolved.unavailable[locationId]) {
            fetch[staffId] = true;
          }
        }
      }
    }
    if (appointments) {
      appointments.forEach(entity => {
        const { val } = entity;
        if (
          !val.Canceled &&
          util.parseDate(val.DateTime) === util.parseDate(calendarDate)
        ) {
          fetch[val.PatientID] = true;
        }
      });
    }
    let users = Object.keys(fetch);
    setPending(true);
    return scheduleModel
      .fetchUsers(users)
      .then(fetchUsers => {
        setPending(false);
        users = {};
        fetchUsers.forEach(entity => {
          users[entity.key] = entity;
        });
        setUserData(users);
      })
      .catch(error => {
        setPending(false);
        setError(error);
      });
  };

  const filterLoc = scheduleModel.filter.entity.val[scheduleModel.LOCATION];

  const getDayData = locationId => {
    const { appointments, availability } = monthData;
    const result = { staff: [], appointments: [], unavailable: [] };
    if (availability) {
      const resolved = resolveAvailability(
        availability,
        util.parseDate(calendarDate),
        locationId,
        null,
        null,
        null,
        true
      );
      if (resolved.available && resolved.available[locationId]) {
        for (const staffId in resolved.available[locationId]) {
          const hours = hourSpans(resolved.available[locationId][staffId]);
          hours.forEach(({ from, to }) => {
            const item = {
              from,
              to,
              id: staffId,
              text:
                userData && userData[staffId]
                  ? userData[staffId].val.name
                  : 'Loading...',
              onClick: item =>
                viewControl.presentView(
                  {
                    id: scheduleModel.staffScheduleViewId,
                    mode: viewMode.MASTER,
                    key: staffId,
                    day: calendarDate
                  },
                  { calendarDate, dayMode }
                )
            };
            result.staff.push(item);
          });
        }
      }
      if (resolved.unavailable && resolved.unavailable[locationId]) {
        for (const staffId in resolved.unavailable[locationId]) {
          const hours = hourSpans(resolved.unavailable[locationId][staffId]);
          hours.forEach(({ from, to }) => {
            const item = {
              from,
              to,
              id: staffId,
              text:
                userData && userData[staffId]
                  ? userData[staffId].val.name
                  : 'Loading...',
              onClick: item =>
                viewControl.presentView(
                  {
                    id: scheduleModel.staffScheduleViewId,
                    mode: viewMode.MASTER,
                    key: staffId,
                    day: calendarDate
                  },
                  { calendarDate, dayMode }
                )
            };
            result.unavailable.push(item);
          });
        }
      }
    }
    if (appointments) {
      appointments.forEach(entity => {
        const { val } = entity;
        if (
          !val.Canceled &&
          util.parseDate(val.DateTime) === util.parseDate(calendarDate) &&
          val.LocationID === locationId
        ) {
          const hour = util.parseHour(val.DateTime);
          const item = {
            from: hour,
            to: hour + 1,
            id: val.StaffID,
            alert: !val.Confirmed,
            text:
              userData && userData[val.PatientID]
                ? userData[val.PatientID].val.name
                : 'Loading...',
            entity,
            onClick: item =>
              viewControl.presentView(
                {
                  id: scheduleModel.staffScheduleViewId,
                  mode: viewMode.MASTER,
                  key: val.StaffID,
                  day: calendarDate
                },
                { calendarDate, dayMode }
              )
          };
          result.appointments.push(item);
        }
      });
    }
    return result;
  };

  const groupSets = locations.reduce((groups, loc) => {
    if (!filterLoc || filterLoc === 'ALL' || filterLoc === loc.key) {
      const data = getDayData(loc.key);
      // Staff is empty... need to remember what this is
      if (data.staff.length > 0 || data.appointments.length > 0) {
        groups.push({
          key: loc.key,
          data,
          cols: [
            {
              title: `Staff - ${loc.val.LocationName}`,
              id: 'staff',
              key: loc.key
            },
            {
              title: `Appointments - ${loc.val.LocationName}`,
              id: 'appointments',
              key: loc.key
            }
          ]
        });
      }
      if (data.staff.length > 0 || data.unavailable.length > 0) {
        groups.push({
          key: loc.key,
          data,
          cols: [
            {
              title: `Staff - ${loc.val.LocationName}`,
              id: 'staff',
              key: loc.key
            },
            {
              title: `Unavailable - ${loc.val.LocationName}`,
              id: 'unavailable',
              key: loc.key
            }
          ]
        });
      }
    }
    return groups;
  }, []);

  const calendarModel = {
    calendarDate,
    setCalendarDate,
    dayMode,
    setDayMode,
    earliest: today,
    selected: today,
    filter: scheduleModel.filter,
    getCellForDate: date => {
      const { hasSchedule, hasConfirmed, hasUnconfirmed } = getScheduleSummary(
        date
      );
      const actions = [];
      const dayNum = date.day();
      if (hasSchedule || (dayNum > 0 && dayNum < 6)) {
        actions.push({
          key: 'availability',
          state: hasSchedule ? 'active' : 'inactive',
          icon: People
        });
      }
      if (hasConfirmed || hasUnconfirmed) {
        actions.push({
          key: 'appointments',
          state: hasUnconfirmed ? 'danger' : 'active',
          icon: DateRange
        });
      }
      return {
        actions
      };
    },
    dayView: {
      earliest: 7,
      latest: 18,
      groupSets
    }
  };

  useEffect(() => viewControl.setActions(scheduleModel.actions(viewControl)), [
    pending
  ]);

  // console.log('render');
  return <Calendar calendarModel={calendarModel} viewControl={viewControl} />;
};

CalendarView.propTypes = {
  viewControl: PropTypes.object,
  adminMode: PropTypes.bool,
  userMode: PropTypes.bool,
  scheduleModel: PropTypes.object.isRequired
};

export default CalendarView;
